
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  import  moment from 'moment';
  import {getNewsListHttp} from "~/api/comm";
  moment.locale("zh-cn");
  @Component({
    name:"hotGameList2",
  })
  export default class hotGameList2 extends Vue {
    @Prop(Number) divWidth_?: number
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    private hoverIndex:any=''
    private newsList:any={}
    private newsTypeList:Array<any>=[
      {name:'公告',id:1},
      {name:'帮助中心',id:2},
      {name:'关于我们',id:3},
      {name:'首页弹屏',id:4},
      {name:'新手入门',id:5},
      {name:'客服中心',id:6},
      {name:'特色服务',id:7},
    ]
    mounted(){
      this.getNewsList()
    }
    @Emit()
    //加载新闻列表
    async getNewsList(){
      try {
        let {data}=await getNewsListHttp({params:{pageSize:3,newsType:1}})
        if (this.errorToastFn(data)) {
          this.newsList=data.data.list
          console.log(this.newsList)
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
  }
