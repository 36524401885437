
  import { Component, Vue, Emit, Prop, Watch} from '~/node_modules/vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from '~/node_modules/vuex-class'
  import  moment from '~/node_modules/moment';
  import {gameListHttp,goodsTypeListHttp} from "~/api/comm";
  moment.locale("zh-cn");
  @Component({
    name:"djhAd20",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class djhAd20 extends Vue {
    @Mutation private SET_HOTGAMES_DATA!: any;
    @State(state => state.web.hotGamesArray) hotGamesArray!: any;
    @Prop(Number) divWidth_?: number
    @Prop(Array) adArray_?: any
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    private queryParm:any={}//筛选
    private mouseIndex:number=-1
    private gameList:any=[]
    private words:Array<any>=[
      {name:'热门游戏',type:'hot',value:'-1'},
      {name:'手游',type:'type',value:'2'},
      {name:'端游',type:'type',value:'1'}
    ]
    mounted(){
      setTimeout(async ()=>{
        if (this.hotGamesArray && this.hotGamesArray.length>0) {
          this.gameList=this.hotGamesArray
        } else {
          await this.getGameList(true)
        }
      },50)
      console.log('sdgfeg3234',this.hotGamesArray);
      
    }
    get getArray(){
      if (this.hotGamesArray && this.hotGamesArray.length>0) {
        console.log('adad=======',this.hotGamesArray.filter((v:any)=>v.device==0))
        let tempAdArray = [...this.hotGamesArray]
        tempAdArray.forEach(v => {
          console.log('sdlfj',v);
          if(v.goodsTypeAndNameMap && String(v.goodsTypeAndNameMap).length > 0 && String(v.goodsTypeAndNameMap).includes('{')) {
            v['goodsTypeNameArr'] = Object.values(JSON.parse(v.goodsTypeAndNameMap))
          }
        });
        return tempAdArray
      } else {
        return []
      }
    }
    @Emit()
    // 获取游戏列表
    async getGameList(flag:boolean=false) {
      console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr,this.queryParm,'============recGameStr===========')
      try {
        let {data} = await gameListHttp({params:this.queryParm})
        if (this.errorToastFn(data)){
          if (flag) {
            this.gameList=[]
            let hotGameArray:any=[]
            if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
              hotGameArray=this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',')
              for (let i:number=0;i<hotGameArray.length;i++) {
                let gameFlag:any=data.data.find((res:any)=>res.id==hotGameArray[i])
                if (gameFlag) {
                  this.gameList.push(gameFlag)
                }
              }
              if (!this.hotGamesArray || (this.hotGamesArray && this.hotGamesArray.length<this.gameList.length)) {
                this.SET_HOTGAMES_DATA(this.gameList)
              }
            }
          } else {
            this.gameList = data.data;
          }
        }
      }catch (e) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
        this.gameList = []
      }
    }
  }
