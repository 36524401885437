
import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
@Component({
  name:"djhSwiper",
  components: {
    newImg2: require('~/components/comm/newImg/index2.vue').default,
  }
})
export default class djhSwiper extends Vue {
  @Mutation private SET_TOKEN_DATA!: any;
  @State(state => state.web.distributorsObject) distributorsObject!: any;
  @Prop(Number) divWidth_?: any
  @Prop(Number) divHeight_?: any
  @Prop({default:()=>[]}) swiperList_?: any
  private swiperOption: any = {
    loop : true,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      bulletClass: 'pagination',
      bulletActiveClass: 'pagination_active',
      renderBullet:  (index:number, className:string)=> {
        this.list=this.swiperList_.filter((v:any)=>v.device==0)
        return '<div tabindex="'+ index +'" class="' + className + '" @mouseover='+this.moveBanner()+'></div>';
      },
      // renderBullet:'官方文档查看这个属性'
    },
  }

  private list:any=[]
  mounted(){
    this.list=this.swiperList_.filter((v:any)=>v.device==0)
  }
  @Emit()
  getSwiper(){
    if (this.swiperList_) {
      this.list=this.swiperList_.filter((v:any)=>v.device==0)
      return this.swiperList_.filter((v:any)=>v.device==0)
    } else {
      return []
    }
  }
  @Emit()
  moveBanner(){
  }
  @Emit()
  openUrl2(url:string,type:string) {
    console.log(url)
  }
}
