
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { distOrderCountHttp } from "~/api/order";
import { latestDealRecordHttp } from "~/api/distributor";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { dailyOrderHttp } from "~/api/order";
import { queryVerifyCustomerHttp } from "~/api/cusSer";
import { addBlackHttp } from "~/api/black";
import { gameListHttp, getNewsListHttp } from "~/api/comm";
import { goodsListHttp, dealGoodsData } from "~/api/goods";

@Component({
  name: "substationIndex10",
  components: {
    logoSearch3: require("~/components/comm/logoSearch/index3.vue").default,
    djhHeaderNav8: require("~/components/comm/djhHeaderNav/index8.vue").default,
    djhSwiper8: require("~/components/comm/djhSwiper/index8.vue").default,
    djhSwiper7: require("~/components/comm/djhSwiper/index7.vue").default,
    gameCheckNum: require("~/components/template/gameCheckNum.vue").default,
    newsList7: require("~/components/comm/news/newsList7.vue").default,
    homeLogin4: require("~/components/comm/homeLogin/index4.vue").default,
    djhAd14: require("~/components/comm/djhAd/index14.vue").default,
    djhAd17: require("~/components/comm/djhAd/index17.vue").default,
    newsList6: require("~/components/comm/news/newsList6.vue").default,
    djhAd16: require("~/components/comm/djhAd/index16.vue").default,
    djhAd18: require("~/components/comm/djhAd/index18.vue").default,
    djhAd19: require("~/components/comm/djhAd/index19.vue").default,
    hotGameList5: require("~/components/comm/hotGame/hotGameList5.vue").default,
    goodsList8: require("~/components/comm/goodsList/goodsList8.vue").default,
    footerService3: require("~/components/comm/djhFooter/footerService3.vue")
      .default,
    bestCoin: require("~/components/comm/coin/bestCoin.vue").default,
    newsList: require("~/components/comm/news/newsList.vue").default,
    safeCheck: require("~/components/comm/safeCheck/index.vue").default,
    floatMenu5: require("~/components/comm/floatMenu/index5.vue").default,
    newImg2: require("~/components/comm/newImg/index2.vue").default,
  },
})
export default class substationIndex10 extends Vue {
  @Mutation private SET_TOKEN_DATA!: any;
  @State((state) => state.web.distributorsObject) distributorsObject!: any;
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State((state) => state.web.hotGamesArray) hotGamesArray!: any;
  @Mutation private SET_GAMELIST_DATA!: any;
  @State((state) => state.web.gameListArray) gameListArray!: any;
  public qqRegular: any;
  public eventMsgTost: any;
  public getCookie: any;
  public setCookie: any;
  public openUrl: any;
  public errorToastFn: any;
  public eventNotifyTost: any;
  private orderCount: any = ""; // 每日成交订单总数
  private allDealCount: any = ""; // 成交订单总数
  private goodsCount: any = ""; // 在售商品总数
  private yesterdayOrderCount: number = 0; // 昨日成交订单总数
  private isShow: boolean = true;
  private checkNumber: any = null; // 验证的客服QQ
  private checkBlack: any = null; // 验证的黑名单
  private isLogined: Boolean = false; // 是否已登录
  private actor: string = ""; // 头像
  private nickName: string = ""; // 昵称
  private gameList: Array<any> = []; // 游戏列表
  private gameType: number = 1; // 游戏列表
  private newsList: Array<any> = [];
  private recOrTime: any = "";
  private chooseGameIndex: any = 0;
  private queryParams: any = {
    gameId: -1,
    goodsTypeId: 1,
    pages: 1,
    pageSize: 10,
    orderTypeStr: "",
  };
  private nowGameTotal: number = 0;
  private listData: Array<any> = []; // 当前查询到的游戏列表数据size10
  private templateGame: any = {};
  private haveGameCount: number = 0;
  private check: number = 1;
  private startVal : number = 0;
  private isFirstShowInClient : boolean = false
  private isShowDialog : boolean = false
  private noticeTitle : string = '需要注意的是内容是默认不居中的'

  private colorConfig: Object = {
    //菜单颜色
    bgColor: "#190F0D",
    hoverBgColor: "#BC0000",
    color: "#EAB700",
    hoverColor: "#EAB700",
  };
  private adSingleImg: any = [];
  private orderListData: any = [];

  async mounted() {
    console.log('mountedmountedmountedmountedmountedmountedmountedmountedmounted');
    if (this.getCookie("token")) {
      this.isLogined = true;
      this.actor = this.getCookie("actor");
      this.nickName = this.getCookie("nickName");
    }
    setTimeout(async () => {
      console.log(this.distributorsObject, "========aaaaaa啊啊啊啊啊啊啊");
      let body: any = document.querySelectorAll(
        "body"
      ) as NodeListOf<HTMLElement>;
      console.log(body, "===body===");
      body[0].setAttribute("style", "background-color:#f2f3f5");
      if (this.hotGamesArray && this.hotGamesArray.length > 0) {
        this.gameList = this.hotGamesArray;
        console.log("走这里12390797");
      } else {
        await this.getGameList(true);
      }
      console.log(this.gameList, "gameListgameListgameListgameList");
      this.queryParams.gameId = this.gameList[0].id;
      this.templateGame = this.gameList.find((v: any) => {
        return this.distributorsObject.distBaseConf.teGameId == v.id;
      });
      this.getGoodsList();
    }, 50);
    console.log(this.distributorsObject, this.gameList, "sdgsagg");

    if (this.gameListArray.length > 0) {
      this.haveGameCount = this.gameListArray.length;
    } else {
      await this.getGameList(true);
    }
    console.log(this.templateGame, "sdgsagg");
    let scrolltop=document.documentElement.scrollTop||document.body.  scrollTop;
    if(scrolltop > 880) {
      if(!this.isFirstShowInClient) {
          this.isFirstShowInClient = true
        }
    } else {
        window.addEventListener('scroll',()=>{
        let scrolltop1=document.documentElement.scrollTop||document.body.scrollTop;
          if(scrolltop1>880){
          if(!this.isFirstShowInClient) {
            this.isFirstShowInClient = true
          }
        }
      })
    }


    this.getArray();
    // this.getSellGoodsNum();

    await this.getStaticOrderCount();
    await this.getDistOrderCount();
    await this.getDealGoods();
    await this.dailyOrderHttpFn();
    // await this.getNewsList();
    console.log(this.newsList, this.listData, "滚动新闻");
  }

  @Emit()
  changeGameType(type: number) {
    if (this.gameType !== type) {
      this.gameType = type;
      (this.$refs.goodsList7 as any).getGoodsHttpWithId(
        3,
        this.gameType === 1 ? 83 : 82
      );
    }
  }
  @Emit()
  getNewGoods(type: number) {
    console.log(121221);
    (this.$refs.goodsList7 as any).getGoodsHttpWithId(type);
  }
  @Emit()
  getArray() {
    if (this.distributorsObject && this.distributorsObject.adverPosMap["4"]) {
      let flag: any = this.distributorsObject.adverPosMap["4"].filter(
        (v: any) => v.device == 0
      );
      if (flag) {
        this.adSingleImg = flag;
      }
    }
  }
  @Emit() //获取当日成交笔数
  async dailyOrderHttpFn() {
    try {
      let { data } = await dailyOrderHttp();
      if (this.errorToastFn(data)) {
        if (data.data) {
          if (this.$store.state.web.distributorsObject &&this.$store.state.web.distributorsObject.distBaseConf.statisMap) {
            let statisMap =
            this.$store.state.web.distributorsObject.distBaseConf.statisMap;
            if (!!statisMap && statisMap.includes("{")) {
              let flagMap = JSON.parse(statisMap);
              if (!!flagMap["dealCount"]) {
                this.yesterdayOrderCount = flagMap["dealCount"]+data.data.goodsList[0].dealNum;
              } else {
                this.yesterdayOrderCount = data.data.goodsList[0].dealNum;
              }
            }
          } else {
            this.yesterdayOrderCount = data.data.goodsList[0].dealNum;
          }
        } else {
          if (this.$store.state.web.distributorsObject &&this.$store.state.web.distributorsObject.distBaseConf.statisMap) {
            let statisMap =
            this.$store.state.web.distributorsObject.distBaseConf.statisMap;
            if (!!statisMap && statisMap.includes("{")) {
              let flagMap = JSON.parse(statisMap);
              if (!!flagMap["dealCount"]) {
                this.yesterdayOrderCount = flagMap["dealCount"];
              }
            }
          }
          this.$bus.$emit("yesterdayOrderCountEvent", this.yesterdayOrderCount);
        }
      }
    } catch (error) {
      this.errorToastFn("提示", "网络超时", "warning");
    }
  }
  @Emit() // 获取分站静态订单笔数
  async getStaticOrderCount() {
    if (
      this.$store.state.web.distributorsObject &&
      this.$store.state.web.distributorsObject.distBaseConf.statisMap
    ) {
      let statisMap =
        this.$store.state.web.distributorsObject.distBaseConf.statisMap;
      if (!!statisMap && statisMap.includes("{")) {
        let flagMap = JSON.parse(statisMap);
        if (!!flagMap["dealCount"]) {
          this.orderCount = flagMap["dealCount"];
        }
      }
    }
  }
  @Emit() // 获取分站成交笔数
  async getDistOrderCount() {
    try {
      let { data } = await distOrderCountHttp();
      if (this.errorToastFn(data)) {
        if (data.data) {
          let flag = "20";
          console.log(
            this.$store.state.web.distributorsObject.distBaseConf.statisMap,
            "1111111111111111111111111111111111111111111111111"
          );
          try {
            if (
              this.$store.state.web.distributorsObject &&
              this.$store.state.web.distributorsObject.distBaseConf.statisMap
            ) {
              //  flag=this.$store.state.web.distributorsObject.distBaseConf.statisMap.split(',')[2]
              let statisMap =
                this.$store.state.web.distributorsObject.distBaseConf.statisMap;
                console.log(statisMap,'sdfhklgj');

              if (!!statisMap && statisMap.includes("{")) {
                let flagMap = JSON.parse(statisMap);
                if (!!flagMap["dealAllCount"]) {
                  flag = (
                    flagMap["dealAllCount"] * 1 +
                    data.data.count
                  ).toString();
                }
              }
            }
          } catch (e) {}
          this.allDealCount = flag;
        }
      }
    } catch (error) {
      this.errorToastFn("提示", "网络超时", "warning");
    }
  }

  @Emit() //客服验证账号
  async checkSafe() {
    if (!this.qqRegular.test(this.checkNumber)) {
      console.log(this.checkNumber, "fspoieo");
      this.checkNumber = null;
      // this.eventMsgTost(`请输入检测QQ`);
      // this.$msgbox('请输入检测QQ')
      this.noticeTitle = '请输入检测QQ！'
      this.isShowDialog = true
      return false;
    }
    try {
      const param = { queryValue: this.checkNumber, queryType: 1 };
      let { data }: any = await queryVerifyCustomerHttp({ params: param });
      if (this.errorToastFn(data)) {
        console.log(
          "经验证：${this.checkNumber}为真客服。",
          1111111111111111111111111111111111111111
        );
        if (data.data && !!data.data.distId) {
          // this.eventMsgTost(`经验证：${this.checkNumber}为真客服。`, "success");
          this.noticeTitle = `经验证：${this.checkNumber}为真客服！`
          this.isShowDialog = true
        } else {
          // this.eventMsgTost(`经验证：${this.checkNumber}为假客服。`);
          this.noticeTitle = `经验证：${this.checkNumber}为假客服！`
          this.isShowDialog = true
        }
      }
    } catch (error) {
      this.errorToastFn(error, "系统繁忙中，请稍后再试！");
    }
  }
  @Emit() //客服黑名单
  async checkSafe2() {
    if (!this.getCookie("token")) {
      this.eventMsgTost(`请登录`);
      this.openUrl("/login");
      return false;
    }
    if (!this.checkBlack) {
      // this.eventMsgTost(`请输入黑号关键字`);
      this.noticeTitle = "请输入黑号关键字！"
      this.isShowDialog = true
      return false;
    }
    try {
      let { data }: any = await addBlackHttp({
        params: { distId: 95, keyWord: this.checkBlack },
      });
      if (this.errorToastFn(data)) {
        if (data.data && data.data.list && data.data.list.length > 0) {
          // this.$alert("该用户已被拉入黑名单，请终止交易。", "存在违规", {
          //   confirmButtonText: "确定",
          //   callback: (action) => {},
          // });
          this.noticeTitle = "该用户已被拉入黑名单，请终止交易！"
          this.isShowDialog = true
        } else {
          // this.$alert(
          //   "该用户未被拉入黑名单，如后续有违规请联系客服，避免线下交易。",
          //   "未违规",
          //   {
          //     confirmButtonText: "确定",
          //     callback: (action) => {},
          //   }
          // );
          this.noticeTitle = "该用户未被拉入黑名单，如后续有违规请联系客服，避免线下交易！"
          this.isShowDialog = true
        }
      }
    } catch (error) {
      this.errorToastFn(error, "系统繁忙中，请稍后再试！");
    }
  }

  @Emit() // 获取每日成交记录
  async getDealGoods() {
    try {
      let { data } = await latestDealRecordHttp({ params: { pageSize: 13 } });
      if (this.errorToastFn(data)) {
        this.orderListData = data.data;
        console.log(this.orderListData, "=======orderListData======");
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
    }
  }

  @Emit()
  // 获取筛选游戏列表
  async getGameList(flag: boolean = false) {
    console.log(
      this.$store.state.web.distributorsObject.distBaseConf.recGameStr,
      "=======recGameStr123========="
    );
    try {
      let { data } = await gameListHttp();
      if (this.errorToastFn(data)) {
        console.log(data, "poqlwkjui");

        if (flag) {
          this.gameList = [];
          let hotGameArray: any = [];
          if (
            this.$store.state.web.distributorsObject.distBaseConf.recGameStr
          ) {
            hotGameArray =
              this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(
                ","
              );
            for (let i: number = 0; i < hotGameArray.length; i++) {
              let gameFlag: any = data.data.find(
                (res: any) => res.id == hotGameArray[i]
              );
              if (gameFlag) {
                this.gameList.push(gameFlag);
              }
            }
            this.haveGameCount = data.data.length;
            this.templateGame = this.gameList.find((v: any) => {
              return this.distributorsObject.distBaseConf.teGameId == v.id;
            });
            console.log(this.templateGame, "sdf09jk");

            if (
              !this.hotGamesArray ||
              (this.hotGamesArray &&
                this.hotGamesArray.length < this.gameList.length)
            ) {
              this.SET_HOTGAMES_DATA(this.gameList);
            }
          }
        } else {
          this.gameList = data.data;
          this.SET_GAMELIST_DATA(this.gameList);
        }
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
      this.gameList = [];
    }
  }
  @Emit() // 获取在售商品数
  async getSellGoodsNum() {
    try {
      const param = { pageSize: 12, gameId: 69, goodsTypeId: 1 };
      let { data } = await goodsListHttp({ params: param });
      if (this.errorToastFn(data)) {
        this.goodsCount = 2413 + data.data.total;
      } else {
        this.goodsCount = 2413;
      }
    } catch (error) {
      console.log(error, "获取在售商品数失败！");
      this.goodsCount = 2413;
    }
  }
  @Emit() // 处理浏览量计算
  dealCount(index: any) {
    let statisMap = this.distributorsObject.distBaseConf.statisMap;
    let flag = "20";
    if (index == 1) {
      if (!!statisMap && statisMap.includes("{")) {
        console.log(statisMap, "----------------statisMap------");
        let flagMap = JSON.parse(statisMap);
        if (!!flagMap["showCount"]) {
          flag = (flagMap["showCount"] * 1).toString();
        }
      }
    } else {
      if (!!statisMap && statisMap.includes("{")) {
        console.log(statisMap, "----------------statisMap------");
        let flagMap = JSON.parse(statisMap);
        if (!!flagMap["dealCount"]) {
          flag = flagMap["dealCount"].toString();
        }
      }
    }
    return flag;
  }
  @Emit() // 获取区服信息
  getareaData(data: any) {
    let res = "";
    let dats = JSON.parse(data);
    if (!!dats.gameName) {
      res = dats.gameName;
    } else {
      res = "暂无";
    }
    if (!!dats.areaName) {
      res = res + "-" + dats.areaName;
    } else {
      res = res + "-" + "暂无";
    }
    if (!!dats.serverName) {
      res = res + "-" + dats.serverName;
    } else {
      res = res + "-" + "暂无";
    }
    return res;
  }
  @Emit()
  //处理总成交数
  dealCountNumber(number: any) {
    if (Number(number) < 10) {
      return `00000${number}`.split("");
    } else if (Number(number) < 100) {
      return `0000${number}`.split("");
    } else if (Number(number) < 1000) {
      return `000${number}`.split("");
    } else if (Number(number) < 10000) {
      return `00${number}`.split("");
    } else if (Number(number) < 100000) {
      return `0${number}`.split("");
    } else {
      return number.toString().split("");
    }
  }
  beforeDestroy() {
    this.$bus.$off("yesterdayOrderCountEvent", 0);
  }
  @Emit()
  getNewsList() {
    if (this.distributorsObject && this.distributorsObject.adverPosMap[3]) {
      this.newsList = this.distributorsObject.adverPosMap[3];
    } else {
      this.newsList = [];
    }
  }
  @Emit()
  chooseGame(item: any, index: number) {
    this.chooseGameIndex = index;
    this.queryParams.gameId = item.id;
    this.queryParams.pages = 1;
    this.getGoodsList();
  }
  @Emit()
  clickRecOrTime(num: number) {
    if (num == 0) {
      if (this.recOrTime == "") {
        return;
      }
      this.recOrTime = "";
      this.queryParams.orderTypeStr = "";
    } else {
      if (this.recOrTime == "createTime=1") {
        return;
      }
      this.recOrTime = "createTime=1";
      this.queryParams.orderTypeStr = "createTime=1";
    }
    this.queryParams.pages = 1;
    this.getGoodsList();
  }
  // 根据queryParams查询参数获取游戏商品信息
  @Emit()
  async getGoodsList() {
    try {
      const { data } = await goodsListHttp({ params: this.queryParams });
      if (this.errorToastFn(data)) {
        console.log(data.data, "当前获取的商品数据");
        this.nowGameTotal = data.data.total;
        // let data2:any=dealGoodsData(data.data.goodsList)
        // console.log(data2,'ppiopqowe')
        this.listData = data.data.goodsList;
        // (this.$refs.listDataRef as any).freshListData()
        // setTimeout(()=>{
        //   (this.$refs.listDataRef as any).freshListData()
        // },50)
        // if (data.data.goodsList.length < this.param.pageSize) {
        //   this.noMore = true;
        // } else {
        //   this.noMore = false;
        // }
      } else {
        this.eventNotifyTost("提示", "获取商品数据失败", "warning");
      }
    } catch (error) {
      console.log(error);
    }
  }
  // 点击上一页或者下一页的按钮
  @Emit()
  preOrNext(num: number) {
    if (num === 0) {
      if (this.queryParams.pages > 1) {
        this.queryParams.pages--;
      } else {
        this.$message({ message: "这已经是第一页了！", type: "warning" });
        return;
      }
    } else {
      let havePages = Math.ceil(this.nowGameTotal / 10);
      if (this.queryParams.pages < havePages) {
        this.queryParams.pages++;
      } else {
        this.$message({ message: "这已经是最后一页了！", type: "warning" });
        return;
      }
    }
    this.getGoodsList();
  }
}
