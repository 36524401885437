
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
@Component({
  name: "floatMenu5",
  components: {
    newImg2: require("~/components/comm/newImg/index2.vue").default
  },
})
export default class floatMenu5 extends Vue {
  private show: boolean = true;
  private show1: number = 1;
  private isShowCusQr : boolean = false 
  private isShowGroupQr : boolean = false 
  private QRCode: any;
  private hostQr: string = '';
  mounted() {
    this.qRCodeFn();
  }
  @Emit()
  //返回顶部
  toTop() {
    window.scrollTo(0, 0);
  }
  @Emit()
  erweimaShowHandle(flag:boolean) {
      this.isShowCusQr = flag
  }
  @Emit()
  joinGroupShowHandle(flag:boolean) {
      this.isShowGroupQr = flag
  }
  qRCodeFn() {
    let url = window.location.origin;
    this.QRCode.toDataURL(url, (err: any, url: any)=>{
        this.hostQr = url;
    });
  }
}
