
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  @Component({
    name:"hotGameList2",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class hotGameList2 extends Vue {
    @Prop(Number) divWidth_?: number
    @Prop(Number) divHeight_?: number
    @Prop(Array) adArray_?: any
    public openGoodsDetail:any
    public eventNotifyTost:any
    private goodsSn:string=''
    private adString:any=''
    mounted(){
      if (this.adArray_) {
        // console.log(this.adArray_.filter((v:any)=>v.device==0),'=======ad==ad=======')
        let flag:any=this.adArray_.filter((v:any)=>v.device==0)
        if (!!flag) {
          this.adString=flag[0].url
        }
      } else {
        return []
      }
    }
    @Emit()
    goGoodsDetail(){
      if (this.goodsSn) {
        this.openGoodsDetail({goodsSn:this.goodsSn},'_blank')
      } else {
        this.eventNotifyTost('提示', '请输入商品编号！', 'warning')
      }
    }
  }
