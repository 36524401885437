
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"djhAd9",
  })
  export default class djhAd9 extends Vue {
    @Prop(Number) divWidth_?: any
    @Prop(Number) divHeight_?: any
    @Prop(Array) adArray_?: any
    private adOne:string=''
    private adOneUrl:string=''
    private adTwo:string=''
    private adTwoUrl:string=''
    private adThree:string=''
    private adThreeUrl:string=''
    private adFour:string=''
    private adFourUrl:string=''
    mounted(){
      if (this.adArray_) {
        let flag:any=[]
        console.log(this.adArray_.filter((v:any)=>v.device==0),'=======ad==ad=======')
        flag = this.adArray_.filter((v:any)=>v.device==0)
        if (flag && flag[0]) {
          this.adOne=flag[0].title
          this.adOneUrl=flag[0].htUrl
        }
        if (flag && flag[1]) {
          this.adTwo=flag[1].title
          this.adTwoUrl=flag[1].htUrl
        }
        if (flag && flag[2]) {
          this.adThree=flag[2].title
          this.adThreeUrl=flag[2].htUrl
        }
        if (flag && flag[3]) {
          this.adFour=flag[3].title
          this.adFourUrl=flag[3].htUrl
        }
      }
    }
  }
