
  import { Component, Vue, Emit, Prop } from '~/node_modules/vue-property-decorator'
  @Component({
    name:"djhAd23",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class djhAd23 extends Vue {
    @Prop({default: () => []}) hotgameList_?: any
    public getCookie:any
    public openUrl:any
    private images:Array<any>=[
      {id:1,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
      {id:2,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
      {id:3,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
      {id:4,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
      {id:5,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
    ]
    private isShowService:boolean=false
    private swiperOption: any = {
      loop: true,
      autoplay: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      freeMode:true,
      noSwiping:false,
      hideOnClick:true,
      slidesPerGroup : 1,
      slidesPerView: 5,
      preloadImages: false,
      lazy: true,
    }
    private showBox: boolean = false; //控制查看图片弹窗显示
    private imageBoxArea: Array<any> = []; //查看图片
    mounted(){
      console.log('jsdhjkhjknkln',this.hotgameList_);
    }
    get isShow1() {
      if (this.hotgameList_ && this.hotgameList_.length > 7) {
        // let aa='24/259665d4d23a76.png'
        // console.log(aa.split(','),'==========screenshot===========')
        // console.log(this.blackInfo_.screenshot.split(','),'==========screenshot===========')
        // return this.blackInfo_.screenshot.split(',')
        // if(this.blackInfo_.screenshot.split(',').length > 4)
        return true
      } else {
        return false
      }
    }
    get isShow2() {
      if (this.hotgameList_ && this.hotgameList_.length <= 7 && this.hotgameList_.length > 0) {
        // let aa='24/259665d4d23a76.png'
        // console.log(aa.split(','),'==========screenshot===========')
        // console.log(this.blackInfo_.screenshot.split(','),'==========screenshot===========')
        // return this.blackInfo_.screenshot.split(',')
        return true
      } else {
        return false
      }
    }
  }
