
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"hotLine",
  })
  export default class hotLine extends Vue {
    @Prop(Number) divWidth_?: any
    @Prop(Number) divHeight_?: any
    mounted(){

    }
  }
