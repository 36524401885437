
import { Component, Vue, Emit } from "vue-property-decorator";
// @ts-ignore
import { dealGoodsData, goodsListHttp } from "~/api/goods";
@Component({
  name: "home",
  layout: function (context) {
    console.log(
      context.store.state.web.distributorsObject.distBaseConf.teGameId,
      "-===========她她她她她她她她她她她她她她她2222"
    );
    if (
      context.store.state.web &&
      context.store.state.web.distributorsObject &&
      context.store.state.web.distributorsObject.distBaseConf &&
      context.store.state.web.distributorsObject.distBaseConf.homeTemplate
    ) {
      let templateId: number =
        context.store.state.web.distributorsObject.distBaseConf.homeTemplate;
      templateId=14
      if (templateId == 3) {
        return "indexLayout";
      } else if (templateId == 2) {
        return "index2Layout";
      } else if (templateId == 9) {
        return "index3Layout";
      } else if (templateId == 10) {
        return "index4Layout";
      } else if (templateId == 11 || templateId == 12 || templateId == 13 || templateId == 14) {
        return 'index6Layout'
      } else {
        return "defaultLayout";
      }
    } else {
      return "defaultLayout";
    }
  },
  components: {
    index1: require("~/components/comm/substationIndex/index1.vue").default,
    index2: require("~/components/comm/substationIndex/index2.vue").default,
    index3: require("~/components/comm/substationIndex/index3.vue").default,
    index4: require("~/components/comm/substationIndex/index4.vue").default,
    index5: require("~/components/comm/substationIndex/index5.vue").default,
    index6: require("~/components/comm/substationIndex/index6.vue").default,
    index7: require("~/components/comm/substationIndex/index7.vue").default,
    index8: require("~/components/comm/substationIndex/index8.vue").default,
    index9: require("~/components/comm/substationIndex/index9.vue").default,
    index10: require("~/components/comm/substationIndex/index10.vue").default,
    index11: require("~/components/comm/substationIndex/index11.vue").default,
    index12: require("~/components/comm/substationIndex/index12.vue").default,
    index13: require("~/components/comm/substationIndex/index13.vue").default,
    index14: require("~/components/comm/substationIndex/index14.vue").default,
  },
  async asyncData({ query, req, store }: any) {
    let templateId = 3;
    let gameId = 1;
    if (
      store.state.web &&
      store.state.web.distributorsObject &&
      store.state.web.distributorsObject.distBaseConf &&
      store.state.web.distributorsObject.distBaseConf.homeTemplate
    ) {
      templateId = store.state.web.distributorsObject.distBaseConf.homeTemplate;
    }
    if (
      store.state.web &&
      store.state.web.distributorsObject &&
      store.state.web.distributorsObject.distBaseConf &&
      store.state.web.distributorsObject.distBaseConf.teGameId
    ) {
      gameId = store.state.web.distributorsObject.distBaseConf.teGameId;
    }
    templateId=14
    try {
      let param = {
        params: { gameId: gameId, goodsTypeId: 1, pageSize: 8 },
        req,
      };
      if (templateId == 3) {
        param = {
          params: { gameId: gameId, goodsTypeId: 1, pageSize: 10 },
          req,
        };
      }
      let [goodsList] = await Promise.all([goodsListHttp(param)]);
      if (goodsList.data.code === 0) {
        return {
          goodsListData: dealGoodsData(goodsList.data.data),
          templateId: templateId,
        };
      } else {
        return { goodsListData: {}, templateId: templateId };
      }
    } catch (e) {
      return { goodsListData: {}, templateId: templateId };
    }
  },
})
export default class home extends Vue {
  // private templateId:number=1 //模板id
  private remindPop: any = false; // IE提示
  created() {
    
    
    if (process.client) {
      console.log(window.navigator.userAgent,'头---------------------------------------0');
      this.$nextTick(() => {
        if (window.navigator != undefined) {
          if (
            window.navigator.userAgent.indexOf("MSIE") >= 0 ||
            navigator.userAgent.indexOf("QIHU 360EE") > -1
          ) {
            this.remindPop = true;
          } else {
            this.remindPop = false;
          }
          console.log(this.remindPop, "======distributorsObject=ttttt======");
        }
      });
    }
  }
}
