
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"dealGoodsImgText",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class dealGoodsImgText extends Vue {
    @Prop(Number) divWidth_?: number //宽度
    @Prop(Number) divHeight_?: number //高度
    @Prop(Array) adArray_?: any
    private dealGoodsImg:string=''
    mounted() {
      this.getArray()
    }
    @Emit()
    getArray(){
      if (this.adArray_) {
        // console.log(this.adArray_,this.adArray_.filter((v:any)=>v.device==0),'=======ad==ad==哈哈哈哈=====')
        let flag:any=this.adArray_.filter((v:any)=>v.device==0)
        if (flag && flag[0]) {
          this.dealGoodsImg=flag[0].url
        }
      } else {
        this.dealGoodsImg=''
      }
    }
  }
