
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"floatMenu6",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class floatMenu6 extends Vue {
    private loading:boolean = false

    mounted() {
    }
  }
