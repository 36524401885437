
import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
import {getNewsListHttp} from "~/api/comm";
@Component({
  name:"newsList7",
})
export default class newsList7 extends Vue {
  @Prop(Number) divWidth_?: number //宽度
  @Prop(Number) divHeight_?: number //高度
  public errorToastFn:any
  private checktype:number=1 //选择类型1:公告,2:帮助中心,5:新手入门,6:客服中心,7:特色服务
  private newsArray:Array<any>=[] //新闻列表存放
  private newsTypeList:Array<any>=[
    {name:'公告',id:1},
    {name:'帮助中心',id:2},
    {name:'关于我们',id:3},
    {name:'首页弹屏',id:4},
    {name:'新手入门',id:5},
    {name:'客服中心',id:6},
    {name:'特色服务',id:7},
  ]
  mounted() {
    this.changeList(1)
  }
  @Emit()
  //改变新闻列表
  async changeList(v:number){
    this.checktype=v
    try {
      let {data}=await getNewsListHttp({params:{pageSize:3,newsType:v}})
      this.newsArray = data.data.list
    } catch (error) {
      this.errorToastFn('系统繁忙！')
    }
  }
}
