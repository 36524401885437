
import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
@Component({
  name:"djhSwiper9",
  components: {
    newImg2: require('~/components/comm/newImg/index2.vue').default,
  }
})
export default class djhSwiper9 extends Vue {
  @Mutation private SET_TOKEN_DATA!: any;
  @State(state => state.web.distributorsObject) distributorsObject!: any;
  @Prop(Number) divWidth_?: any
  @Prop(Number) divHeight_?: any
  public openUrl:any
  private swiperOption: any = {
    loop : true,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.swiper-button-xiayige',
      prevEl: '.swiper-button-last',
    },
    on: {
        click: (e:any) => {   //设置loop之后，通过组件复制出来的slider没有点击事件,需要特殊处理
              console.log('sdfhjk',e,this,'');
              try {
                console.log('sdfhjk1112222',e.target.offsetParent.dataset,e.target.offsetParent.dataset.hturl);
                if(e.target.dataset && e.target.dataset.hturl) {
                  this.openUrl(e.target.dataset.hturl)
                } else if(e.target.offsetParent.dataset && e.target.offsetParent.dataset.hturl) {
                  this.openUrl(e.target.offsetParent.dataset.hturl)
                }
                console.log('sdfhjk11122224444444',e,this,'');
              } catch (error) {
                  console.log(error);
              }
          }
      }
  }
  mounted(){
    console.log('asdfiujil',this.distributorsObject.adverPosMap['4']);
  }
  get adArrayLunBo() {
    if(this.distributorsObject && this.distributorsObject.adverPosMap['4'] && this.distributorsObject.adverPosMap['4'].length > 0) {
      return this.distributorsObject.adverPosMap['4'].filter((v:any,i:any) => v.device == 0 && i != 0)
    } else {
      return []
    }
  }
  get adArrayZhaohui() {
    if(this.distributorsObject && this.distributorsObject.adverPosMap['4'] && this.distributorsObject.adverPosMap['4'].length > 0) {
      return this.distributorsObject.adverPosMap['4'].filter((v:any,i:any) => v.device == 0 && i == 0)
    } else {
      return []
    }
  }
  test() {
    console.dir(this.$refs.swiperContent)
  }
  clickHandle(item:any) {
    console.log(item);
  }
}
