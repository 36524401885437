
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"djhAd3",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class djhAd3 extends Vue {
    @Prop(Number) divWidth_?: any
    @Prop(Number) divHeight_?: any
    @Prop(Array) adArray_?: any
    private swiperOption: any = {
      loop: true,
      notNextTick: true,
      slidesPerView: 1,
      mousewheel: false,
      preloadImages: false,
      lazy: true,
      speed:36000,
      noSwiping:true,
      autoplay: {
        delay: 0,//平滑滑动切换
        disableOnInteraction:true,
      },
    }
    private AdArray_ : Array<any> = [{ad_remind:'鸿凯网络游戏服务平台hk1686-为您提供最安全的游戏交易,魔兽怀旧服，金币交易，账号交易，代练等服务。因为专注 所以专业  收款账户 支付宝956308804@qqWechat.com 微信hk168637 买号:QQ3007602008  卖号: QQ3007606488',url:'#'}] // 广告列表
    mounted(){
    }
    @Emit()
    getArray(){
      if (this.adArray_) {
        // console.log(this.adArray_.filter((v:any)=>v.device==0),'=======ad==ad=======')
        return this.adArray_.filter((v:any)=>v.device==0)
      } else {
        return []
      }
    }
  }
