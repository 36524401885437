
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  import  moment from 'moment';
  moment.locale("zh-cn");
  @Component({
    name:"goodsList4",
    components: {
      newImg: require('~/components/comm/newImg/index.vue').default,
    },
  })
  export default class goodsList4 extends Vue {
    @Prop(Number) divWidth_?: number
    @Prop(Number) divHeight_?: any
    @Prop(Object) goodsListData_?: any
    mounted(){
      console.log(this.goodsListData_,'======goodsListData_=======')
    }
    @Emit()
    //时间格式化，type：YYYY-MM-DD HH:mm:ss
    timeFormatUser(time:string,type:string){
      return moment(time).format(type);
    }
  }
