
import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
import {dealGoodsData, goodsListHttp} from "~/api/goods";
import {gameListHttp} from "~/api/comm";
@Component({
  name:"goodsList10",
  components: {
    newImg: require('~/components/comm/newImg/index.vue').default,
  }
})
export default class goodsList10 extends Vue {
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State(state => state.web.hotGamesArray) hotGamesArray!: any;
  @Prop({default:()=>[]}) hotGameList_?: any //游戏类型：1：网络游戏  2：手机游戏
  public errorToastFn:any
  public eventNotifyTost:any
  public openGoodsDetail:any
  private noMore: boolean = false;
  private goodsListData:any={
    total:0,
    goodsList:[]
  }
  private param:any={
    pages: 1,
    pageSize:8,
    gameId:74,
    goodsTypeId:1,
  }
  private ListData : any = []
  private nowGameId:any = -1
  private defaultItem:any = {
    thumbnail: 'img/001/zypa/2021/11/12/1636685246558',
    bigTitle: '暂无商品！！！！！',
    areaName: '',
    serverName: '-',
    price: '--',
    createTime: '--'
  }
  mounted(){
    setTimeout(() => {
      if(this.hotGamesArray && this.hotGamesArray.length > 0) {
        this.switchGame(this.hotGamesArray[0].id)
      }
      console.log('进来333',this.hotGamesArray,'');
    },600)
    console.log('进来333');
  }
  get priceInteger() {
    return (item:any) => {
      if(String(item.price).includes('.')) {
        return String(item.price).split('.')[0] + '.'
      } else {
        return String(item.price)
      }
    }
  }
  get priceMinority() {
    return (item:any) => {
      if(String(item.price).includes('-')) {
        return ''
      }
      if(String(item.price).includes('.')) {
        return String(item.price).split('.')[1]
      } else {
        return '.00'
      }
    }
  }
  // 切换游戏
  switchGame(gameId:any) {
    console.log('进来111');

    if(this.nowGameId == gameId) {
      return
    }
    this.nowGameId = gameId
    this.param = { pages: 1,pageSize:8,gameId:gameId,goodsTypeId:1 }
    this.getGoodsList()
  }
  async getGoodsList() {
    console.log('进来222');
    try {
      const {data} = await goodsListHttp({params:this.param})
      if(this.errorToastFn(data)) {
        this.goodsListData.total = data.data.total
        this.goodsListData.goodsList = data.data.goodsList
        if(data.data.goodsList.length < 8) {
          let needNum = this.param.pageSize - data.data.goodsList.length
          for(let i=0; i < needNum; i++) {
            this.goodsListData.goodsList.push(this.defaultItem)
          }
        }
      } else {
        this.goodsListData = { total:0, goodsList:[] }
      }
    } catch (error) {
      console.log(error);
      this.goodsListData = { total:0, goodsList:[] }
    }
  }
  prePage() {
    if(this.param.pages <= 1) {
      this.$message.warning('已经是第一页啦，宝！')
    } else {
      this.param.pages = this.param.pages - 1
      this.getGoodsList()
    }
  }
  nextPage() {
    if(this.goodsListData.total - this.param.pages * this.param.pageSize > 0) {    // 证明下一页还有数据
      this.param.pages = this.param.pages + 1
      this.getGoodsList()
    } else {
      this.$message.warning('已经是最后一页啦，宝！')
    }
  }
  // 打开商品详情
  goToGoodsDetail(item:any) {
    if(item.goodsSn) {
      this.openGoodsDetail({goodsSn:item.goodsSn,goodsTypeId:item.goodsTypeId},'_blank')
    } else {
      this.$message.warning('请看看其他商品吧！')
    }
  }
}
