
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {dealGoodsData, goodsListHttp} from "~/api/goods";
  import {gameListHttp} from "~/api/comm";
  @Component({
    name:"goodsList6",
    components: {
      newImg: require('~/components/comm/newImg/index.vue').default,
    }
  })
  export default class goodsList6 extends Vue {
    @Mutation private SET_HOTGAMES_DATA!: any;
    @State(state => state.web.hotGamesArray) hotGamesArray!: any;
    @Prop(Number) divWidth_?: any
    @Prop(Number) divHeight_?: any
    @Prop(Number) itemWidth_?: any
    @Prop({default:''}) gameType?: any //游戏类型：1：网络游戏  2：手机游戏
    @Prop({default:0}) index?: any //当前组件使用数组第几个游戏：0：第一个  1：第二个
    public errorToastFn:any
    public eventNotifyTost:any
    private goodsListData:any={
      total:0,
      goodsList:[]
    }
    private param:any={
      pageSize:8,
      gameId:1,
      goodsTypeId:1,
    }
    mounted(){
      setTimeout(async ()=>{
        this.getGoodsHttp()//商品数据
      },100)
    }
    @Emit()
    async getGoodsHttp(){
      console.log(this.hotGamesArray,this.gameType,'======this.hotGamesArray====')
      if (this.hotGamesArray && this.hotGamesArray.length>0) {
        if (!!this.gameType) {
          let flag=this.hotGamesArray.find((v:any)=>v.gameType==this.gameType)
          console.log(flag,'========flag=======3333')
          if (flag) {
            this.param.gameId=flag.id
            if (flag.goodsTypeStr) {
              this.param.goodsTypeId=flag.goodsTypeStr.split(',')[0]
            }
          }
        } else {
          let flag:any=this.hotGamesArray[this.index]
          if (flag) {
            this.param.gameId=flag.id
            if (flag.goodsTypeStr) {
              this.param.goodsTypeId=flag.goodsTypeStr.split(',')[0]
            }
          }
        }
        try{
          let {data}=await goodsListHttp({ params: this.param })
          if (this.errorToastFn(data)) {
            let data2:any=dealGoodsData(data.data)
            this.goodsListData=data2
          }
        }catch (error) {
          this.goodsListData={}
        }
      } else {
        console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr)
        this.getGameList(true)
      }
    }
    @Emit()
    // 获取游戏列表
    async getGameList(flag:boolean=false) {
      console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr,'============recGameStr===========')
      let gameList:any=[]
      try {
        let {data} = await gameListHttp()
        if (this.errorToastFn(data)){
          let hotGameArray:any=[]
          if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
            hotGameArray=this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',')
            console.log(hotGameArray,'===hotGameArray======')
            for (let i:number=0;i<hotGameArray.length;i++) {
              let gameFlag:any=data.data.find((res:any)=>res.id==hotGameArray[i])
              if (gameFlag) {
                gameList.push(gameFlag)
              }
            }
            if (gameList && gameList.length>0) {
              if (!!this.gameType) {
                let flag=gameList.find((v:any)=>v.gameType==this.gameType)
                if (flag) {
                  this.param.gameId=flag.id
                  if (flag.goodsTypeStr) {
                    this.param.goodsTypeId=flag.goodsTypeStr.split(',')[0]
                  }
                }
              } else {
                let flag:any=gameList[this.index]
                if (flag) {
                  this.param.gameId=flag.id
                  if (flag.goodsTypeStr) {
                    this.param.goodsTypeId=flag.goodsTypeStr.split(',')[0]
                  }
                }
              }
              try{
                let {data}=await goodsListHttp({ params: this.param })
                if (this.errorToastFn(data)) {
                  let data2:any=dealGoodsData(data.data)
                  this.goodsListData=data2
                }
              }catch (error) {
                this.goodsListData={}
              }
            }
          }
        }
      }catch (e) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
  }
