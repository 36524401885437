
import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
@Component({
  name:"djhSwiper7",
  components: {
    newImg2: require('~/components/comm/newImg/index2.vue').default,
  }
})
export default class djhSwiper extends Vue {
  @Prop(Array) swiperList_?: any
  private swiper_index:number=0//当前轮播图（走马灯）页面编号
  private swiperOption: any = {
    autoplay: {
      delay: 6000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
    },
    loop: true,
    lazy: true,
    on: {
      slideChangeTransitionEnd: () => {
        let swiper = (this.$refs.sub_swiperBox3 as any).swiper;
        this.swiper_index = swiper.activeIndex;
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  }
  private list:any=[]
  mounted(){
    try {
      const datas = [...this.swiperList_]
      this.list = [];
      while(datas.length > 0) {
        this.list.push(datas.splice(0, 3));
      }
      if (datas.length <= 3) {
        delete this.swiperOption.autoplay
      }
      console.log(this.list, '获取获取合作主播')
    } catch (e) {
      console.log(e, '获取获取合作主播失败！')
    }
  }
  @Emit()
  getSwiper(){
    if (this.swiperList_ && this.swiperList_.length>0) {
      const datas = [...this.swiperList_]
      const datas2 = [];
      while(datas.length > 0) {
        datas2.push(datas.splice(0, 3));
      }
      console.log(datas2, '获取获取合作主播1111111111111')
      return datas2
    } else {
      return []
    }
  }
}
