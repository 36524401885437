import { render, staticRenderFns } from "./index6.vue?vue&type=template&id=270c9384&scoped=true"
import script from "./index6.vue?vue&type=script&lang=ts"
export * from "./index6.vue?vue&type=script&lang=ts"
import style0 from "./index6.vue?vue&type=style&index=0&id=270c9384&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "270c9384",
  null
  
)

export default component.exports