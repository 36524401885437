
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {getNewsListHttp} from "~/api/comm";
  @Component({
    name:"newsList6",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class newsList6 extends Vue {
    @Prop(Number) divWidth_?: any
    @Prop(Number) divHeight_?: any
    @Prop(Array) adArray_?: any
    public errorToastFn:any
    private swiperOption: any = {
      loop: true,
      notNextTick: true,
      slidesPerView: 1,
      mousewheel: false,
      preloadImages: false,
      lazy: true,
      speed:36000,
      noSwiping:true,
      autoplay: {
        delay: 0,//平滑滑动切换
        disableOnInteraction:true,
      },
    }
    private newsList : Array<any> = [] // 公告列表
    mounted(){
      this.getNewsList()
    }
    @Emit()
    async getNewsList(){
      try {
        let {data} = await getNewsListHttp({params:{newsType:11}})
        if (this.errorToastFn(data)) {
          if (data.data.list.length>0) {
            this.newsList=data.data.list
            console.log(this.newsList,'===d=d=d=s==s===')
          }
        }
      } catch (error) {
        this.newsList=[]
      }
    }
  }
