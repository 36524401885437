
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  import  moment from 'moment';
  import {latestDealRecordHttp} from "~/api/distributor";
  moment.locale("zh-cn");
  @Component({
    name:"latestDealGoods2",
    components: {
      newImg: require('~/components/comm/newImg/index.vue').default,
    },
  })
  export default class latestDealGoods2 extends Vue {
    @Prop(Number) divWidth_?: number
    @Prop(Number) divHeight_?: any
    @Prop(Object) goodsListData_?: any
    public errorToastFn:any
    public eventNotifyTost:any
    private orderListData:any={}
    private swiperOption: any = {
      loop: true,
      autoplay: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      freeMode:true,
      noSwiping:false,
      hideOnClick:true,
      slidesPerGroup : 1,
      slidesPerView: 4,
      preloadImages: false,
      lazy: true,
    }
    mounted(){
      this.getDealGoods()
    }
    @Emit()
    async getDealGoods(){
      try {
        let {data}=await latestDealRecordHttp({params:{pageSize:8}})
        if (this.errorToastFn(data)) {
          this.orderListData=data.data
          console.log(this.orderListData,'=======orderListData======')
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
    @Emit()
    //时间格式化，type：YYYY-MM-DD HH:mm:ss
    timeFormatUser(time:string,type:string){
      return moment(time).format(type);
    }
  }
