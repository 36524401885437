
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
@Component({
  name: "djhHeaderNavIndex3",
})
export default class djhHeaderNavIndex3 extends Vue {
  @Prop(Number) topMenuWidth_?: any;
  @Prop(Array) headerArray_?: any;
  private index_: any = -10; // 选中第几个
  private specil: number = 0; // 判断下划线是否需要进行特殊样式修改。例如：DNF代练，下划线需要变长（样式控制变量）
  private isShowTopMenu: Boolean = true; // 是否显示菜单栏
  private topMenuUnderLeft: number = -660; // 菜单栏下划线左边距离
  private topMenuLenth: any = 6; // 菜单栏长度
  private isFirstActive: any = true;
  private topMenuFn: Array<any> = [
    { title: "网站首页", htUrl: "/" },
    { title: "帐号交易", htUrl: "/wares/?gt=1&gid=5" },
    { title: "金币交易", htUrl: "/wares/?gt=2&gid=5" },
    { title: "游戏代练", htUrl: "/" },
    { title: "游戏陪玩", htUrl: "/" },
    { title: "资源群", htUrl: "/" },
  ]; // 菜单列表
  mounted() {
    if (this.headerArray_ && this.headerArray_.length > 0) {
      this.topMenuLenth = this.headerArray_.length;
    }
    console.log(this.$route.path, "=====path===========");
    if (this.headerArray_) {
      if (this.$route.query.goodsTypeId == "11") {
        this.headerArray_.forEach((res: any, index: number) => {
          if (res.url.indexOf("goodsTypeId=11") > -1) {
            this.index_ = index + 1;
            this.topMenuUnderLeft =
              (this.topMenuWidth_ / this.topMenuLenth) * this.index_ -
              this.topMenuWidth_ / this.topMenuLenth / 2 -
              15;
            return;
          }
        });
      } else if (this.$route.query.goodsTypeId == "2") {
        this.headerArray_.forEach((res: any, index: number) => {
          if (res.url.indexOf("goodsTypeId=2") > -1) {
            this.index_ = index + 1;
            this.topMenuUnderLeft =
              (this.topMenuWidth_ / this.topMenuLenth) * this.index_ -
              this.topMenuWidth_ / this.topMenuLenth / 2 -
              15;
            return;
          }
        });
      } else if (this.$route.query.goodsTypeId == "8") {
        this.headerArray_.forEach((res: any, index: number) => {
          if (res.url.indexOf("goodsTypeId=8") > -1) {
            this.index_ = index + 1;
            this.topMenuUnderLeft =
              (this.topMenuWidth_ / this.topMenuLenth) * this.index_ -
              this.topMenuWidth_ / this.topMenuLenth / 2 -
              15;
            return;
          }
        });
      } else if (
        this.$route.query.goodsTypeId == "1" ||
        this.$route.query.goodsTypeId
      ) {
        console.log("=====uuuuuuuuu有有有有有有有");
        this.headerArray_.forEach((res: any, index: number) => {
          if (res.url.indexOf("goodsTypeId=1&") > -1) {
            this.index_ = index + 1;
            this.topMenuUnderLeft =
              (this.topMenuWidth_ / this.topMenuLenth) * this.index_ -
              this.topMenuWidth_ / this.topMenuLenth / 2 -
              15;
            console.log(
              index,
              this.topMenuUnderLeft,
              "=====uuuuuuuuu有有有有有有有22222"
            );
            return;
          }
        });
      } else if (this.$route.path.indexOf("characteristicService") > -1) {
        console.log("=====uuuuuuuuu有有有有有有有");
        this.headerArray_.forEach((res: any, index: number) => {
          console.log(
            res.url.indexOf("goodsTypeId=7") > -1,
            res.url,
            "=====有有有有有有有"
          );
          if (res.url.indexOf("goodsTypeId=7") > -1) {
            this.index_ = index + 1;
            this.topMenuUnderLeft =
              (this.topMenuWidth_ / this.topMenuLenth) * this.index_ -
              this.topMenuWidth_ / this.topMenuLenth / 2 -
              15;
            console.log(
              index,
              this.topMenuUnderLeft,
              "=====uuuuuuuuu有有有有有有有22222"
            );
            return;
          }
        });
      } else if (this.$route.path == "/") {
        this.index_ = this.headerArray_.findIndex((v: any) => v.url == "/") + 1;
        // this.index_=1
        this.topMenuUnderLeft =
          (this.topMenuWidth_ / this.topMenuLenth) * this.index_ -
          this.topMenuWidth_ / this.topMenuLenth / 2 -
          15;
        console.log(
          this.topMenuUnderLeft,
          "=====uuuuuuuuu有有有有有有有444444"
        );
      }
    }

    console.log(this.index_, "========index_========");
  }
  @Emit()
  leftmove(num: number) {
    //鼠标移入menu option 改变下划线位置
    if (num !== 0) {
      this.isFirstActive = false;
    }
    this.specil = num;
    this.topMenuUnderLeft =
      (this.topMenuWidth_ / this.topMenuLenth) * num -
      this.topMenuWidth_ / this.topMenuLenth / 2 -
      15;
    // console.log(this.topMenuUnderLeft,'=====topMenuUnderLeft--move--')
  }
  @Emit()
  leftreset() {
    this.isFirstActive = true;
    //鼠标移出menu option 下划线复位
    this.specil = -1;
    this.topMenuUnderLeft =
      (this.topMenuWidth_ / this.topMenuLenth) * this.index_ -
      this.topMenuWidth_ / this.topMenuLenth / 2 -
      15;
    // console.log(this.topMenuUnderLeft,'=====topMenuUnderLeft--leave--')
  }
}
