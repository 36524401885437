
  import { Component, Vue, Emit, Prop, Watch} from '~/node_modules/vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from '~/node_modules/vuex-class'
  import  moment from '~/node_modules/moment';
  import {getNewsListHttp} from "~/api/comm";
  import { queryVerifyCustomerHttp } from '~/api/cusSer'
  import { addBlackHttp } from '~/api/black'
  moment.locale("zh-cn");
  @Component({
    name:"djhAd21",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class djhAd21 extends Vue {
    @Mutation private SET_HOTGAMES_DATA!: any;
    @State(state => state.web.hotGamesArray) hotGamesArray!: any;
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    @Prop(Number) divWidth_?: number
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    public qqRegular:any
    private newsList:Array<any> = []
    private isCheckCurstomer:boolean = true
    private inputStr: string = ''
    private inputWxStr: string = ''   // 因为刚开始只考虑了qq和黑号 微信后来添加单独处理
    private isShowMask: boolean = false
    mounted(){
      console.log('sdgfeg',this.distributorsObject);
      this.getNewsList()
    }
    // 左上角的几个大图
    get ad2Array() {
      if(this.distributorsObject && this.distributorsObject.adverPosMap['2'] && this.distributorsObject.adverPosMap['2'].length>0) {
        let tampArr = this.distributorsObject.adverPosMap['2'].filter((v:any) => v.device==0)
        return tampArr
      }
      return []
    }
    // 右下角的六个一排按钮
    get treeButtonArray() {
      if(this.distributorsObject && this.distributorsObject.adverPosMap['1'] && this.distributorsObject.adverPosMap['1'].length>0) {
        let tampArr = this.distributorsObject.adverPosMap['1'].filter((v:any) => v.device==0)
        return tampArr
      }
      return []
    }
    // 获取新闻列表 三个
    async getNewsList() {
      try {
        const {data} = await getNewsListHttp({params:{pages:1,pageSize:3,newsType:1,orderTypeStr:"sort=1"}})
        if(this.errorToastFn(data)) {
          this.newsList = data.data.list
        }
      } catch (error) {
        console.log(error);
      }
    }
    // 切换客服验证tab
    clickTabHandle(status:any) {
      this.inputStr = ''
      this.isCheckCurstomer = status
    }
    // 请求验证
    async goToCheck() {
      let noticeMsg = ''
      if(this.isCheckCurstomer) {
        if(!this.qqRegular.test(this.inputStr)) {
          this.$message({
            // message: '经验证：12313131为假客服！',
            message: '请输入正确的客服QQ号！',
            offset: 530,
            duration: 5000,
            customClass: 'myMessageCss'
          })
          return
        } else {
          try {
            const {data} = await queryVerifyCustomerHttp({params:{queryType:1,queryValue:this.inputStr}})
            if(data && data.code == 0 && Object.keys(data.data).length>0) {
              noticeMsg = `经验证：${this.inputStr}为真客服！`
            } else {
              noticeMsg = `经验证：${this.inputStr}为假客服！`
            }
            this.$message({
              message: noticeMsg,
              offset: 530,
              duration: 5000,
              customClass: 'myMessageCss'
            })
            this.inputStr = ''
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        if(!this.inputStr) {
          this.$message({
            message: '请输入要查询的黑名单！',
            offset: 530,
            duration: 5000,
            customClass: 'myMessageCss'
          })
        } else {
          try {
            let distId = 110
            if(this.$store.state.web.distributorsObject.distId) {
              distId = this.$store.state.web.distributorsObject.distId
            }
            const {data} = await addBlackHttp({params:{pages:1,pageSize:10,distId:distId,keyWord:this.inputStr}}) 
            if(data && data.code == 0 && data.data.list.length==0) {
              noticeMsg = '该用户未被拉入黑名单，如有后续违规请联系客服，避免线下交易！'
            } else {
              noticeMsg = '该用户已被拉入黑名单，请终止交易！'
            }
            this.$message({
              message: noticeMsg,
              offset: 530,
              duration: 5000,
              customClass: 'myMessageCss'
            })
            this.inputStr = ''  
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
    // 微信验证另外处理
    async goToCheckWx() {
      let noticeMsg = ''
      if(this.inputWxStr.length < 6) {
        this.$message({
          message: '请输入正确的客服微信号！',
          offset: 530,
          duration: 5000,
          customClass: 'myMessageCss'
        })
      } else {
        try {
          const {data} = await queryVerifyCustomerHttp({params:{queryType:2,queryValue:this.inputWxStr}})
          if(data && data.code == 0 && Object.keys(data.data).length>0) {
            noticeMsg = `经验证：${this.inputWxStr}为真客服！`
          } else {
            noticeMsg = `经验证：${this.inputWxStr}为假客服！`
          }
          this.$message({
            message: noticeMsg,
            offset: 530,
            duration: 5000,
            customClass: 'myMessageCss'
          })
          this.inputWxStr = ''
        } catch (error) {
          console.log(error);
        }
      }
    }
    closeDialog() {
      this.isShowMask = false
    }
    showDialog(num:any) {
      if(num == 0) {
        this.isCheckCurstomer = false
      } else if(num == 1) {
        this.isCheckCurstomer = true
      }
      this.isShowMask = true
    }
    
  }
