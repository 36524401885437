
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  //外部点击
  import '../../utils/directive'
  import {dealGamefilter, gamefilterHttp} from "~/api/comm";
  import {addUserRoleCardHttp, getUserRoleCardHttp, removeUserRoleCardHttp} from "~/api/user";
  import {queryQucikCoinHttp} from "~/api/quickcoin";
  @Component({
    name:"fastGold",
  })
  export default class fastGold extends Vue {
    @Mutation private SET_GAMEFILTER_DATA!: any;
    @State(state => state.web.gamefilterArray) gamefilterArray!: any;
    @Mutation private SET_FASTGOLDORDER_DATA!: any;
    @State(state => state.web.fastGoldOrder) fastGoldOrder!: any;
    @Prop(Number) divWidth_?: number //宽度
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    public openUrl:any
    public getCookie:any
    private selected:number=1 //选中的tab标签
    private selectRoleCardId:any='' //选中的角色卡ID
    private roleCardText:string='请选择角色卡' //角色卡input提示文字
    private selectRoleCardFlag:boolean=false //是否选中了角色卡,控制是否显示

    private crossList:any=[]//跨区列表
    private areaList:any=[]//大区列表
    private serverList:any=[]//服务器列表

    private roleCardObject:any={
      gameId:1
    } //角色卡信息
    private roleCardList:any=[] //可供选择的角色卡
    private isRoleCardEdit:boolean=false //角色卡是否是重新编辑
    private isShowRoleCardEdit:boolean=false //角色卡弹框是否显示

    private info:any= {
      goodsNum: '',//购买数量
      totalPrice: '',//购买金额
      unitPrice: '',//根据所选角色卡获取相应跨区的万金价
      addPrice: 0,//根据所选角色卡获取相应跨区的加价价格
      goodsId: null,//根据所选角色卡获取相应跨区金币的商品ID
      roleCardId:'',//角色卡id
      crossId: '',//跨区id
      gameName:'',//游戏名
      serverName:'',//服务器名
      roleName:'',//角色名
      qkAreaId:'',//快金提供商的大区id
      qkServerId:'',//快金提供商的服务器id
      price:'',//价格
      phoneNum: null,//联系电话
      qqNum: null,//联系QQ
    }
    mounted() {
      setTimeout(()=>{
        this.getGameFilter()
      },50)
    }
    @Emit()
    //拉取个人角色卡
    async getGoldRoleCardList(){
      try {
        let {data}=await getUserRoleCardHttp({params:{'gameId': 1}});
        if (this.errorToastFn(data)) {
          this.roleCardList=data.data
        }
      } catch (error) {
        console.log(error)
        this.eventNotifyTost('提示', '网络超时', 'warning')
        this.roleCardList=[]
      }
    }
    @Emit()
    //拉取大区服务器
    async getGameFilter(){
      console.log(this.gamefilterArray)

      console.log(!this.gamefilterArray[1],'====!gamefilterFlag==哈哈哈哈哈=啦啦啦啦====')
      if (!this.gamefilterArray[1]) { //不存在游戏配置
        try {
          let{data}=await gamefilterHttp({params:{gameId:1}})
          if (this.errorToastFn(data)) {
            this.gamefilterArray[1]=dealGamefilter(data.data)
            this.SET_GAMEFILTER_DATA(this.gamefilterArray)
            if (this.gamefilterArray[1]){
              //获取大区
              let areafilter :any=Object.values(this.gamefilterArray[1]).find((v:any)=>v.fieldKey=="areaId")
              this.areaList=areafilter.options

              //获取跨区
              let crossfilter :any=Object.values(this.gamefilterArray[1]).find((v:any)=>v.fieldKey=="crossId")
              this.crossList=crossfilter.options
              //默认第一个跨区
              if (this.crossList[0]) {
                this.info.crossId=this.crossList[0].specialId
                this.selectCross(true)
              }
            }
          }
        } catch (error) {
          this.eventNotifyTost('提示', '网络超时', 'warning')
        }
      } else { //存在游戏配置
        //获取大区
        let areafilter :any=Object.values(this.gamefilterArray[1]).find((v:any)=>v.fieldKey=="areaId")
        if (areafilter) {
          this.areaList=areafilter.options
        }

        //获取跨区
        let crossfilter :any=Object.values(this.gamefilterArray[1]).find((v:any)=>v.fieldKey=="crossId")
        this.crossList=crossfilter.options
        //默认第一个跨区
        if (this.crossList[0]) {
          this.info.crossId=this.crossList[0].specialId
          this.selectCross(true)
        }
      }
    }
    @Emit()
    //选择tab栏
    selctFastGolType(v:number){
      this.selected=v
    }
    @Emit()
    //选择跨区:flag:是否清空角色卡
    async selectCross(flag:boolean){
      console.log('22222222')
      try {
        let {data}=await queryQucikCoinHttp({params:{crossId:this.info.crossId}})
        if (this.errorToastFn(data)) {
          this.info.addPrice=data.data.addPrice
          this.info.unitPrice=data.data.unitPrice
          this.info.goodsId=data.data.goodsId
          this.info.qkAreaId=data.data.qkAreaId
          this.info.qkServerId=data.data.qkServerId
          if (flag) { //清空角色卡
            this.roleCardText='请选择角色卡'
            this.info.roleCardId=''
            this.selectRoleCardId=''
          }
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
    @Emit()
    //选择角色卡
    selectRoleCard(item:any){
      this.roleCardText=`${item.serverName}-${item.roleName}`
      this.selectRoleCardId=item.id
      //订单信息赋值
      this.info.roleCardId=item.id
      this.info.gameName=item.gameName
      this.info.serverName=item.serverName
      this.info.roleName=item.roleName
      //自动同步跨区
      this.info.crossId=item.crossId
      //关闭列表弹框
      this.selectRoleCardFlag=false
      //重新拉取跨区比例
      this.selectCross(false)
    }
    @Emit()
    //角色卡选择大区
    selectArea(){
      let serverfilter :any=Object.values(this.areaList).find((v:any)=>v.specialId==this.roleCardObject.areaId)
      console.log(serverfilter,'===aa=a=a==')
      if (serverfilter) {
        this.serverList=serverfilter.secondOptions
      }
      if (this.serverList.length==0) {
        this.serverList=[]
      }
    }
    @Emit()
    // 显示角色卡弹框
    showRoleCard(v:boolean){
      if (!this.getCookie('token')){
        this.openUrl('/login')
      } else {
        this.isRoleCardEdit=v
        this.isShowRoleCardEdit=true
        this.getGameFilter()
      }
    }
    @Emit()
    // 显示角色卡列表
    showRoleCardList(){
      this.selectRoleCardFlag=true
      this.getGoldRoleCardList()
    }
    @Emit()
    // 关闭角色卡列表
    closeRoleCardList(){
      console.log('1111111111')
      this.selectRoleCardFlag=false
    }
    @Emit()
    // 删除角色卡
    async delRoleCard(id:number){
      console.log('删除辑角色卡')
      try {
        let {data}=await removeUserRoleCardHttp({method: 'post', data: {id:id}})
        if (this.errorToastFn(data)) {
          this.eventMsgTost('已删除角色卡', 'success')
          if (data.data.id==this.selectRoleCardId) {
            this.selectRoleCardId=''
            this.info.roleCardId='' //参数赋值
            this.roleCardText='请选择角色卡'
          }
          this.getGoldRoleCardList()
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
    @Emit()
    //角色卡验证方法
    checkInputData(){
      if(!this.roleCardObject.serverId || !this.roleCardObject.areaId){
        this.eventMsgTost('游戏大区未选择','error');
        return false;
      }
      if(!this.roleCardObject.serverId){
        this.eventMsgTost('游戏服务器未选择','error');
        return false;
      }
      if(!this.roleCardObject.roleName){
        this.eventMsgTost('error','角色名不能为空');
        return false;
      }
      if (this.roleCardObject.roleName) {
        let first:any=''
        let end:any=''
        first=this.roleCardObject.roleName.substring(0,1)
        end=this.roleCardObject.roleName.substring(this.roleCardObject.roleName.length-1,this.roleCardObject.roleName.length)
        if (first.charCodeAt()==32 || end.charCodeAt()==32) {
          this.eventMsgTost('角色名首尾不能有空格','error');
          return false
        }
      }
      return true;
    }
    @Emit()
    // 编辑角色卡
    async editRoleCard(){
      console.log(this.roleCardObject,'重新编辑角色卡')
      let roleCardObject3:any={...this.roleCardObject}
      roleCardObject3.id=this.selectRoleCardId
      if (this.checkInputData()) {
        try {
          let {data}=await addUserRoleCardHttp({method: 'post', data: roleCardObject3})
          if (this.errorToastFn(data)) {
            this.eventMsgTost('已修改', 'success')
            // 如果新增角色卡时满足条件，则默认选中
            if ((this.info.crossId && data.data.crossId==this.info.crossId) || (data.data.gameId==1 && data.data.areaName==this.info.areaName && data.data.serverName==this.info.serverName)) {
              this.selectRoleCardId=data.data.id; //选中的角色卡的id
              this.info.roleCardId=data.data.id //参数赋值
              this.roleCardObject.areaId=data.data.areaId
              this.roleCardObject.serverId=data.data.serverId
              this.roleCardText=data.data.areaName+'/'+data.data.serverName+'/'+data.data.roleName+(data.campId?`/${data.data.campId}`:'') //为显示赋值
            }
            this.isShowRoleCardEdit=false //隐藏新增角色卡弹窗
            this.getGoldRoleCardList()
          }
        } catch (error) {
          this.eventNotifyTost('提示', '网络超时', 'warning')
        }
      }
    }
    @Emit()
    // 新增角色卡
    async plusRoleCard(){
      console.log(this.roleCardObject,'新增角色卡')
      let roleCardObject2:any={...this.roleCardObject}
      if (this.checkInputData()) { //数据验证
        try {
          let {data}=await addUserRoleCardHttp({method: 'post', data: roleCardObject2})
          if (this.errorToastFn(data)) {
            this.eventMsgTost('新增成功', 'success')
            // 如果新增角色卡时满足条件，则默认选中
            if ((this.info.crossId && data.data.crossId==this.info.crossId) || (data.data.gameId==1 && data.data.areaName==this.info.areaName && data.data.serverName==this.info.serverName)) {
              this.selectRoleCardId=data.data.id; //选中的角色卡的id
              this.info.roleCardId=data.data.id //参数赋值
              this.roleCardObject.areaId=data.data.areaId
              this.roleCardObject.serverId=data.data.serverId
              this.roleCardText=data.data.areaName+'/'+data.data.serverName+'/'+data.data.roleName+(data.campId?`/${data.data.campId}`:'') //为显示赋值
            }
            this.isShowRoleCardEdit=false //隐藏新增角色卡弹窗
            this.getGoldRoleCardList()
          }
        } catch (error) {
          this.eventNotifyTost('提示', '网络超时', 'warning')
        }
      }
    }
    @Emit()
    // 确认提交
    submitOrder(){
      if (!this.info.roleCardId) {
        this.eventMsgTost('请选择角色卡', 'warning')
      } else if (!this.info.unitPrice) {
        this.eventMsgTost('网络异常请刷新重试！', 'warning')
      } else if (this.selected===1 && !this.info.totalPrice) {
        this.eventMsgTost('请填写购买金额', 'warning')
      } else if (this.selected===0 && !this.info.goodsNum) {
        this.eventMsgTost('请填写购买数量', 'warning')
      } else {
        this.SET_FASTGOLDORDER_DATA(this.info)
        this.openUrl('/fastGold/fastGoldOrder')
      }
    }
    @Emit()
    //获取价格
    getPrice(){
      if (this.selected==1 && this.info.totalPrice) {
        this.info.price=parseFloat(String(Number(this.info.totalPrice))).toFixed(2)
        return parseFloat(String(Number(this.info.totalPrice))).toFixed(2)
      } else if (this.selected===0 && this.info.unitPrice) {
        this.info.price=parseFloat(String(Number(this.info.goodsNum)*Number(this.info.unitPrice)*Number(1+Number(this.info.addPrice)))).toFixed(2)
        return parseFloat(String(Number(this.info.goodsNum)*Number(this.info.unitPrice)*Number(1+Number(this.info.addPrice)))).toFixed(2)
      } else {
        this.info.price='0.00'
        return '0.00'
      }
    }
    @Emit()
    //获取价格
    getPrice2(){
      console.log(this.info.addPrice,this.info.unitPrice,'====addPrice=======unitPrice========')
      if (this.info.unitPrice) {
        return parseFloat(String(1/(Number(this.info.unitPrice)*Number(1+Number(this.info.addPrice))))).toFixed(2)
      } else {
        return '0'
      }
    }
  }
