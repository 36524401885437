
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {distOrderCountHttp} from "~/api/order";
  @Component({
    name:"index6SwiperLeft",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
      littleMenu: require('~/components/comm/littleMenu/index1.vue').default,
    }
  })
  export default class index6SwiperLeft extends Vue {
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any

    private number : any = '0' // 交易笔数
    private gameList : any = [] // 游戏条目（第六广告位的数据）

    async mounted() {
      // 从vuex中的分站基本信息里获取到广告位的数据
      this.gameList = this.distributorsObject.adverPosMap?this.distributorsObject.adverPosMap['6']:[]
      await this.getDistOrderCount()
    }
    @Emit() // 获取成交笔数
    async getDistOrderCount(){
      try {
        let {data} = await distOrderCountHttp()
        if (this.errorToastFn(data)) {
          if (data.data) {
            let flag=0
            if (this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.distBaseConf.statisMap) {
              flag=Number(flag)+Number(this.$store.state.web.distributorsObject.distBaseConf.statisMap)
            }
            this.number=Number(data.data.count)+flag
          }
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
    @Emit() // 处理总成交数
    dealCountNumber(number:any){
      if (Number(number)<10) {
        return `00000${number}`
      } else if (Number(number)<100) {
        return `0000${number}`
      } else if (Number(number)<1000) {
        return `000${number}`
      } else if (Number(number)<10000) {
        return `00${number}`
      } else if (Number(number)<100000) {
        return `0${number}`
      } else {
        return number
      }
    }
  }
