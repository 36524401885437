
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {dealGoodsData, goodsListHttp} from "~/api/goods";
  @Component({
    name:"receGoods",
    components: {
      goodsList1: require('~/components/comm/goodsList/goodsList1.vue').default,
    }
  })
  export default class receGoods extends Vue {
    @Prop(Number) divWidth_?: any
    @Prop(Number) itemWidth_?: any
    @Prop(Object) colorConfig_?: any
    private errorToastFn : any
    private openGoodsList : any
    private openUrl : any
    private isHaveMenu : Boolean = true // 是否存在菜单
    private isShowDefaultImg : Boolean = false // 是否显示默认图片
    private gameTitleId : number = -1 // 当前选中的标签id
    private gameTitleId2 : number = -1 // 复制当前选中的标签id，以备标签还原时使用
    private gameMenuList : Array<any> = []
    private gameTitleListData : Array<any> = [
      { "text": "顶级推荐", id: -1, goodsTypeId:1, game:1, orderTypeStr: 'createTime=1' },
      { "text": "最新上架", id: -2, orderTypeStr: 'createTime=1' },
      { "text": "关于我们", id: -4, htUrl: "/pageHelpCenter?codes=1574147388320676pkial-0-23" },
    ]//默认菜单
    private goodsListData : any = {} //商品数据
    async mounted(){
      // treeMenuButs
      setTimeout(async()=>{
        if (this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.treeMenuButMap) {
          let flag:any=this.$store.state.web.distributorsObject.treeMenuButMap.pc3
          // let flag:any=this.$store.state.web.distributorsObject.treeMenuButs.filter((v:any) => v.butType=='pc3')
          if (flag) {
            this.gameMenuList=flag
            this.getGoodsHttp()//商品数据
          } else {
            this.getGoodsHttp()//商品数据
          }
          console.log(flag,'====flag=====')
        }
      },50)
    }
    @Emit()
    async getGoodsHttp(){
      let game_id=1
      console.log(this.gameMenuList,'=====gameMenuList=======')
      if (this.gameMenuList.length>0 && this.gameMenuList[0].adverTag=='goodsApi') {//配置菜单第一项满足默认拉取的规则
        console.log('配置菜单第一项满足默认拉取的规则')
        const querystring = require("querystring");
        let param=querystring.parse(this.gameMenuList[0].htUrl)
        param.pageSize=12
        if(param.gid){
          param['gameId']=param.gid
          delete param.gid
        }
        if(param.gt){
          param['goodsTypeId']=param.gt
          delete param.gt
        }
        try{
          let {data}=await goodsListHttp({ params: param })
          if (this.errorToastFn(data)) {
            let data2:any=dealGoodsData(data.data)
            this.goodsListData=data2
            console.log(this.goodsListData,'==============goodsListData==========')
            this.gameTitleId=this.gameMenuList[0].id
            this.gameTitleId2=this.gameMenuList[0].id
            if (data.data.total==0){
              this.isShowDefaultImg=true
            }
          }
        }catch (error) {
          this.goodsListData={}
          this.isShowDefaultImg=true
        }
      } else {//配置菜单第一项不满足默认拉取的规则
        game_id=1
        console.log('配置菜单第一项不满足默认拉取的规则')
        try{
          let {data}=await goodsListHttp({ params: { gameId: game_id, pageSize:12,goodsTypeId:1 } })
          if (this.errorToastFn(data)) {
            let data2:any=dealGoodsData(data.data)
            this.goodsListData=data2
            if (this.gameMenuList.length>0) {
              this.gameTitleId = -6
              this.gameTitleId2 = -6
            } else {
              this.gameTitleId = -1
              this.gameTitleId2 = -1
            }
            if (data.data.total==0){
              this.isShowDefaultImg=true
            }
          }
        }catch (error) {
          this.goodsListData={}
          this.isShowDefaultImg=true
        }
      }
    }
    @Emit()
    async selectGoodsTypeFn(item:any) {
      this.gameTitleId = item.id;
      this.gameTitleId2 = item.id;
      console.log(this.gameTitleId)
      if (item.adverTag=='goodsApi') {
        const querystring = require("querystring");
        console.log(item,querystring.parse(item.htUrl));
        let param=querystring.parse(item.htUrl)
        param.pageSize=12
        this.goodsListData={}
        this.isShowDefaultImg=false
        this.getGoodsHttp2(param)
      } else {
        if (item.adverTag && item.adverTag=='self') {
          this.openUrl(item.htUrl,'_self')
        } else {
          this.openUrl(item.htUrl,'_blank')
        }
      }
    }
    @Emit()
    async getGoodsHttp2(param:any){
      if(param.gid){
        param['gameId']=param.gid
        delete param.gid
      }
      if(param.gt){
        param['goodsTypeId']=param.gt
        delete param.gt
      }
      try{
        let {data}=await goodsListHttp({ params: param })
        if (this.errorToastFn(data)) {
          let data2:any=dealGoodsData(data.data)
          this.goodsListData=data2
          console.log(this.goodsListData,'==============goodsListData==========')
          if (data.data.total==0){
            this.isShowDefaultImg=true
          }
        }
      }catch (error) {
        this.goodsListData={}
        this.isShowDefaultImg=true
      }
    }
    @Emit()
    goGoodsList() {
      let gameId:number=1
      if (this.$store.state.web.distributorsObject.distBaseConf && this.$store.state.web.distributorsObject.distBaseConf.teGameId) {
        console.log(this.$store.state.web.distributorsObject.distBaseConf.teGameId)
        gameId=Number(this.$store.state.web.distributorsObject.distBaseConf.teGameId)
      }
      this.openGoodsList({gameId:gameId},'_self')
    }
    @Emit()
    mouseoutFn(event:any, sign:boolean, colorObj:any) {
      this.gameTitleId=this.gameTitleId2
      if (!sign) {
        let el = event.currentTarget;
        el.style.color = colorObj.color;
        el.style.background = colorObj.bgColor;
      }
    }
    @Emit()
    mouseoverFn(event:any, sign:boolean, colorObj:any,id:number) {
      this.gameTitleId=id
      if (!sign) {
        let el = event.currentTarget;
        el.style.color = colorObj.hoverColor;
        el.style.background = colorObj.hoverBgColor;
      }
    }
  }
