
  import { Component, Vue, Emit, Prop, Watch} from '~/node_modules/vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from '~/node_modules/vuex-class'
  import  moment from '~/node_modules/moment';
  import {gameListHttp,goodsTypeListHttp,ipinfo} from "~/api/comm";
  import {getWowRegion} from "~/api/goods";
  moment.locale("zh-cn");
  @Component({
    name:"djhAd27",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class djhAd27 extends Vue {
    @Mutation private SET_HOTGAMES_DATA!: any;
    @State(state => state.web.hotGamesArray) hotGamesArray!: any;
    @Prop(Number) divWidth_?: number
    @Prop(Array) adArray_?: any
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    private queryParm:any={}//筛选
    private mouseIndex:number=-1
    private gameList:any=[]
    private words:Array<any>=[
      {name:'热门游戏',type:'hot',value:'-1'},
      {name:'手游',type:'type',value:'2'},
      {name:'端游',type:'type',value:'1'}
    ]
    public moreFilterList: any[] = [];
  public showMoreArea: boolean = false;
  public clickItem: any = {}
  public openUrl:any
    mounted(){

     
      setTimeout(async ()=>{
        if (this.hotGamesArray && this.hotGamesArray.length>0) {
          this.gameList=this.hotGamesArray
  
          
        } else {
          
          await this.getGameList(true)
        }
      },50)
     

    }
    get getArray(){
     
      if (this.hotGamesArray && this.hotGamesArray.length>0) {
        
        let tempAdArray = [...this.hotGamesArray]
        tempAdArray.forEach(v => {
        
          if(v.goodsTypeAndNameMap && String(v.goodsTypeAndNameMap).length > 0 && String(v.goodsTypeAndNameMap).includes('{')) {
            v['goodsTypeNameArr'] = Object.values(JSON.parse(v.goodsTypeAndNameMap))
          }
        });
        let result = tempAdArray.filter((item:any)=> item.isShow == 0 )
       
        return result
        
      } else {
        return []
      }
    }
    handleClickGameArea(id:number){
      

        this.openUrl('/wares/?gt=1&gid='+id,'_blank') 
        

      this.showMoreArea  = false
  }
    async handleCheckFilter(item:any){
     
     
      
    this.showMoreArea = false
    this.clickItem = {}
    const params = {
      gameId: item.id,
    };
    let res: any = await getWowRegion({ params: params });
    let { data } = res.data;
   
    if (res.data.code === 0) {
      if (data.wowList.length > 1) {
        this.moreFilterList = data.wowList;
        this.clickItem = item;
        this.showMoreArea = true
      } else if (data.wowList.length > 0) {
        const id =data.wowList[0].SonId
        
        this.openUrl('/wares/?gt=1&gid='+id,'_blank') 
    
      } else {
        
        this.openUrl('/wares/?gt=1&gid='+item.id,'_blank') 
      }
    }
    }
    @Emit()
    // 获取游戏列表
    async getGameList(flag:boolean=false) {
    
      let IP: any = await ipinfo();

      try {
        let {data} = await gameListHttp({params:{
          drainage:'%E7%BD%91%E7%AB%99%E9%A6%96%E9%A1%B5',
          ip:IP?.data.data,
        }})
        if (this.errorToastFn(data)){
          if (flag) {
            this.gameList=[]
            let hotGameArray:any=[]
            if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
              hotGameArray=this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',')
              for (let i:number=0;i<hotGameArray.length;i++) {
                let gameFlag:any=data.data.find((res:any)=>res.id==hotGameArray[i])
                if (gameFlag) {
                  this.gameList.push(gameFlag)
                }
              }
              this.gameList =  this.gameList.filter((item:any)=> item.isShow == 0 )
              if (!this.hotGamesArray || (this.hotGamesArray && this.hotGamesArray.length<this.gameList.length)) {
                this.SET_HOTGAMES_DATA(this.gameList)
              }
            }
            
          } else {
          
            this.gameList = data.data;
          }
        }
      }catch (e) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
        this.gameList = []
      }
    }
  }
