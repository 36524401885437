
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {getUserInfoHttp} from "~/api/user";
  import {distOrderCountHttp} from "~/api/order";
  import {getNewsListHttp} from "~/api/comm"
  @Component({
    name:"homeLogin4",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class homeLogin4 extends Vue {
    @Mutation private SET_TOKEN_DATA!: any;
    @State((state) => state.web.distributorsObject) distributorsObject!: any;
    @Prop(Number) divWidth_?: number
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    public getCookie:any
    public setCookie:any
    private number:any='0'
    private isLogined : boolean = false // 是否已登录
    private actor : string = '' // 是否已登录
    private coin : string = '0.00' // 是否已登录
    private nickName : any = ''
    private newsList : any = []
    private userNavsList : any = []
    mounted(){
      if (this.getCookie('token')) {
        this.isLogined=true
        this.actor=this.getCookie('actor')
        this.nickName=this.getCookie('nickName')
      }
      if(this.distributorsObject && this.distributorsObject.adverPosMap && this.distributorsObject.adverPosMap[1]) {
          this.userNavsList = this.distributorsObject.adverPosMap[1].filter((v:any) => v.device==0)
        console.log(this.userNavsList,'测试请');
      }
    //   this.getDistOrderCount()
      this.getNewsList()
    }
    @Emit()
    async getDistOrderCount(){
      try {
        let {data} = await distOrderCountHttp()
        if (this.errorToastFn(data)) {
          if (data.data) {
            let flag=0
            if (this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.distBaseConf.statisMap) {
              flag=Number(flag)+Number(this.$store.state.web.distributorsObject.distBaseConf.statisMap)
            }
            this.number=Number(data.data.count)+flag
          }
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
    @Emit()
    async getUserInfo(){
      try { //登录成功后拉取用户信息
        let {data}=await getUserInfoHttp()
        if (this.errorToastFn(data)) {
          this.actor=data.data.actor
          this.coin=Number(data.data.coin).toFixed(2)
          this.nickName=data.data.nickName
          console.log(this.nickName,data.data,'昵称信息')
          this.setCookie("nickName", data.data.nickName)
          this.setCookie("userId", data.data.userId)
          this.setCookie("actor", data.data.actor)
        }
      } catch (e) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
    @Emit()
    //处理总成交数
    dealCountNumber(number:any){

      if (Number(number)<10) {
        return `000000${number}`
      } else if (Number(number)<100) {
        return `00000${number}`
      } else if (Number(number)<1000) {
        return `0000${number}`
      } else if (Number(number)<10000) {
        return `000${number}`
      } else if (Number(number)<100000) {
        return `00${number}`
      } else if (Number(number)<1000000) {
        return `0${number}`
      } else {
        return number
      }
    }
    @Emit()
    async getNewsList() {
        try {
            const {data} = await getNewsListHttp({params:{pages:1,pageSize:3}})
            if(this.errorToastFn(data)) {
                if(data.data) {
                    this.newsList = data.data
                }
            }
        } catch (error) {
            this.eventNotifyTost('提示', '网络超时', 'warning')
        }
        console.log(this.newsList,'新闻列表')
    }
  }
