
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { distOrderCountHttp } from "~/api/order";
import { latestDealRecordHttp } from "~/api/distributor";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { dailyOrderHttp } from "~/api/order";
import { queryVerifyCustomerHttp } from "~/api/cusSer";
import { addBlackHttp } from "~/api/black";
import { gameListHttp, getNewsListHttp } from "~/api/comm";
import { goodsListHttp, dealGoodsData } from "~/api/goods";

@Component({
  name: "substationIndex12",
  components: {
    logoSearch4: require("~/components/comm/logoSearch/index4.vue").default,
    djhHeaderNav8: require("~/components/comm/djhHeaderNav/index8.vue").default,
    djhSwiper11: require("~/components/comm/djhSwiper/index11.vue").default,
    djhSwiper10: require("~/components/comm/djhSwiper/index10.vue").default,
    djhSwiper9: require("~/components/comm/djhSwiper/index9.vue").default,
    djhSwiper8: require("~/components/comm/djhSwiper/index8.vue").default,
    djhSwiper7: require("~/components/comm/djhSwiper/index7.vue").default,
    djhAd20: require("~/components/comm/djhAd/index20.vue").default,
    djhAd21: require("~/components/comm/djhAd/index21.vue").default,
    djhAd22: require("~/components/comm/djhAd/index22.vue").default,
    djhAd23: require("~/components/comm/djhAd/index23.vue").default,
    djhAd24: require("~/components/comm/djhAd/index24.vue").default,
    djhAd25: require("~/components/comm/djhAd/index25.vue").default,
    djhAd26: require("~/components/comm/djhAd/index26.vue").default,
    hotGameList5: require("~/components/comm/hotGame/hotGameList5.vue").default,
    goodsList8: require("~/components/comm/goodsList/goodsList8.vue").default,
    bestCoin: require("~/components/comm/coin/bestCoin.vue").default,
    newsList: require("~/components/comm/news/newsList.vue").default,
    safeCheck: require("~/components/comm/safeCheck/index.vue").default,
    floatMenu9: require("~/components/comm/floatMenu/index9.vue").default,
    newImg2: require("~/components/comm/newImg/index2.vue").default,
    goodsList10: require("~/components/comm/goodsList/goodsList10.vue").default,
    cartoonFigure: require("~/components/comm/cartoonFigure/index1.vue").default,
  },
})
export default class substationIndex12 extends Vue {
  @Mutation private SET_TOKEN_DATA!: any;
  @State((state) => state.web.distributorsObject) distributorsObject!: any;
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State((state) => state.web.hotGamesArray) hotGamesArray!: any;
  @Mutation private SET_GAMELIST_DATA!: any;
  @State((state) => state.web.gameListArray) gameListArray!: any;
  public qqRegular: any;
  public eventMsgTost: any;
  public getCookie: any;
  public setCookie: any;
  public openUrl: any;
  public errorToastFn: any;
  public eventNotifyTost: any;
  public timeFormatUser: any
  public openGoodsDetail:any;
  private isShow: boolean = true;
  private isLogined: Boolean = false; // 是否已登录
  private actor: string = ""; // 头像
  private nickName: string = ""; // 昵称
  private gameList: Array<any> = []; // 游戏列表
  private gameType: number = 1; // 游戏列表

  private recOrTime: any = "";
  private chooseGameIndex: any = 0;
  private queryParams: any = {
    gameId: -1,
    goodsTypeId: 1,
    pages: 1,
    pageSize: 10,
    orderTypeStr: "",
  };
  private nowGameTotal: number = 0;
  private listData: Array<any> = []; // 当前查询到的游戏列表数据size10
  private check: number = 1;
  private startVal : number = 0;
  private isFirstShowInClient : boolean = false
  private isShowDialog : boolean = false
  private noticeTitle : string = '需要注意的是内容是默认不居中的'
  private adSingleImg: any = [];
  private orderListData: any = [];
  private isRunCountFloat: boolean = false      //控制首屏轮播右侧的旋转动画是否启动暂停的标识
  private isHaveRunSecondFloat:boolean = false  //热门游戏的旋转是否已经触发过的表示
  private middleHotGameNowChooseItem:any = 0    // 控制热门游戏当前选择的是端游还是手游还是全部游戏
  private staticNumberForSplit:any = 26    //
  private isShowDownloadDialog = true

  // 天宝阁新加参数
  private isCheckCurstomer:any = true    // 控制当前打开验证的是客服 还是黑号
  private isShowMask:boolean = false  // 控制是否打开客服验证对话框
  private inputStr: string = ''
  private inputWxStr: string = ''   // 因为刚开始只考虑了qq和黑号 微信后来添加单独处理
  private newsList: Array<any> = [];
  private allDealCount:any = ''   // 存放总成交
  private yesterdayOrder:any = ''   // 存放昨日成交
  private yesterdayView:any = ''   // 存放昨日浏览量
  private latestRecordList:any = []   // 最新成交数据 20条
  private bottomLuxuryObj:any ={}   // 拿到第六广告位配置 奢侈品区块
  private luxuryGoodsList:any = []   // 底部奢侈品 商品数据
  private treeBtnIndex:any = 0
  private isShowHotGameBox:boolean = false
  private anchorList1:any = []     // 主播轮播1
  private anchorList2:any = []     // 主播轮播2


  async mounted() {
    console.log('jklnklmq',this.hotGamesArray,this.distributorsObject);
    if (this.getCookie("token")) {
      this.isLogined = true;
      this.actor = this.getCookie("actor");
      this.nickName = this.getCookie("nickName");
    }
    console.log(this.distributorsObject, this.gameList, "sdgsagg");
    if (this.hotGamesArray.length == 0) {  // vuex中热门游戏为空
      if(this.gameListArray.length > 0) {  // 从vuex已存在游戏列表中拿hotgame
        let hotGameIdArray:any = []
        let hotGameArray:any = []
        if(this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
          console.log('qdqdqwdwqdqwd',this.gameListArray,this.$store.state.web.distributorsObject.distBaseConf.recGameStr);
          hotGameIdArray = this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(",");
          for (let i: number = 0; i < hotGameIdArray.length; i++) {
            let gameFlag: any = this.gameListArray.find(
              (res: any) => res.id == hotGameIdArray[i]
            );
            if (gameFlag) {
              this.gameList.push(gameFlag);
              hotGameArray.push(gameFlag)
            }
          }
          this.SET_HOTGAMES_DATA(hotGameIdArray)
        }
      } else {   // vuex中游戏列表不存在
      console.log('qdqdqwdwqdqwd123123123');

        await this.getGameList(true);
      }
    }
    console.log("sdgsagg", this.gameList);
    let scrolltop=document.documentElement.scrollTop||document.body.  scrollTop;
    if(scrolltop > 880) {
      if(!this.isFirstShowInClient) {
        this.isFirstShowInClient = true
      }
      if(!this.isHaveRunSecondFloat) {
        setTimeout(() => {
          this.isHaveRunSecondFloat = true
        },6000)
      }
    } else {
        window.addEventListener('scroll',()=>{
        let scrolltop1=document.documentElement.scrollTop||document.body.scrollTop;
          if(scrolltop1>880){
          if(!this.isFirstShowInClient) {
            this.isFirstShowInClient = true
          }
          if(!this.isHaveRunSecondFloat) {
            setTimeout(() => {
              this.isHaveRunSecondFloat = true
            },6000)
          }
        }
      })
    }
    await this.getNewsList();    // 获取main区右侧的四个公告
    await this.getShowCount();   // 该方法集中处理 总成交 昨日成交 昨日浏览浏览
    await this.getLatestRecord();   // 获取
    console.log(this.newsList, this.listData, "滚动新闻");
    if(this.distributorsObject.adverPosMap && this.distributorsObject.adverPosMap['6']) {
      let flag = this.distributorsObject.adverPosMap['6'].filter((v:any) => v.device == 0)
      if(flag && flag.length > 0) {
        this.bottomLuxuryObj = flag[0]
      }
      console.log('qwkelkmkmkgd',this.bottomLuxuryObj);
      if(Object.keys(this.bottomLuxuryObj).length>0) {
        try {
          const {data} = await goodsListHttp({params:{gameId:Number(this.bottomLuxuryObj.smalltitle),pages:1,pageSize:10,goodsTypeId:1}})
          if(this.errorToastFn(data)) {
            this.luxuryGoodsList = data.data.goodsList
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    if(this.distributorsObject.coAnchorBlocks && this.distributorsObject.coAnchorBlocks.length>0) {
      this.distributorsObject.coAnchorBlocks.map((v:any,i:any) => {
        if(i%2==0) {
          this.anchorList1.push(this.distributorsObject.coAnchorBlocks[i])
        } else {
          this.anchorList2.push(this.distributorsObject.coAnchorBlocks[i])
        }
      })
      console.log('qwkelkmkmkgd',this.anchorList1,this.anchorList2);
    }
  }
  // 天宝阁计算公告展示时间
  get newsShowTime() {
    return (item:any) => {
      if(item.showTime) {   // 存在设置的展示时间
        let showTime = item.showTime.length>5?String(item.showTime).substring(5):String(item.showTime)
        if(showTime.includes('-')) {
          return showTime.replace('-','/')
        } else {
          return showTime
        }
      } else {    // 不存在设置的展示时间 显示creatTime
        return this.timeFormatUser(item.createTime, 'MM/DD')
      }
    }
  }
  get getTreeButtons() {
    if(this.distributorsObject.treeMenuButMap && this.distributorsObject.treeMenuButMap['pc1']) {
      return this.distributorsObject.treeMenuButMap['pc1']
    } else {
      return []
    }
  }
  get priceInteger() {
    return (item:any) => {
      if(String(item.price).includes('.')) {
        return String(item.price).split('.')[0] + '.'
      } else {
        return String(item.price)
      }
    }
  }
  get priceMinority() {
    return (item:any) => {
      if(String(item.price).includes('-')) {
        return ''
      }
      if(String(item.price).includes('.')) {
        return String(item.price).split('.')[1]
      } else {
        return '.00'
      }
    }
  }
  get adArray() {
    if(this.distributorsObject.adverPosMap && this.distributorsObject.adverPosMap['1']) {
      return this.distributorsObject.adverPosMap['1'].filter((v:any) => v.device == 0)
    } else {
      return []
    }
  }
  get filterHotGameList() {
    if(this.hotGamesArray.length>0) {
      if(this.middleHotGameNowChooseItem == 1) {
        return this.hotGamesArray.filter((v:any,i:any) => i>0 && v.gameType == 1)
      } else if(this.middleHotGameNowChooseItem == 2) {
        return this.hotGamesArray.filter((v:any,i:any) => i>0 && v.gameType == 2)
      } else {
        return this.hotGamesArray.filter((v:any,i:any) => i>0)
      }
    } else {
      return this.hotGamesArray
    }
  }
  get getHotGameArray(){
      if (this.hotGamesArray && this.hotGamesArray.length>0) {
        let tempAdArray = [...this.hotGamesArray]
        tempAdArray.forEach(v => {
          if(v.goodsTypeAndNameMap && String(v.goodsTypeAndNameMap).length > 0 && String(v.goodsTypeAndNameMap).includes('{')) {
            v['goodsTypeNameArr'] = Object.values(JSON.parse(v.goodsTypeAndNameMap))
          }
        });
        return tempAdArray
      } else {
        return []
      }
    }
  @Emit()
  // 获取筛选游戏列表
  async getGameList(flag: boolean = false) {
    console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr,"=======recGameStr123=========");
    try {
      let { data } = await gameListHttp();
      if (this.errorToastFn(data)) {
        console.log(data, "poqlwkjui");
        if (flag) {
          this.gameList = [];
          let hotGameIdArray: any = [];
          if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
            let realHotGameArray:any = []
            console.log('qdqdqwdwqdqwd8899',this.$store.state.web.distributorsObject.distBaseConf.recGameStr);
            hotGameIdArray = this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(",");
            for (let i: number = 0; i < hotGameIdArray.length; i++) {
              let gameFlag: any = data.data.find(
                (res: any) => res.id == hotGameIdArray[i]
              );
              if (gameFlag) {
                this.gameList.push(gameFlag);
                realHotGameArray.push(gameFlag)
              }
            }
            // if (!this.hotGamesArray || (this.hotGamesArray &&this.hotGamesArray.length < this.gameList.length)) {
              console.log('qdqdqwdwqdqwd5566998877',this.gameList);
              this.SET_HOTGAMES_DATA(realHotGameArray);
            // }
          }
          this.SET_GAMELIST_DATA(data.data);
        } else {
          this.gameList = data.data;
          this.SET_GAMELIST_DATA(this.gameList);
        }
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
      this.gameList = [];
    }
  }
  @Emit() // 获取区服信息
  getareaData(data: any) {
    let res = "";
    let dats = JSON.parse(data);
    if (!!dats.gameName) {
      res = dats.gameName;
    } else {
      res = "暂无";
    }
    if (!!dats.areaName) {
      res = res + "-" + dats.areaName;
    } else {
      res = res + "-" + "暂无";
    }
    if (!!dats.serverName) {
      res = res + "-" + dats.serverName;
    } else {
      res = res + "-" + "暂无";
    }
    return res;
  }
  @Emit()
  chooseGame(item: any, index: number) {
    this.chooseGameIndex = index;
    this.queryParams.gameId = item.id;
    this.queryParams.pages = 1;
    this.getGoodsList();
  }
  // 根据queryParams查询参数获取游戏商品信息
  @Emit()
  async getGoodsList() {
    try {
      const { data } = await goodsListHttp({ params: this.queryParams });
      if (this.errorToastFn(data)) {
        console.log(data.data, "当前获取的商品数据");
        this.nowGameTotal = data.data.total;
        // let data2:any=dealGoodsData(data.data.goodsList)
        // console.log(data2,'ppiopqowe')
        this.listData = data.data.goodsList;
      } else {
        this.eventNotifyTost("提示", "获取商品数据失败", "warning");
      }
    } catch (error) {
      console.log(error);
    }
  }
  // **************************************** 天宝阁 开始
  // 获取main区块右侧的四个公告
  async getNewsList() {
    try {
      const {data} = await getNewsListHttp({params:{pageSize:4,pages:1}})
      if(this.errorToastFn(data)) {
        this.newsList = data.data.list
      }
    } catch (error) {
      console.log(error);
    }
  }
  // 控制验证客服弹出框
  showDialog(num:any) {
    if(num == 0) {
      this.isCheckCurstomer = false
    } else if(num == 1) {
      this.isCheckCurstomer = true
    }
    this.isShowMask = true
  }
  // 切换客服验证tab
  clickTabHandle(status:any) {
    this.inputStr = ''
    this.isCheckCurstomer = status
  }
  // 请求验证
  async goToCheck() {
    let noticeMsg = ''
    if(this.isCheckCurstomer) {
      if(!this.qqRegular.test(this.inputStr)) {
        this.$message({
          // message: '经验证：12313131为假客服！',
          message: '请输入正确的客服QQ号！',
          offset: 530,
          duration: 5000,
          customClass: 'myMessageCss'
        })
        return
      } else {
        try {
          const {data} = await queryVerifyCustomerHttp({params:{queryType:1,queryValue:this.inputStr}})
          if(data && data.code == 0 && Object.keys(data.data).length>0) {
            noticeMsg = `经验证：${this.inputStr}为真客服！`
          } else {
            noticeMsg = `经验证：${this.inputStr}为假客服！`
          }
          this.$message({
            message: noticeMsg,
            offset: 530,
            duration: 5000,
            customClass: 'myMessageCss'
          })
          this.inputStr = ''
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      if(!this.inputStr) {
        this.$message({
          message: '请输入要查询的黑名单！',
          offset: 530,
          duration: 5000,
          customClass: 'myMessageCss'
        })
      } else {
        try {
          let distId = 110
          if(this.$store.state.web.distributorsObject.distId) {
            distId = this.$store.state.web.distributorsObject.distId
          }
          const {data} = await addBlackHttp({params:{pages:1,pageSize:10,distId:distId,keyWord:this.inputStr}})
          if(data && data.code == 0 && data.data.list.length==0) {
            noticeMsg = '该用户未被拉入黑名单，如有后续违规请联系客服，避免线下交易！'
          } else {
            noticeMsg = '该用户已被拉入黑名单，请终止交易！'
          }
          this.$message({
            message: noticeMsg,
            offset: 530,
            duration: 5000,
            customClass: 'myMessageCss'
          })
          this.inputStr = ''
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
  // 微信验证另外处理
  async goToCheckWx() {
    let noticeMsg = ''
    if(this.inputWxStr.length < 6) {
      this.$message({
        message: '请输入正确的客服微信号！',
        offset: 530,
        duration: 5000,
        customClass: 'myMessageCss'
      })
    } else {
      try {
        const {data} = await queryVerifyCustomerHttp({params:{queryType:2,queryValue:this.inputWxStr}})
        if(data && data.code == 0 && Object.keys(data.data).length>0) {
          noticeMsg = `经验证：${this.inputWxStr}为真客服！`
        } else {
          noticeMsg = `经验证：${this.inputWxStr}为假客服！`
        }
        this.$message({
          message: noticeMsg,
          offset: 530,
          duration: 5000,
          customClass: 'myMessageCss'
        })
        this.inputWxStr = ''
      } catch (error) {
        console.log(error);
      }
    }
  }
  // 关闭验证弹框
  closeDialog() {
    this.isShowMask = false
  }
  // 集中处理 总成交 昨日成交 昨日浏览量
  async getShowCount() {
    try {
      let allDealCount = 0
      let yesterdayOrder = 0
      let yesterdayView = 0
      if (this.$store.state.web.distributorsObject &&this.$store.state.web.distributorsObject.distBaseConf.statisMap) {
        let statisMap = this.$store.state.web.distributorsObject.distBaseConf.statisMap;
        if(!!statisMap && statisMap.includes("{")) {
          let flagMap = JSON.parse(statisMap);
          if(!!flagMap["dealAllCount"]) {
            allDealCount = flagMap["dealAllCount"]
          }
          if(!!flagMap["yesterdayOrder"]) {
            yesterdayOrder = flagMap["yesterdayOrder"]
          }
          if(!!flagMap["yesterdayView"]) {
            yesterdayView = flagMap["yesterdayView"]
          }
        }
      }
      // 请求分站总成交
      const {data} = await distOrderCountHttp()
      console.log('sdjfklsdjkfls',data);
      if(this.errorToastFn(data)) {
        allDealCount = allDealCount + data.data.count
      }
      // 请求分站总成交
      const {data:data1} = await dailyOrderHttp()
      console.log('sdjfklsdjkfls1',data1);
      if(this.errorToastFn(data1) && data1.data.goodsList.length>1) {
        yesterdayOrder = yesterdayOrder + data1.data.goodsList[1].dealNum
      }
      this.allDealCount = String(allDealCount)
      this.yesterdayOrder = yesterdayOrder
      this.yesterdayView = yesterdayView
    } catch (error) {
      console.log(error);
    }
  }
  // 每日成交记录
  async getLatestRecord() {
    try {
      const {data} = await latestDealRecordHttp()
      console.log(data);
      if(this.errorToastFn(data)) {
        this.latestRecordList = data.data
      }
    } catch (error) {
     console.log(error);
    }
  }
  // 鼠标经过树形按钮
  overTreeBtn(index:any) {
    if(index>-1) {
      this.treeBtnIndex = index
    }
  }
  leaveTreeBtn() {
    this.treeBtnIndex = 0
  }
  // 热门游戏 是否显示
  showHotGameHandle() {
    this.isShowHotGameBox = true
  }
  hiddenHotGameHandle() {
    this.isShowHotGameBox = false
  }
  // 打开商品详情
  goToGoodsDetail(item:any) {
    if(item.goodsSn) {
      this.openGoodsDetail({goodsSn:item.goodsSn,goodsTypeId:item.goodsTypeId},'_blank')
    } else {
      this.$message.warning('请看看其他商品吧！')
    }
  }
}
