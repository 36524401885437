
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"djhHeaderNavIndex2",
  })
  export default class djhHeaderNavIndex2 extends Vue {
    @Prop(Number) topMenuWidth_?: any
    @Prop(Array) headerArray_?: any
    private index_ : any = 1 // 选中第几个
    private isShowTopMenu : Boolean = true // 是否显示菜单栏
    private topMenuFn : Array<any> = [{title:'网站首页',htUrl:'/'}, {title:'帐号交易',htUrl:'/'}, {title:'金币交易',htUrl:'/'}, {title:'游戏代练',htUrl:'/'},{title:'游戏陪玩',htUrl:'/'},{title:'资源群',htUrl:'/'}] // 菜单列表
    mounted(){
    }
    @Emit()
    leftmove(num:number) {//鼠标移入menu option 改变下划线位置
      this.index_=num
    }
    @Emit()
    leftreset(num:number) {//鼠标移出menu option 下划线复位
      this.index_=num
    }
  }
