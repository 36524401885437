
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  @Component({
    name:"menuIndex1",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default
    }
  })
  export default class djhHeaderNavIndex2 extends Vue {
    @Prop({default: 20}) comLeftRightPadding?: any
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    private menuList : any = [] // 菜单列表（）

    mounted() {
      // 从vuex中的分站基本信息里获取到自定义菜单
      this.menuList = this.distributorsObject.treeMenuButMap?this.distributorsObject.treeMenuButMap['pc3']:[]
    }
  }
