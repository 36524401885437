
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { gameListHttp } from "~/api/comm";
import { distOrderCountHttp } from "~/api/order";
@Component({
  name: "gameCheckNum",
  components: {
    newImg2: require("~/components/comm/newImg/index2.vue").default,
  },
})
export default class gameCheckNum extends Vue {
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State((state) => state.web.hotGamesArray) hotGamesArray!: any;
  @State((state) => state.web.distributorsObject) distributorsObject!: any;

  @Prop(Number) divWidth_?: number; //宽度
  @Prop(Number) divHeight_?: number; //高度
  public errorToastFn: any;
  public eventMsgTost: any;
  public eventNotifyTost: any;
  public getCookie: any;
  private gameList: any = [];
  private check: number = 1;
  private orderNumber: any = "0";
  private orderCount: any = "20";
  private yesterdayOrderCount: number = 0;
  //  @Watch("orderCount")
  //   getOrderCount(newVal: any) {
  //     if (newVal == '') {
  //       this.msgTxt['phoneNum'] = '用户名/手机号为空'
  //     } else {
  //       this.msgTxt['phoneNum'] = ''
  //     }
  //     this.phoneNum=newVal
  //   }
  mounted() {
    setTimeout(async () => {
      if (this.hotGamesArray && this.hotGamesArray.length > 0) {
        this.gameList = this.hotGamesArray;
      } else {
        await this.getGameList();
      }
    }, 50);
    this.getDistOrderCount();
    this.$bus.$on("yesterdayOrderCountEvent", (e: number) => {
      this.yesterdayOrderCount = e;
    });
  }
  @Emit() // 处理浏览量计算
  dealCount(count: any, index: any) {
    // const relacount = count.split(',')
    // const data:any = new Date()
    // console.log(data, 'data4data4data4data4data4', Math.ceil(((data.getDay()%10)/100)))
    // if(index === 2 && relacount.length>2) {
    //   let comp:any = Math.ceil(((data.getDay()%10)/100) * parseInt(relacount[2]))
    //   if(data.getDay()%2===0){
    //     comp = -1*comp
    //   }
    //   return parseInt(relacount[2]) + comp
    // } else if(index === 1 && relacount.length>1) {
    //   let comp:any = Math.ceil(((data.getDay()%10)/100) * parseInt(relacount[1]))
    //   if(data.getDay()%2===0){
    //     comp = -1*comp
    //   }
    //   return parseInt(relacount[1]) + comp
    // } else {
    //   return parseInt(relacount[0])
    // }
    let statisMap = this.distributorsObject.distBaseConf.statisMap;
    let flag = "20";
    if (index == 1) {
      if (!!statisMap && statisMap.includes("{")) {
        console.log(statisMap, "----------------statisMap------");
        let flagMap = JSON.parse(statisMap);
        if (!!flagMap["showCount"]) {
          flag = (flagMap["showCount"] * 1).toString();
        }
      }
    } else {
      if (!!statisMap && statisMap.includes("{")) {
        console.log(statisMap, "----------------statisMap------");
        let flagMap = JSON.parse(statisMap);
        if (!!flagMap["dealCount"]) {
          flag = (
            flagMap["dealCount"]
          ).toString();
        }
      }
    }
    return flag;
  }
  @Emit()
  // 获取游戏列表
  async getGameList() {
    console.log(
      this.$store.state.web.distributorsObject.distBaseConf.recGameStr,
      "============recGameStr==========="
    );
    try {
      let { data } = await gameListHttp();
      if (this.errorToastFn(data)) {
        this.gameList = [];
        let hotGameArray: any = [];
        if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
          hotGameArray =
            this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(
              ","
            );
          for (let i: number = 0; i < hotGameArray.length; i++) {
            let gameFlag: any = data.data.find(
              (res: any) => res.id == hotGameArray[i]
            );
            if (gameFlag) {
              this.gameList.push(gameFlag);
            }
          }
          if (
            !this.hotGamesArray ||
            (this.hotGamesArray &&
              this.hotGamesArray.length < this.gameList.length)
          ) {
            this.SET_HOTGAMES_DATA(this.gameList);
          }
        }
      }
    } catch (e) {
      this.eventNotifyTost("提示", "网络超时", "warning");
      this.gameList = [];
    }
  }
  @Emit()
  async getDistOrderCount() {
    try {
      let { data } = await distOrderCountHttp();
      if (this.errorToastFn(data)) {
        if (data.data) {
          let flag = 20;
          if (
            this.$store.state.web.distributorsObject &&
            this.distributorsObject.distBaseConf.statisMap
          ) {
            this.orderCount = data.data.count;
            let statisMap = this.distributorsObject.distBaseConf.statisMap;
            if (!!statisMap && statisMap.includes("{")) {
              let flagMap = JSON.parse(statisMap);
              if (!!flagMap["dealAllCount"] && !!this.orderCount) {
                flag = flagMap["dealAllCount"] * 1 + Number(this.orderCount);
              }
            }
            // this.$nextTick(() => {
            //   let orderCount: string = this.getCookie("orderCount");
            //   let statisMap = this.distributorsObject.distBaseConf.statisMap;
            //   if (!!statisMap && statisMap.includes("{")) {
            //     let flagMap = JSON.parse(statisMap);
            //     if (!!flagMap["dealCount"] && !!orderCount) {
            //       flag = flagMap["dealCount"] * 1 + Number(orderCount);
            //     }
            //   }
            // });
          }
          this.orderNumber = flag;
        }
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
    }
  }
  @Emit()
  //处理总成交数
  dealCountNumber(number: any) {
    if (Number(number) < 10) {
      return `00000${number}`.split("");
    } else if (Number(number) < 100) {
      return `0000${number}`.split("");
    } else if (Number(number) < 1000) {
      return `000${number}`.split("");
    } else if (Number(number) < 10000) {
      return `00${number}`.split("");
    } else if (Number(number) < 100000) {
      return `0${number}`.split("");
    } else {
      return number.toString().split("");
    }
  }
}
