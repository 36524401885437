
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { getNewsListHttp } from "~/api/comm";
@Component({
  name: "djhAd25",
  components: {
    newImg2: require("~/components/comm/newImg/index2.vue").default,
  },
})
export default class djhAd25 extends Vue {
  @Prop(Number) divWidth_?: any;
  @Prop(Number) divHeight_?: any;
  @Prop(Array) adArray_?: any;
  public openUrl:any;
  public errorToastFn: any;
  private swiperOption: any = {
    loop: true,
    notNextTick: true,
    slidesPerView: 1,
    mousewheel: false,
    preloadImages: false,
    lazy: true,
    speed: 36000,
    noSwiping: true,
    autoplay: {
      delay: 0, //平滑滑动切换
      disableOnInteraction: true,
    },
    on: {
        click: (e:any) => {   //设置loop之后，通过组件复制出来的slider没有点击事件,需要特殊处理
              console.log('sdfhjk',e,this,'');
              try {
                console.log('sdfhjk1112222',e.target.offsetParent.dataset,e.target.offsetParent.dataset.hturl);
                if(e.target.dataset && e.target.dataset.hturl) {
                  this.openUrl(e.target.dataset.hturl)
                } else if(e.target.offsetParent.dataset && e.target.offsetParent.dataset.hturl) {
                  this.openUrl(e.target.offsetParent.dataset.hturl)
                }
                console.log('sdfhjk11122224444444',e,this,'');
              } catch (error) {
                  console.log(error);
              }
          }
      }
  };
  private newsList: Array<any> = []; // 公告列表
  mounted() {
    // this.getNewsList();
  }
  @Emit()
  getArray() {
    if (this.adArray_) {
      // console.log(this.adArray_.filter((v:any)=>v.device==0),'=======ad==ad=======')
      let flag: any = this.adArray_.filter((v: any) => v.device == 0);
      if (!!flag) {
        return flag.filter((vv: any, index: number) => index < 1);
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  @Emit()
  async getNewsList() {
    try {
      let { data } = await getNewsListHttp({ params: { newsType: 11 } });
      if (this.errorToastFn(data)) {
        if (data.data.list.length > 0) {
          this.newsList = data.data.list;
          console.log(this.newsList, "===d=d=d=s==s===");
        }
      }
    } catch (error) {
      this.newsList = [];
    }
  }
}
