
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { distOrderCountHttp } from "~/api/order";
import { latestDealRecordHttp } from "~/api/distributor";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { dailyOrderHttp } from "~/api/order";
import {queryVerifyCustomerHttp} from "~/api/cusSer";
import {addBlackHttp} from "~/api/black";
import {gameListHttp} from "~/api/comm";
import {goodsListHttp} from "~/api/goods";

@Component({
  name: "substationIndex9",
  components: {
    logoSearch2: require("~/components/comm/logoSearch/index2.vue").default,
    djhHeaderNav7: require("~/components/comm/djhHeaderNav/index7.vue").default,
    djhSwiper6: require("~/components/comm/djhSwiper/index6.vue").default,
    djhSwiper7: require("~/components/comm/djhSwiper/index7.vue").default,
    gameCheckNum: require("~/components/template/gameCheckNum.vue").default,
    newsList7: require("~/components/comm/news/newsList7.vue").default,
    homeLogin3: require("~/components/comm/homeLogin/index3.vue").default,
    djhAd14: require("~/components/comm/djhAd/index14.vue").default,
    djhAd13: require("~/components/comm/djhAd/index13.vue").default,
    newsList6: require("~/components/comm/news/newsList6.vue").default,
    djhAd16: require("~/components/comm/djhAd/index16.vue").default,
    hotGameList4: require("~/components/comm/hotGame/hotGameList4.vue").default,
    goodsList7: require("~/components/comm/goodsList/goodsList7.vue").default,
    footerService2: require("~/components/comm/djhFooter/footerService2.vue")
      .default,
    bestCoin: require("~/components/comm/coin/bestCoin.vue").default,
    newsList: require("~/components/comm/news/newsList.vue").default,
    safeCheck: require("~/components/comm/safeCheck/index.vue").default,
    floatMenu4: require("~/components/comm/floatMenu/index4.vue").default,
    newImg2: require("~/components/comm/newImg/index2.vue").default,
  },
})
export default class substationIndex9 extends Vue {
  @Mutation private SET_TOKEN_DATA!: any;
  @State((state) => state.web.distributorsObject) distributorsObject!: any;
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State(state => state.web.hotGamesArray) hotGamesArray!: any;
  @Mutation private SET_GAMELIST_DATA!: any;
  @State(state => state.web.gameListArray) gameListArray!: any;
  public qqRegular: any
  public eventMsgTost: any
  public getCookie: any
  public setCookie: any;
  public openUrl: any;
  public errorToastFn: any;
  public eventNotifyTost: any;
  private orderCount: any = ""; // 每日成交订单总数
  private allDealCount: any = ""; // 成交订单总数
  private goodsCount: any = ""; // 在售商品总数
  private yesterdayOrderCount: number = 0; // 昨日成交订单总数
  private isShow:boolean=true
  private checkNumber:any= null // 验证的客服QQ
  private checkBlack:any= null // 验证的黑名单
  private isLogined : Boolean = false // 是否已登录
  private actor : string = '' // 头像
  private nickName : string = '' // 昵称
  private gameList : Array<any> = [] // 游戏列表
  private gameType :number = 1 // 游戏列表
  private showLeftFloat: boolean = true;

  private colorConfig: Object = {
    //菜单颜色
    bgColor: "#190F0D",
    hoverBgColor: "#BC0000",
    color: "#EAB700",
    hoverColor: "#EAB700",
  };
  private adSingleImg: any = [];
  private orderListData: any = [];

  async mounted() {
    if (this.getCookie('token')) {
      this.isLogined=true
      this.actor=this.getCookie('actor')
      this.nickName=this.getCookie('nickName')
    }
    setTimeout(async () => {
      console.log(this.distributorsObject, "========aaaaaa啊啊啊啊啊啊啊");
      let body: any = document.querySelectorAll(
        "body"
      ) as NodeListOf<HTMLElement>;
      console.log(body, "===body===");
      body[0].setAttribute("style", "background-color:#f2f3f5");
      if (this.hotGamesArray && this.hotGamesArray.length>0) {
        this.gameList=this.hotGamesArray
      } else {
        await this.getGameList(true)
      }
      console.log(this.gameList, 'gameListgameListgameListgameList')
    }, 50);

    this.getArray();
    this.getSellGoodsNum();
    await this.getStaticOrderCount();
    await this.getDistOrderCount();
    await this.getDealGoods();
    await this.dailyOrderHttpFn();
  }
  @Emit()
  changeGameType(type: number) {
    if (this.gameType !== type) {
      this.gameType = type;
      (this.$refs.goodsList7 as any).getGoodsHttpWithId(3, this.gameType === 1 ? 83 : 82);
    }
  }
  @Emit()
  getNewGoods(type: number) {
    console.log(121221);
    (this.$refs.goodsList7 as any).getGoodsHttpWithId(type);
  }
  @Emit()
  getArray() {
    if (this.distributorsObject && this.distributorsObject.adverPosMap["4"]) {
      let flag: any = this.distributorsObject.adverPosMap["4"].filter(
        (v: any) => v.device == 0
      );
      if (flag) {
        this.adSingleImg = flag;
      }
    }
  }
  @Emit() //获取当日成交笔数
  async dailyOrderHttpFn() {
    try {
      let { data } = await dailyOrderHttp();
      if (this.errorToastFn(data)) {
        if (data.data) {
          this.yesterdayOrderCount = data.data.goodsList[0].dealNum;
          this.orderCount = (this.orderCount * 1 + this.yesterdayOrderCount)
            .toString()
            .padStart(5, "0");
          console.log(this.orderCount, 'orderCountorderCountorderCountorderCountorderCountorderCount');
          this.$bus.$emit("yesterdayOrderCountEvent", this.yesterdayOrderCount);
        }
      }
    } catch (error) {
      this.errorToastFn("提示", "网络超时", "warning");
    }
  }
  @Emit() // 获取分站静态订单笔数
  async getStaticOrderCount() {
    if (
      this.$store.state.web.distributorsObject &&
      this.$store.state.web.distributorsObject.distBaseConf.statisMap
    ) {
      let statisMap =
        this.$store.state.web.distributorsObject.distBaseConf.statisMap;
      if (!!statisMap && statisMap.includes("{")) {
        let flagMap = JSON.parse(statisMap);
        if (!!flagMap["dealCount"]) {
          this.orderCount = flagMap["dealCount"];
        }
      }
    }
  }
  @Emit() // 获取分站成交笔数
  async getDistOrderCount() {
    try {
      let { data } = await distOrderCountHttp();
      if (this.errorToastFn(data)) {
        if (data.data) {
          let flag = "20";
          console.log(this.$store.state.web.distributorsObject.distBaseConf.statisMap,'1111111111111111111111111111111111111111111111111')
          try {
            if (
              this.$store.state.web.distributorsObject &&
              this.$store.state.web.distributorsObject.distBaseConf.statisMap
            ) {
              //  flag=this.$store.state.web.distributorsObject.distBaseConf.statisMap.split(',')[2]
              let statisMap =
                this.$store.state.web.distributorsObject.distBaseConf.statisMap;
              if (!!statisMap && statisMap.includes("{")) {
                let flagMap = JSON.parse(statisMap);
                if (!!flagMap["dealAllCount"]) {
                  flag = (
                    flagMap["dealAllCount"] * 1 +
                    data.data.count
                  ).toString();
                }
              }
            }
          } catch (e) {}
          this.allDealCount = flag;
        }
      }
    } catch (error) {
      this.errorToastFn("提示", "网络超时", "warning");
    }
  }

  @Emit()//客服验证账号
  async checkSafe(){
    if(!this.qqRegular.test(this.checkNumber)){
      this.checkNumber=null
      this.eventMsgTost(`请输入检测QQ`)
      return false
    }
    try {
      const param = {queryValue:this.checkNumber,queryType:1}
      let {data}:any=await queryVerifyCustomerHttp({params:param})
      if (this.errorToastFn(data)) {
        console.log('经验证：${this.checkNumber}为真客服。',1111111111111111111111111111111111111111)
        if (data.data && !!data.data.distId) {
          this.eventMsgTost(`经验证：${this.checkNumber}为真客服。`, 'success')
        } else {
          this.eventMsgTost(`经验证：${this.checkNumber}为假客服。`)
        }
      }
    } catch (error) {
      this.errorToastFn(error,'系统繁忙中，请稍后再试！');
    }
  }
  @Emit()//客服黑名单
  async checkSafe2(){
    if (!this.getCookie('token')) {
      this.eventMsgTost(`请登录`)
      this.openUrl('/login')
      return false
    }
    if(!this.checkBlack){
      this.eventMsgTost(`请输入黑号关键字`)
      return false
    }
    try {
      let {data}:any=await addBlackHttp({params:{distId:95, keyWord:this.checkBlack}})
      if (this.errorToastFn(data)) {
        if (data.data && data.data.list && data.data.list.length>0) {
          this.$alert('该用户已被拉入黑名单，请终止交易。', '存在违规', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
        } else {
          this.$alert('该用户未被拉入黑名单，如后续有违规请联系客服，避免线下交易。', '未违规', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
        }
      }
    } catch (error) {
      this.errorToastFn(error,'系统繁忙中，请稍后再试！');
    }
  }

  @Emit() // 获取每日成交记录
  async getDealGoods() {
    try {
      let { data } = await latestDealRecordHttp({ params: { pageSize: 13 } });
      if (this.errorToastFn(data)) {
        this.orderListData = data.data;
        console.log(this.orderListData, "=======orderListData======");
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
    }
  }

  @Emit()
  // 获取筛选游戏列表
  async getGameList(flag:boolean=false){
    // console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr,'=======recGameStr=========')
    try {
      let {data}=await gameListHttp()
      if (this.errorToastFn(data)) {
        if (flag) {
          this.gameList=[]
          let hotGameArray:any=[]
          if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
            hotGameArray=this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',')
            for (let i:number=0;i<hotGameArray.length;i++) {
              let gameFlag:any=data.data.find((res:any)=>res.id==hotGameArray[i])
              if (gameFlag) {
                this.gameList.push(gameFlag)
              }
            }
            if (!this.hotGamesArray || (this.hotGamesArray && this.hotGamesArray.length<this.gameList.length)) {
              this.SET_HOTGAMES_DATA(this.gameList)
            }
          }
        } else {
          this.gameList=data.data
          this.SET_GAMELIST_DATA(this.gameList)
        }
      }
    } catch (error) {
      this.eventNotifyTost('提示', '网络超时', 'warning')
      this.gameList=[]
    }
  }
  @Emit() // 获取在售商品数
  async getSellGoodsNum(){
    try{
      const param = {pageSize:12, gameId:69, goodsTypeId:1}
      let {data}=await goodsListHttp({ params: param })
      if (this.errorToastFn(data)) {
        this.goodsCount = 2413+data.data.total
      }else {
        this.goodsCount = 2413
      }
    }catch (error) {
      console.log(error,'获取在售商品数失败！')
      this.goodsCount = 2413
    }
  }
  @Emit() // 处理浏览量计算
  dealCount(index: any) {
    let statisMap = this.distributorsObject.distBaseConf.statisMap;
    let flag = "20";
    if (index == 1) {
      if (!!statisMap && statisMap.includes("{")) {
        console.log(statisMap, "----------------statisMap------");
        let flagMap = JSON.parse(statisMap);
        if (!!flagMap["showCount"]) {
          flag = (flagMap["showCount"] * 1).toString();
        }
      }
    } else {
      if (!!statisMap && statisMap.includes("{")) {
        console.log(statisMap, "----------------statisMap------");
        let flagMap = JSON.parse(statisMap);
        if (!!flagMap["dealCount"]) {
          flag = (
            flagMap["dealCount"]
          ).toString();
        }
      }
    }
    return flag;
  }
  @Emit()   // 获取区服信息
  getareaData(data:any){
    let res=''
    let dats = JSON.parse(data)
    if(!!dats.gameName){
      res = dats.gameName
    } else {
      res = '暂无'
    }
    if(!!dats.areaName){
      res = res + '-' + dats.areaName
    } else {
      res = res + '-' + '暂无'
    }
    if(!!dats.serverName){
      res = res + '-' + dats.serverName
    } else {
      res = res + '-' + '暂无'
    }
    return res
  }
  @Emit()
  //处理总成交数
  dealCountNumber(number: any) {
    if (Number(number) < 10) {
      return `00000${number}`.split("");
    } else if (Number(number) < 100) {
      return `0000${number}`.split("");
    } else if (Number(number) < 1000) {
      return `000${number}`.split("");
    } else if (Number(number) < 10000) {
      return `00${number}`.split("");
    } else if (Number(number) < 100000) {
      return `0${number}`.split("");
    } else {
      return number.toString().split("");
    }
  }
  @Emit()
  // 关闭左侧悬浮图片
  hideLeftFloat() {
    this.showLeftFloat = false;
  }
  beforeDestroy() {
    this.$bus.$off("yesterdayOrderCountEvent", 0);
  }
}
