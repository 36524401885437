import { render, staticRenderFns } from "./newsList7.vue?vue&type=template&id=724511de&scoped=true"
import script from "./newsList7.vue?vue&type=script&lang=ts"
export * from "./newsList7.vue?vue&type=script&lang=ts"
import style0 from "./newsList7.vue?vue&type=style&index=0&id=724511de&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724511de",
  null
  
)

export default component.exports