
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  @Component({
    name:"substationIndex4",
    components: {
      logoSearch: require('~/components/comm/logoSearch/index.vue').default,
      djhHeaderNav3: require('~/components/comm/djhHeaderNav/index3.vue').default,
      djhSwiper4: require('~/components/comm/djhSwiper/index4.vue').default,
      djhAd12: require('~/components/comm/djhAd/index12.vue').default,
      djhAd5: require('~/components/comm/djhAd/index5.vue').default,
      djhAd11: require('~/components/comm/djhAd/index11.vue').default,
      djhAd10: require('~/components/comm/djhAd/index10.vue').default,
      anchor: require('~/components/comm/anchor/index.vue').default,
      homeLogin2: require('~/components/comm/homeLogin/index2.vue').default,
      hotGameList4: require('~/components/comm/hotGame/hotGameList4.vue').default,
      goodsList6: require('~/components/comm/goodsList/goodsList6.vue').default,
      // dailyGoods: require('~/components/comm/dealGoods/dailyGoods.vue').default,
      footerService: require('~/components/comm/djhFooter/footerService.vue').default,
      bestCoin: require('~/components/comm/coin/bestCoin.vue').default,
      newsList: require('~/components/comm/news/newsList.vue').default,
      safeCheck: require('~/components/comm/safeCheck/index.vue').default,
      floatMenu: require('~/components/comm/floatMenu/index.vue').default,
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class substationIndex4 extends Vue {
    @Mutation private SET_TOKEN_DATA!: any;
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    public openUrl:any
    private colorConfig : Object = { //菜单颜色
      bgColor: "#190F0D",
      hoverBgColor: "#BC0000",
      color: "#EAB700",
      hoverColor: "#EAB700"
    }
    private floatMenuOther:any = {
      title:'客服验证',
      goScroll: 2400,
      img:require('static/image/floatMenu/fangdajing.png')
    }
    private adSingleImg:any={
      4:{
        adImg:'',
        adImgText:'',
        adHtUrl:'',
      },
      5:{
        adImg:'',
        adImgText:'',
        adHtUrl:'',
      }
    }
    mounted(){
      setTimeout(()=>{
        console.log(this.distributorsObject,'========aaaaaa啊啊啊啊啊啊啊')
      },100)
      let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
      console.log(body,'===body====')
      body[0].setAttribute('style', 'background-color:#f2f3f5')

      this.getArray()
    }
    @Emit()
    getArray(){
      if (this.distributorsObject && this.distributorsObject.adverPosMap['4']) {
        let flag:any=this.distributorsObject.adverPosMap['4'].filter((v:any)=>v.device==0)
        if (flag && flag[0]) {
          this.adSingleImg[4].adImg=flag[0].url
          this.adSingleImg[4].adImgText=flag[0].title
          this.adSingleImg[4].adHtUrl=flag[0].htUrl
        }
      }
      if (this.distributorsObject && this.distributorsObject.adverPosMap['5']) {
        let flag:any=this.distributorsObject.adverPosMap['5'].filter((v:any)=>v.device==0)
        if (flag && flag[0]) {
          this.adSingleImg[5].adImg=flag[0].url
          this.adSingleImg[5].adImgText=flag[0].title
          this.adSingleImg[5].adHtUrl=flag[0].htUrl
        }
      }
    }
  }
