
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"safeUnion",
  })
  export default class safeUnion extends Vue {
    @Prop(Number) divWidth_?: number //宽度
    @Prop(Number) divHeight_?: number //高度
    mounted() {

    }
  }
