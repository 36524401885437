
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {queryVerifyCustomerHttp} from "~/api/cusSer";
  @Component({
    name:"safeCheck",
  })
  export default class safeCheck extends Vue {
    @Prop(Number) divWidth_?: number //宽度
    @Prop({default:false}) boxShadow_?: boolean //是否显示box-shadow
    @Prop({default:true}) alignment_?: boolean //是否对齐
    public qqRegular : any // qq验证
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    private tab:number=1 //选择的标签，也用于判断，验证方式
    private checkResult:number=-1 //验证结果2:成功，1：错误，0：输入警告
    private checkMsg:string='' //验证结果2:成功，1：错误，0：输入警告
    private infoObject:any={queryValue:'',queryType:1} //要验证的数据
    mounted() {
    }
    @Emit()
    //验证上传信息
    checkInfo(){
      if(this.tab==1 && !this.qqRegular.test(this.infoObject.queryValue)){
        this.checkResult=0
        this.checkMsg='请输入正确的QQ号'
        return false
      }else if(this.tab==2 && !this.infoObject.queryValue){
        this.checkResult=0
        this.checkMsg='请输入正确的客服微信号'
        return false
      }else if(this.tab==3){
        this.checkResult=0
        this.checkMsg='黑名单查询功能待上架！'
        return false
      }else {
        return true
      }
    }
    @Emit()
    //上传验证账号
    async checkSafe(){
      if(this.checkInfo()){
        try {
          let {data}:any=await queryVerifyCustomerHttp({params:this.infoObject})
          if (this.errorToastFn(data)) {
            console.log(data.data,!!data.data)
            let flag:string=''
            if (this.tab==1) {
              flag='QQ客服'
            } else {
              flag='微信客服'
            }
            if (data.data && !!data.data.distId) {
              this.checkResult=2
              this.checkMsg=`经验证：${flag}${this.infoObject.queryValue} 为真客服。`
            } else {
              this.checkResult=1
              this.checkMsg=`经验证：${flag}${this.infoObject.queryValue} 为假客服。`
            }
          }
        } catch (error) {
          this.eventMsgTost('','系统繁忙中，请稍后再试！');
        }
        setTimeout(()=>{
          this.checkResult=-1
          this.checkMsg=''
        },20000)
      }else {
        setTimeout(()=>{
          this.checkResult=-1
          this.checkMsg=''
        },20000)
      }
    }
    @Emit()
    //选择验证方式
    selectCheck(v:number){
      this.tab=v
      this.infoObject.queryType=v
    }

  }
