
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  @Component({
    name:"substationIndex3",
    components: {
      headerLogoNav: require('~/components/comm/djhHeaderNav/headerLogoNav.vue').default,
      daiShouButton: require('~/components/template/daiShouButton.vue').default,
      djhSwiper: require('~/components/comm/djhSwiper/index.vue').default,
      djhAd6: require('~/components/comm/djhAd/index6.vue').default,
      helpButton: require('~/components/template/helpButton.vue').default,
      newsList: require('~/components/comm/news/newsList.vue').default,
      freeEvaluation: require('~/components/template/freeEvaluation.vue').default,
      safeCheck: require('~/components/comm/safeCheck/index.vue').default,
      fastGold: require('~/components/template/fastGold.vue').default,
      recImgText: require('~/components/template/recImgText.vue').default,
      goodsList4: require('~/components/comm/goodsList/goodsList4.vue').default,
      safeUnion: require('~/components/template/safeUnion.vue').default,
      hotGameList: require('~/components/comm/hotGame/hotGameList.vue').default,
      dealGoodsImgText: require('~/components/comm/dealGoods/dealGoodsImgText.vue').default,
      latestDealGoods: require('~/components/comm/dealGoods/latestDealGoods.vue').default,
      floatMenu: require('~/components/comm/floatMenu/index.vue').default,
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class substationIndex3 extends Vue {
    @Mutation private SET_TOKEN_DATA!: any;
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    @Prop(Object) goodsListData_?: any
    private changeFalge:boolean=true // 是否开启滚动监听
    private webHeight:any='' // 是否开启滚动监听
    private floatMenuOther:any = {
      title:'客服验证',
      goScroll: 400,
      img:require('static/image/floatMenu/fangdajing.png')
    }
    mounted(){
      window.scrollTo(0, 0);
      this.webHeight=document.body.scrollHeight
      window.addEventListener("scroll",this.scrollListener) //添加滚动事件
      // this.scrollListener()

      let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
      console.log(body,'===body====')
      body[0].setAttribute('style', 'background-color:#f2f3f5')

    }
    destroyed() {
      window.removeEventListener('scroll', this.scrollListener)
    }
    @Emit()
    scrollListener(){
      // window.onscroll= () =>{
        if (this.distributorsObject.pictArea.backImg && this.distributorsObject.pictArea.backTitleImg) {
          var a = document.documentElement.scrollTop // 滚动的距离
          var b = document.documentElement.scrollHeight // 屏幕高度
          // console.log(this.webHeight-b,'+++++++222+++++++++++++++++')
          if(this.changeFalge && (this.webHeight-b)<200)  {
            (document as any).getElementById('headernav2').style.marginBottom='200px'
            let box:any = document.querySelector("#aaaa")
            box.className = "substationIndex3_item_topact"
            this.changeFalge=false
            // console.log(this.changeFalge,'======changeFalge==========')
          }else {

          }
        }
      // }
    }
  }
