
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"djhAd7",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class djhAd7 extends Vue {
    @Prop(Number) divHeight_?: any
    @Prop(Array) adArray_?: any
    private adImg:string=''
    private adImgText:string=''
    private adHtUrl:string=''
    mounted(){
      this.getArray()
    }
    @Emit()
    getArray(){
      if (this.adArray_) {
        // console.log(this.adArray_.filter((v:any)=>v.device==0),'=======ad==ad=======')
        let flag:any=this.adArray_.filter((v:any)=>v.device==0)
        if (flag && flag[0]) {
          this.adImg=flag[0].url
          this.adImgText=flag[0].title
          this.adHtUrl=flag[0].htUrl
        }
      } else {
        this.adArray_=[]
      }
    }
  }
