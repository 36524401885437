
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  import  moment from 'moment';
  import {latestDealRecordHttp} from "~/api/distributor";
  moment.locale("zh-cn");
  @Component({
    name:"anchor",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class anchor extends Vue {
    @Prop(Number) divWidth_?: number
    @Prop(Number) divHeight_?: number
    @Prop(Array) anchorArray_?: any
    public errorToastFn:any
    public eventNotifyTost:any
    private swiperOption: any = {
      loop: true,
      autoplay: {
        delay: 0,//平滑滑动切换
        disableOnInteraction:true,
      },
      speed:14000,
      freeMode:true,
      noSwiping:false,
      hideOnClick:true,
      slidesPerGroup : 1,
      slidesPerView: 4,
      preloadImages: false,
      lazy: true,
    }
    mounted(){
    }
    @Emit()
    //时间格式化，type：YYYY-MM-DD HH:mm:ss
    timeFormatUser(time:string,type:string){
      return moment(time).format(type);
    }
  }
