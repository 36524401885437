
  import { Mutation,State } from 'vuex-class'
import { Component, Vue, Emit, Prop } from '~/node_modules/vue-property-decorator'
  @Component({
    name:"djhAd26",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class djhAd26 extends Vue {
    @Prop({default: () => []}) latestRecordList_?:any
    @Mutation private SET_TOKEN_DATA!: any;
    @State((state) => state.web.distributorsObject) distributorsObject!: any;
    public getCookie:any
    public openUrl:any
    private images:Array<any>=[
      {id:1,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
      {id:2,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
      {id:3,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
      {id:4,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
      {id:5,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
      {id:6,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'},
      {id:7,src:'https://oss1.hk1686.com/3/05c69f46e4e6cd.jpg'}
    ]
    private isShowService:boolean=false
    private swiperOption: any = {
      loop: true,
      autoplay: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      freeMode:true,
      noSwiping:false,
      hideOnClick:true,
      // slidesPerGroup : 1,
      slidesPerView: 5,
      spaceBetween: 17,
      preloadImages: false,
      lazy: true,
    }
    private showBox: boolean = false; //控制查看图片弹窗显示
    private imageBoxArea: Array<any> = []; //查看图片
    mounted(){
      console.log('jsdhjkhjknkln');
    }
    get adArray() {
      if(this.distributorsObject && this.distributorsObject.adverPosMap['3'] && this.distributorsObject.adverPosMap['3'].length > 0) {
        return this.distributorsObject.adverPosMap['3'].filter((v:any) => v.device == 0)
      } else {
        return []
      }
    }
    openBlank(url:any) {
      if(String(url).includes('#')) {
        return
      }
      window.open(url,'_blank')
    }
    openByIndex(index:any) {
      console.log('wefnwkelnfklnkl');
      
      try {
        if(this.adArray[index]) {
          window.open(this.adArray[index].htUrl,'_blank')
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
