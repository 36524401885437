
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"djhAd4",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class djhAd4 extends Vue {
    @Prop(Number) divWidth_?: any
    @Prop(Number) divHeight_?: any
    @Prop(Array) adArray_?: any
    mounted(){

    }
    @Emit()
    getArray(){
      if (this.adArray_) {
        // console.log(this.adArray_.filter((v:any)=>v.device==0),'=======ad==ad=======')
        return this.adArray_.filter((v:any)=>v.device==0)
      } else {
        return []
      }
    }
  }
