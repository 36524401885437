
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {publicWechatQRCodeHttp} from "~/api/operation";
  @Component({
    name:"floatMenu",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class floatMenu extends Vue {
    @Prop({default:{}}) floatMenuOther_?: any
    public changeToMobileHome:any //获取手机端首页路由
    private QRCode:any
    private loading:boolean = false
    private flag:number = -1
    private wechatErWeiMa:string = 'https://oss1.hk1686.com/3/0b5f86b68f784a.png'

    mounted() {
    }
    @Emit()
    //生成二维码
    showPhoneEwm(){
      this.flag = 1
      let url=this.changeToMobileHome()
      let msgDom =null
      msgDom = document.getElementById('msg2')
      if(!!msgDom){
        this.QRCode.toCanvas(msgDom, url, async (error:any) => {
          this.loading=true
        })
      }
    }
    @Emit()
    //鼠标移动
    async mouserEnter(v:number){
      if (v===0) {
        if (!this.wechatErWeiMa) {
          try {
            let {data}=await publicWechatQRCodeHttp({method:'POST'})
            this.wechatErWeiMa=data.url
            this.flag=v
          } catch (error) {
            this.wechatErWeiMa='https://oss1.hk1686.com/3/0b5f86b68f784a.png'
            this.flag=v
          }
        } else {
          this.flag=v
        }
      } else {
        this.flag=v
      }
    }
  }
