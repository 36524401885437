
import {Component, Prop, Emit, Vue} from "vue-property-decorator";
import {State} from 'vuex-class'

@Component({
  name: 'lottery'
})
export default class lottery extends Vue {
  public getCookie: any;
  private drawCloth:any = ""; //画布
  private ctx: any = ""; //画笔
  private ismousedown: boolean = false; //标志用户是否按下鼠标或开始触摸
  private fontem: any = "26"; // 获取html字体大小
  private isScratch: boolean = false; // 是否刮过卡
  private showPrize: boolean = true // 显示奖品
  @Prop(String) number?:any // 号码
  mounted() {
    // this.getCavas()
  }

  // 初始化画布
  getCavas(): void {
    //这是为了不同分辨率上配合@media自动调节刮的宽度
    this.drawCloth = document.getElementById("theCanvas");
    //这里很关键，canvas自带两个属性width、height,我理解为画布的分辨率，跟style中的width、height意义不同。
    //最好设置成跟画布在页面中的实际大小一样
    //不然canvas中的坐标跟鼠标的坐标无法匹配
    this.drawCloth.width = this.drawCloth.clientWidth
    this.drawCloth.height = this.drawCloth.clientHeight;
    this.ctx = this.drawCloth.getContext("2d");
    this.initCanvas();
  }
  // 画刮刮卡
  initCanvas(): void {
    this.number="#20190123123"
    this.ctx.globalCompositeOperation = "source-over";
    this.ctx.fillStyle = "#aaa";
    this.ctx.fillRect(0, 0, this.drawCloth.clientWidth, this.drawCloth.clientHeight);
    this.ctx.fill();
    this.ctx.font = "Bold 3rem Arial";
    this.ctx.textAlign = "center";
    this.ctx.fillStyle = "#656565";
    this.ctx.fillText("刮一刮中大奖", this.drawCloth.width / 2, 100);
    //有些老的手机自带浏览器不支持destination-out,下面的代码中有修复的方法
    this.ctx.globalCompositeOperation = "destination-out";
  }
  //点击到了刮刮乐
  touchstart(e: any): void {
    e.preventDefault();
    console.log(this.getCookie('token'))
    if(this.getCookie('token'))
    {
      this.ismousedown = true;

    }else
    {
      // this.eventMsgTost("请登录","warning", )
      this.$router.push('/login')
    }
  }
  // 检测到手放开了
  touchend(e: any): void {
    e.preventDefault();
    //得到canvas的全部数据
    var a = this.ctx.getImageData(0, 0, this.drawCloth.width, this.drawCloth.height);
    var j = 0;
    for (var i = 3; i < a.data.length; i += 4) {
      if (a.data[i] == 0) j++;
    }
    //当被刮开的区域等于一半时，则可以开始处理结果
    if (j >= a.data.length / 15) {
      this.isScratch = true;
    }else{
      setTimeout(()=>{
        this.isScratch = true;
      },3000)
    }
    this.ismousedown = false;
  }
  // 检测到在刮
  touchmove(e: any): void {
    this.showPrize = true
    e.preventDefault();
    if (this.ismousedown) {
      if (e.changedTouches) {
        e = e.changedTouches[e.changedTouches.length - 1];
      }
      let topD = document.getElementById("canvasTop") as HTMLInputElement;
      var topY = topD.offsetTop
      var oX = this.drawCloth.offsetLeft,
        oY = this.drawCloth.offsetTop + topY;
      var x = (e.clientX + document.body.scrollLeft || e.pageX) - oX || 0,
        y = (e.clientY + document.body.scrollTop || e.pageY) - oY || 0;
      //画360度的弧线，就是一个圆，因为设置了ctx.globalCompositeOperation = 'destination-out';
      //画出来是透明的
      this.ctx.beginPath();
      this.ctx.arc(x, y, this.fontem * 1.5, 0, Math.PI * 2, true); // 调整画笔的大小
      //下面3行代码是为了修复部分手机浏览器不支持destination-out
      //我也不是很清楚这样做的原理是什么
      this.drawCloth.style.display = 'none';
      this.drawCloth.offsetHeight;
      this.drawCloth.style.display = 'inherit';
      this.ctx.fill();
    }
  }

}
