
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"daiShouButton",
  })
  export default class daiShouButton extends Vue {
    @Prop(Number) divHeight_?: any
    @Prop(Array) daiShouArray_?: any
    private nowChild:number = -1 //当前鼠标所在按钮
    mounted() {

    }
  }
