
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"djhAd6",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class djhAd6 extends Vue {
    @Prop(Number) divWidth_?: any
    @Prop(Number) divHeight_?: any
    @Prop(Array) adArray_?: any
    private swiperOption: any = {
      loop: true,
      autoplay: true,
      speed:6000,
      freeMode:true,
      slidesPerGroup : 1,
      direction: 'vertical',
      slidesPerView: "auto",
      preloadImages: false,
      lazy: true,
    }

    private AdArray_ : Array<any> = [] // 广告列表
    mounted(){
      // console.log(this.adArray_,'=======adArray_=index6=====')
    }
    @Emit()
    getArray(){
      if (this.adArray_) {
        // console.log(this.adArray_.filter((v:any)=>v.device==0),'=======ad==ad=======')
        return this.adArray_.filter((v:any)=>v.device==0)
      } else {
        return []
      }
    }
  }
