
import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
//外部点击
import '~/utils/directive'
import {dealGamefilter, gamefilterHttp, gameListHttp} from "~/api/comm"
import {goodsInfoHttp} from '@/api/goods'
@Component({
  name:"logoSearch2",
  components: {
    // newImg2: require('~/components/comm/newImg/index2.vue').default,
  }
})
export default class logoSearch extends Vue {
  @Prop({default:'1'}) logoType?: any
  @Mutation private SET_GAMEFILTER_DATA!: any;
  @State(state => state.web.gamefilterArray) gamefilterArray!: any;
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State(state => state.web.hotGamesArray) hotGamesArray!: any;
  @Mutation private SET_GAMELIST_DATA!: any;
  @State(state => state.web.gameListArray) gameListArray!: any;
  public errorToastFn:any
  public eventMsgTost:any
  public eventNotifyTost:any
  public getGoodsListUrl:any
  public openUrl:any

  private isClickOption : boolean = false // 是否点击选项，如果点击，则不执行handleClose
  private selectGameTxt : string = '选择游戏' // 选中游戏
  private selectAreaTxt : string = '选择大区' // 选中大区
  private selectServerTxt : string = '选择服务器' // 选中服务器
  private selectGameIndex : any = -1 // 选中游戏id
  private selectAreaIndex : any = -1 // 选中大区id
  private selectServerIndex : any = -1 // 选中服务器id
  private seletype : Number = 0 // 显示下拉框的位置
  private wordsIndex: string = 'hot' // 字母默认选中hot
  private words : Array<string> = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"] // 字母
  private gameList : Array<any> = [] // 游戏列表
  private areaList : Array<any> = [] // 大区列表
  private serverList : Array<any> = [] // 服务器列表
  private queryParm : any = {}
  private searchQueryParm : any = {gameId:'',areaId:'',serverId:'',keyWord:''}

  private webSiteUrl:string=''

  async mounted(){
    this.webSiteUrl=window.location.origin
    setTimeout(async ()=>{
      if (this.hotGamesArray && this.hotGamesArray.length>0) {
        this.gameList=this.hotGamesArray
      } else {
        await this.getGameList(true)
      }
      console.log(this.gameList);
      let gameId:any=this.$route.query.gid?this.$route.query.gid:this.$route.query.gameId

      this.$nextTick(async () => {
        if (gameId) { //列表页需要默认当前页面的游戏
          // console.log(this.$route.query.gameId,this.gameListArray,'======gameId=======她她她她她她===gameListArray=======')
          if (this.gameListArray && this.gameListArray.length>0) { //存在全部游戏缓存
            let gameFlag:any=this.gameListArray.find((v:any)=>v.id==gameId)
            if (gameFlag) { //如果在全部游戏列表找到该游戏
              this.searchQueryParm.gameId=gameFlag.id
              this.selectGameTxt=gameFlag.name
              this.getAreaList(this.searchQueryParm.gameId)
            } else { //全部游戏缓存不存在当前游戏，则拉取所有游戏
              await this.getAllGameList()
              let gameFlag2:any=this.gameListArray.find((v:any)=>v.id==gameId)
              if (gameFlag2) { //如果在全部游戏列表找到该游戏
                this.searchQueryParm.gameId=gameFlag2.id
                this.selectGameTxt=gameFlag2.name
                this.getAreaList(this.searchQueryParm.gameId)
              }
            }
          } else {
            await this.getAllGameList()
            let gameFlag3:any=this.gameListArray.find((v:any)=>v.id==gameId)
            if (gameFlag3) { //如果在全部游戏列表找到该游戏
              this.searchQueryParm.gameId=gameFlag3.id
              this.selectGameTxt=gameFlag3.name
              this.getAreaList(this.searchQueryParm.gameId)
            }
          }
        } else {
          // if (this.$store.state.web.distributorsObject.distBaseConf && this.$store.state.web.distributorsObject.distBaseConf.teGameId) {
          //   for (let k in this.gameList) {
          //     console.log(k,'=====kk======')
          //     if (this.gameList[k].id==this.$store.state.web.distributorsObject.distBaseConf.teGameId){
          //       console.log(this.gameList[k])
          //       this.selectGameIndex=k
          //       this.selectGameTxt=this.gameList[k].name
          //       this.isClickOption = true;
          //       this.searchQueryParm.gameId=this.gameList[k].id
          //       return
          //     }
          //   }
          // }
        }
      })
    },50)
  }
  @Emit()
  getImgUrl(url:string){
    if (url && url.indexOf('http')>-1) {
      return url
    } else {
      return `https://oss1.hk1686.com/${url}`
    }
  }

  @Emit()
  // 获取筛选游戏列表
  async getGameList(flag:boolean=false){
    // console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr,'=======recGameStr=========')
    try {
      let {data}=await gameListHttp({params:this.queryParm})
      if (this.errorToastFn(data)) {
        if (flag) {
          this.gameList=[]
          let hotGameArray:any=[]
          if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
            hotGameArray=this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',')
            for (let i:number=0;i<hotGameArray.length;i++) {
              let gameFlag:any=data.data.find((res:any)=>res.id==hotGameArray[i])
              if (gameFlag) {
                this.gameList.push(gameFlag)
              }
            }
            if (!this.hotGamesArray || (this.hotGamesArray && this.hotGamesArray.length<this.gameList.length)) {
              this.SET_HOTGAMES_DATA(this.gameList)
            }
          }
        } else {
          this.gameList=data.data
          this.SET_GAMELIST_DATA(this.gameList)
        }
      }
    } catch (error) {
      this.eventNotifyTost('提示', '网络超时', 'warning')
      this.gameList=[]
    }
  }
  @Emit()
  //拉取所有游戏列表
  async getAllGameList(){
    try {
      let {data}=await gameListHttp()
      if (this.errorToastFn(data)) {
        this.SET_GAMELIST_DATA(data.data)
      }
    } catch (error) {
      this.eventNotifyTost('提示', '网络超时', 'warning')
    }
  }
  @Emit()
  // 获取游戏大区服务器列表 id:游戏id
  async getAreaList(id:number){
    if (id) {
      console.log(this.gamefilterArray)
      if (!this.gamefilterArray[id]) { //不存在游戏配置
        try {
          let{data}=await gamefilterHttp({params:{gameId:id}})
          if (this.errorToastFn(data)) {
            this.gamefilterArray[id]=dealGamefilter(data.data)
            this.SET_GAMEFILTER_DATA(this.gamefilterArray)
            if (this.gamefilterArray[id]){
              //获取大区
              let areafilter :any=Object.values(this.gamefilterArray[id]).find((v:any)=>v.fieldKey=="areaId")
              this.areaList=areafilter.options
            }
          }
        } catch (error) {
          this.eventNotifyTost('提示', '网络超时', 'warning')
        }
      } else { //存在游戏配置
        //获取大区
        let areafilter :any=Object.values(this.gamefilterArray[id]).find((v:any)=>v.fieldKey=="areaId")
        if (areafilter) {
          this.areaList=areafilter.options
        }
      }
    }
  }
  @Emit()
  // 点击label显示下拉框
  async clickLabel(v:number,type:any) {
    console.log(type,'======vvvvv======')
    this.seletype = v;
    if (v==1) {
      this.wordsIndex=type
      if (type=='hot') {
        this.gameList=this.hotGamesArray
      } else {
        this.queryParm['initial']=type
        this.getGameList(false)
      }
    } else if (v==2 && this.areaList.length==0) {
      this.getAreaList(this.searchQueryParm.gameId)
    } else {
      if (!this.searchQueryParm.areaId) {
        this.seletype = 2
        this.getAreaList(this.searchQueryParm.gameId)
      }
    }
  }
  @Emit()
  // 选择游戏
  selectGame(index:number,id:number) {
    this.isClickOption = true;
    this.seletype = 2;
    //拉取大区列表
    let gameIndex = this.gameList.findIndex(v => (v.gameId?v.gameId:v.id) == id)
    this.selectGameIndex = gameIndex;
    this.selectGameTxt = this.gameList[gameIndex].name;
    //筛选条件赋值
    this.searchQueryParm.gameId=id
    this.selectAreaIndex = -1
    this.selectAreaTxt = ''
    this.selectServerIndex = -1
    this.selectServerTxt = ''
    this.getAreaList(id)
  }
  @Emit()
  //选择大区
  selectArea(index:number,id:number) {
    this.isClickOption = true;
    this.seletype = 3;
    this.selectAreaIndex = index;
    //文本显示赋值
    this.selectAreaTxt = this.areaList[index].specialName;
    //拉取服务器列表
    let serverfilter :any=Object.values(this.areaList).find((v:any)=>v.specialId==id)
    console.log(serverfilter,this.areaList,'===aa=a=a==')
    if (serverfilter) {
      this.serverList=serverfilter.secondOptions
    }
    if (this.serverList.length==0) {
      this.serverList=[]
    }
    //筛选条件赋值
    this.searchQueryParm.areaId=id
    console.log(this.searchQueryParm,'====searchQueryParm=======')
    this.selectServerIndex = -1
    this.selectServerTxt = ''
  }
  @Emit()
  // 选择服务器
  selectServer(index:number,id:number) {
    this.isClickOption = true;
    this.seletype = 0;
    this.selectServerIndex = index;
    //文本显示赋值
    this.selectServerTxt = this.serverList[index].twoSpecialName;
    //筛选条件赋值
    this.searchQueryParm.serverId=id
  }
  @Emit()
  // 搜索
  async jumpUrl(){
    let reg = /^[A-Z][0-9]*$/
      if(this.searchQueryParm.keyWord.length==19 && reg.test(this.searchQueryParm.keyWord)) {
          console.log(this.searchQueryParm.keyWord.substring(this.searchQueryParm.keyWord.length-2),1, 'qwrqqi')

        let tempParam = {goodsSn:this.searchQueryParm.keyWord}
        const {data} = await goodsInfoHttp({params:tempParam})
        console.log(data.code,data,'argh')
        if(data.code == 0 && data.data.goodsSn) {
          console.log(this.searchQueryParm.keyWord.substring(this.searchQueryParm.keyWord.length-2),this.searchQueryParm.keyWord.substr(0,1), 1,'qwrqqi')
          this.searchQueryParm.gameId = this.searchQueryParm.keyWord.substring(this.searchQueryParm.keyWord.length-2)
          if(this.searchQueryParm.gameId[0] == '0') {
            this.searchQueryParm.gameId = this.searchQueryParm.gameId[1]
          }
          if(this.searchQueryParm.keyWord.substr(0,1)=='Z') {
            this.searchQueryParm.gid = 1
          } else if(this.searchQueryParm.keyWord.substr(0,1)=='J'){
            this.searchQueryParm.gid = 2
          }
        }
      }

    console.log(this.searchQueryParm,window.location.pathname)
    if (!this.searchQueryParm.gameId) {
      this.seletype=1
      return
    }
    // this.getGoodsListUrl({gameId:this.searchQueryParm.gameId})
    let routeData:any
    if (this.searchQueryParm.areaId && this.searchQueryParm.serverId && this.searchQueryParm.keyWord) {
      routeData = this.$router.resolve({
        path: "/wares/",
        query: {
          gt: '1',
          gid: this.searchQueryParm.gameId,
          keyWord: this.searchQueryParm.keyWord,
          areaId: this.searchQueryParm.areaId,
          serverId: this.searchQueryParm.serverId
        }
      })
    } else if (this.searchQueryParm.areaId && this.searchQueryParm.keyWord) {
      routeData = this.$router.resolve({
        path: "/wares/",
        query: {
          gt: '1',
          gid: this.searchQueryParm.gameId,
          keyWord: this.searchQueryParm.keyWord,
          areaId: this.searchQueryParm.areaId,
        }
      })
    } else if (this.searchQueryParm.areaId && this.searchQueryParm.serverId) {
      routeData = this.$router.resolve({
        path: "/wares/",
        query: {
          gt: '1',
          gid: this.searchQueryParm.gameId,
          areaId: this.searchQueryParm.areaId,
          serverId: this.searchQueryParm.serverId
        }
      })
    } else if (this.searchQueryParm.keyWord) {
      routeData = this.$router.resolve({
        path: "/wares/",
        query: {
          gt: '1',
          gid: this.searchQueryParm.gameId,
          keyWord: this.searchQueryParm.keyWord,
        }
      })
    } else if (this.searchQueryParm.areaId) {
      routeData = this.$router.resolve({
        path: "/wares/",
        query: {
          gt: '1',
          gid: this.searchQueryParm.gameId,
          areaId: this.searchQueryParm.areaId,
        }
      })
    } else {
      routeData = this.$router.resolve({
        path: "/wares/",
        query: {
          gt: '1',
          gid: this.searchQueryParm.gameId,
        }
      })
    }
    if (window.location.pathname != "/wares/") {
      window.open(routeData.href, "_blank");
    } else {
      window.open(routeData.href, "_self");
    }
  }
  @Emit()
  // 关闭弹框
  handleClose(el:any) {
    if (this.isClickOption) {
      this.isClickOption = false;
      return;
    }
    if (this.seletype) this.seletype = 0;
  }

}
