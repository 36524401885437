
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  // require('~/assets/cartoonFigure/live2d')
  // require('~/assets/cartoonFigure/waifu-tips')
  @Component({
    name:"waifu",
    head() {
      return {
        script: [
          { src: 'https://cdn.bootcss.com/jquery/3.2.1/jquery.min.js'},
          { src: '/js/cartoonFigure/waifu-tips.js'},
          { src: '/js/cartoonFigure/live2d.js'}
        ],
        link: [
          { rel: 'stylesheet', type: 'text/css', href: '/js/cartoonFigure/waifu.css'}
        ]
      }
    }
  })
  export default class waifu extends Vue {
    mounted(){
      // const oScript = document.createElement('script');
      // oScript.type = 'text/javascript';
      // // oScript.src = '~/assets/cartoonFigure/live2d';
      // oScript.src = '/static/js/live2d';
      // document.body.appendChild(oScript);
      // setTimeout(() => {})
      // if ((window as any).initModel) {
      //   (window as any).initModel()
      // }
      let intervalCount = 0
      let interval:any = setInterval(()=>{
        if (intervalCount < 10) {
          intervalCount ++
          if ((window as any).initModel) {
            (window as any).initModel()
            clearInterval(interval)
            interval = null
            intervalCount = 0
          }
        } else {
            clearInterval(interval)
            interval = null
            intervalCount = 0
        }
      }, 1000)
    }
  }
  
