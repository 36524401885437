
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  @Component({
    name:"substationIndex8",
    components: {
      logoSearch: require('~/components/comm/logoSearch/index.vue').default,
      djhHeaderNav2: require('~/components/comm/djhHeaderNav/index2.vue').default,
      djhSwiper2: require('~/components/comm/djhSwiper/index2.vue').default,
      hotGameList2: require('~/components/comm/hotGame/hotGameList2.vue').default,
      newsList2: require('~/components/comm/news/newsList2.vue').default,
      todayDealGoods: require('~/components/comm/dealGoods/todayDealGoods.vue').default,
      djhAd4: require('~/components/comm/djhAd/index4.vue').default,
      djhAd5: require('~/components/comm/djhAd/index5.vue').default,
      receGoods: require('~/components/comm/receGoods/index.vue').default,
      djhAd7: require('~/components/comm/djhAd/index7.vue').default,
      cooperationOrg: require('~/components/template/cooperationOrg.vue').default,
      footerService: require('~/components/comm/djhFooter/footerService.vue').default,
      floatMenu: require('~/components/comm/floatMenu/index.vue').default,
    }
  })
  export default class substationIndex8 extends Vue {
    @Mutation private SET_TOKEN_DATA!: any;
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    private colorConfig : Object = { //菜单颜色
      bgColor: "#190F0D",
      hoverBgColor: "#BC0000",
      color: "#EAB700",
      hoverColor: "#EAB700"
    }
    private floatMenuOther:any = {
      title:'客服验证',
      goScroll: 2400,
      img:require('static/image/floatMenu/fangdajing.png')
    }
    mounted(){
      let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
      console.log(body,'===body====')
      body[0].setAttribute('style', 'background-color:#F5F6FA')
    }
  }
