
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  @Component({
    name:"substationIndex6",
    components: {
      logoSearch: require('~/components/comm/logoSearch/index.vue').default,
      djhHeaderNav6: require('~/components/comm/djhHeaderNav/index6.vue').default,
      index6SwiperLeft: require('~/components/template/index6SwiperLeft.vue').default,
      footerService: require('~/components/comm/djhFooter/footerService.vue').default,
      floatMenu: require('~/components/comm/floatMenu/index.vue').default,
    }
  })
  export default class substationIndex6 extends Vue {
    @Mutation private SET_TOKEN_DATA!: any;
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    private colorConfig : Object = { //菜单颜色
      bgColor: "#190F0D",
      hoverBgColor: "#BC0000",
      color: "#EAB700",
      hoverColor: "#EAB700"
    }
    private floatMenuOther:any = {
      title:'客服验证',
      goScroll: 2400,
      img:require('static/image/floatMenu/fangdajing.png')
    }
    mounted(){
      let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
      console.log(body,'===body====')
      body[0].setAttribute('style', 'background-color:#F5F6FA')
    }
  }
