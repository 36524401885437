
import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
import {dealGoodsData, goodsListHttp} from "~/api/goods";
import {gameListHttp} from "~/api/comm";
@Component({
  name:"goodsList8",
  components: {
    newImg: require('~/components/comm/newImg/index.vue').default,
  }
})
export default class goodsList8 extends Vue {
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State(state => state.web.hotGamesArray) hotGamesArray!: any;
  @Prop(Number) divWidth_?: any
  @Prop(Number) divHeight_?: any
  @Prop(Number) itemWidth_?: any
  @Prop(Array) listData_?: any
  @Prop({default:''}) gameType?: any //游戏类型：1：网络游戏  2：手机游戏
  @Prop({default:0}) index?: any //当前组件使用数组第几个游戏：0：第一个  1：第二个
  public errorToastFn:any
  public eventNotifyTost:any
  private noMore: boolean = false;
  private goodsListData:any={
    total:0,
    goodsList:[]
  }
  private param:any={
    pages: 1,
    pageSize:8,
    gameId:83,
    goodsTypeId:1,
  }
  private ListData : any = []
  mounted(){
    setTimeout(async ()=>{
      console.log(this.listData_,'子组件拿到了数据');
      this.ListData = [...this.listData_]
    },400)
  }
  @Emit()
  getListData(){
    return [...this.listData_]
  }
  @Emit()
  async getGoodsHttp(){
    try{
      let {data}=await goodsListHttp({ params: this.param })
      if (this.errorToastFn(data)) {
        let data2:any=dealGoodsData(data.data)
        this.goodsListData=data2
        if (data.data.goodsList.length < this.param.pageSize) {
          this.noMore = true;
        } else {
          this.noMore = false;
        }
      }
    }catch (error) {
      this.goodsListData={}
    }
  }
  @Emit()
  async getGoodsHttpWithId(type: number, gameId: number){
    if (gameId) {
      this.noMore = false;
      this.param = {
        pages: 1,
        pageSize:8,
        gameId: gameId,
        goodsTypeId:1,
      }
      try{
        let {data}=await goodsListHttp({ params: this.param })
        if (this.errorToastFn(data)) {
          let data2:any=dealGoodsData(data.data)
          this.goodsListData=data2
          if (data.data.goodsList.length < this.param.pageSize) {
            this.noMore = true;
          } else {
            this.noMore = false;
          }
        }
      }catch (error) {
        this.goodsListData={}
      }
    } else {
      try{
        let pages = this.param.pages;
        if (type === 1 && pages - 1 > 0) {
          pages --;
        }
        if (type === 2 && !this.noMore) {
          pages ++;
        }
        if (!this.noMore) {
          let requestData = {...this.param}
          requestData['pages'] = pages;
          let {data}=await goodsListHttp({ params: requestData })
          if (this.errorToastFn(data)) {
            if (data.data.goodsList.length === 0) {
              this.noMore = true;
              this.eventNotifyTost('提示', '没有更多啦~', 'warning')
            } else {
              this.param.pages = pages;
              if (data.data.goodsList.length >= this.param.pageSize) {
                this.noMore = false;
              } else {
                this.noMore = true;
              }
              let data2:any=dealGoodsData(data.data)
              this.goodsListData=data2
            }
          }
        } else {
          this.eventNotifyTost('提示', '没有更多啦~', 'warning')
        }
      }catch (error) {
        this.goodsListData={}
      }
    }
  }
  @Emit()
  // 获取游戏列表
  async getGameList(flag:boolean=false) {
    console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr,'============recGameStr===========')
    let gameList:any=[]
    try {
      let {data} = await gameListHttp()
      if (this.errorToastFn(data)){
        let hotGameArray:any=[]
        if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
          hotGameArray=this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',')
          console.log(hotGameArray,'===hotGameArray======')
          for (let i:number=0;i<hotGameArray.length;i++) {
            let gameFlag:any=data.data.find((res:any)=>res.id==hotGameArray[i])
            if (gameFlag) {
              gameList.push(gameFlag)
            }
          }
          if (gameList && gameList.length>0) {
            if (!!this.gameType) {
              let flag=gameList.find((v:any)=>v.gameType==this.gameType)
              if (flag) {
                this.param.gameId=flag.id
                if (flag.goodsTypeStr) {
                  this.param.goodsTypeId=flag.goodsTypeStr.split(',')[0]
                }
              }
            } else {
              let flag:any=gameList[this.index]
              if (flag) {
                this.param.gameId=flag.id
                if (flag.goodsTypeStr) {
                  this.param.goodsTypeId=flag.goodsTypeStr.split(',')[0]
                }
              }
            }
            try{
              let {data}=await goodsListHttp({ params: this.param })
              if (this.errorToastFn(data)) {
                let data2:any=dealGoodsData(data.data)
                this.goodsListData=data2
              }
            }catch (error) {
              this.goodsListData={}
            }
          }
        }
      }
    }catch (e) {
      this.eventNotifyTost('提示', '网络超时', 'warning')
    }
  }
  // 更新ListData
  @Emit()
  freshListData() {
    this.ListData = [...this.listData_]
  }
}
