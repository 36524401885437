
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { distOrderCountHttp } from "~/api/order";
import { latestDealRecordHttp } from "~/api/distributor";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { dailyOrderHttp } from "~/api/order";
import { queryVerifyCustomerHttp } from "~/api/cusSer";
import { addBlackHttp } from "~/api/black";
import { gameListHttp, getNewsListHttp } from "~/api/comm";
import { goodsListHttp, dealGoodsData } from "~/api/goods";

@Component({
  name: "substationIndex13",
  components: {
    logoSearch: require("~/components/comm/logoSearch/index.vue").default,
    djhHeaderNav8: require("~/components/comm/djhHeaderNav/index8.vue").default,
    djhSwiper9: require("~/components/comm/djhSwiper/index9.vue").default,
    djhSwiper8: require("~/components/comm/djhSwiper/index8.vue").default,
    djhAd20: require("~/components/comm/djhAd/index20.vue").default,
    djhAd21: require("~/components/comm/djhAd/index21.vue").default,
    djhAd22: require("~/components/comm/djhAd/index22.vue").default,
    djhAd24: require("~/components/comm/djhAd/index24.vue").default,
    floatMenu9: require("~/components/comm/floatMenu/index9.vue").default,
    newImg2: require("~/components/comm/newImg/index2.vue").default,
    goodsList9: require("~/components/comm/goodsList/goodsList9.vue").default,
  },
})
export default class substationIndex13 extends Vue {
  @Mutation private SET_TOKEN_DATA!: any;
  @State((state) => state.web.distributorsObject) distributorsObject!: any;
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State((state) => state.web.hotGamesArray) hotGamesArray!: any;
  @Mutation private SET_GAMELIST_DATA!: any;
  @State((state) => state.web.gameListArray) gameListArray!: any;
  public qqRegular: any;
  public eventMsgTost: any;
  public getCookie: any;
  public setCookie: any;
  public openUrl: any;
  public errorToastFn: any;
  public eventNotifyTost: any;
  private isShow: boolean = true;
  private isLogined: Boolean = false; // 是否已登录
  private actor: string = ""; // 头像
  private nickName: string = ""; // 昵称
  private gameList: Array<any> = []; // 游戏列表
  private gameType: number = 1; // 游戏列表
  private nowGameTotal: number = 0;
  private startVal : number = 0;
  private isFirstShowInClient : boolean = false
  private isRunCountFloat: boolean = false      //控制首屏轮播右侧的旋转动画是否启动暂停的标识
  private isHaveRunSecondFloat:boolean = false  //热门游戏的旋转是否已经触发过的表示
  private middleHotGameNowChooseItem:any = 0    // 控制热门游戏当前选择的是端游还是手游还是全部游戏
  private staticNumberForSplit:any = 26    // 
  private isShowDownloadDialog = true

  private allDealCount:any = ''   // 存放总成交
  private yesterdayOrder:any = ''   // 存放昨日成交
  private yesterdayView:any = ''   // 存放昨日浏览量
  @Watch('allDealCount')
  getAllDealCount(newVal:any) {
    if(Number(newVal) > 0) {
      this.isRunCountFloat = true
      setTimeout(() => {
        this.isRunCountFloat = false
      },6000)
    }
  }
  async mounted() {
    console.log('jklnklmq',this.hotGamesArray);
    if (this.getCookie("token")) {
      this.isLogined = true;
      this.actor = this.getCookie("actor");
      this.nickName = this.getCookie("nickName");
    }
    console.log(this.distributorsObject, this.gameList, "sdgsagg");
    if (this.hotGamesArray.length == 0) {  // vuex中热门游戏为空
      if(this.gameListArray.length > 0) {  // 从vuex已存在游戏列表中拿hotgame
        let hotGameIdArray:any = []
        let hotGameArray:any = []
        if(this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
          console.log('qdqdqwdwqdqwd',this.gameListArray,this.$store.state.web.distributorsObject.distBaseConf.recGameStr);
          
          hotGameIdArray = this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(",");
          for (let i: number = 0; i < hotGameIdArray.length; i++) {
            let gameFlag: any = this.gameListArray.find(
              (res: any) => res.id == hotGameIdArray[i]
            );
            if (gameFlag) {
              this.gameList.push(gameFlag);
              hotGameArray.push(gameFlag)
            }
          }
          this.SET_HOTGAMES_DATA(hotGameIdArray)
        }
      } else {   // vuex中游戏列表不存在
      console.log('qdqdqwdwqdqwd123123123');
      
        await this.getGameList(true);
      }
    }
    console.log("sdgsagg", this.gameList);
    let scrolltop=document.documentElement.scrollTop||document.body.  scrollTop;
    if(scrolltop > 880) {
      if(!this.isFirstShowInClient) {
        this.isFirstShowInClient = true
      }
      if(!this.isHaveRunSecondFloat) {
        setTimeout(() => {
          this.isHaveRunSecondFloat = true
        },6000)
      }
    } else {
        window.addEventListener('scroll',()=>{
        let scrolltop1=document.documentElement.scrollTop||document.body.scrollTop;
          if(scrolltop1>880){
          if(!this.isFirstShowInClient) {
            this.isFirstShowInClient = true
          }
          if(!this.isHaveRunSecondFloat) {
            setTimeout(() => {
              this.isHaveRunSecondFloat = true
            },6000)
          }
        }
      }) 
    }
    await this.getShowCount();   // 该方法集中处理 总成交 昨日成交 昨日浏览浏览
    console.log('qwejlkjrklgnmfkm',this.allDealCount,this.yesterdayOrder,this.yesterdayView,"滚动新闻");
  }

  get filterHotGameList() {
    if(this.hotGamesArray.length>0) {
      if(this.middleHotGameNowChooseItem == 1) {
        return this.hotGamesArray.filter((v:any,i:any) => i>0 && v.gameType == 1)
      } else if(this.middleHotGameNowChooseItem == 2) {
        return this.hotGamesArray.filter((v:any,i:any) => i>0 && v.gameType == 2)
      } else {
        return this.hotGamesArray.filter((v:any,i:any) => i>0)
      }
    } else {
      return this.hotGamesArray
    }
  }
  @Emit()
  // 获取筛选游戏列表
  async getGameList(flag: boolean = false) {
    console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr,"=======recGameStr123=========");
    try {
      let { data } = await gameListHttp();
      if (this.errorToastFn(data)) {
        console.log(data, "poqlwkjui");
        if (flag) {
          this.gameList = [];
          let hotGameIdArray: any = [];
          if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
            let realHotGameArray:any = []
            console.log('qdqdqwdwqdqwd8899',this.$store.state.web.distributorsObject.distBaseConf.recGameStr);
            hotGameIdArray = this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(",");
            for (let i: number = 0; i < hotGameIdArray.length; i++) {
              let gameFlag: any = data.data.find(
                (res: any) => res.id == hotGameIdArray[i]
              );
              if (gameFlag) {
                this.gameList.push(gameFlag);
                realHotGameArray.push(gameFlag)
              }
            }
            // if (!this.hotGamesArray || (this.hotGamesArray &&this.hotGamesArray.length < this.gameList.length)) {
              console.log('qdqdqwdwqdqwd5566998877',this.gameList);
              this.SET_HOTGAMES_DATA(realHotGameArray);
            // }
          }
          this.SET_GAMELIST_DATA(data.data);
        } else {
          this.gameList = data.data;
          this.SET_GAMELIST_DATA(this.gameList);
        }
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
      this.gameList = [];
    }
  }
  beforeDestroy() {
  }
  // 中间热门游戏点击了端游手游的tabs
  hotGameTab(num:any) {
    if(num == this.middleHotGameNowChooseItem) {
      return
    }
    this.middleHotGameNowChooseItem = num
  }
    // 集中处理 总成交 昨日成交 昨日浏览量
  async getShowCount() {
    try {
      let allDealCount = 0
      let yesterdayOrder = 0
      let yesterdayView = 0
      if (this.$store.state.web.distributorsObject &&this.$store.state.web.distributorsObject.distBaseConf.statisMap) {
        let statisMap = this.$store.state.web.distributorsObject.distBaseConf.statisMap;
        if(!!statisMap && statisMap.includes("{")) {
          let flagMap = JSON.parse(statisMap);
          if(!!flagMap["dealAllCount"]) {
            allDealCount = flagMap["dealAllCount"]
          }
          if(!!flagMap["yesterdayOrder"]) {
            yesterdayOrder = flagMap["yesterdayOrder"]
          }
          if(!!flagMap["yesterdayView"]) {
            yesterdayView = flagMap["yesterdayView"]
          }
        }
      }
      // 请求分站总成交
      const {data} = await distOrderCountHttp()
      console.log('sdjfklsdjkfls',data);
      if(this.errorToastFn(data)) {
        allDealCount = allDealCount + data.data.count
      }
      // 请求分站总成交
      const {data:data1} = await dailyOrderHttp()
      console.log('sdjfklsdjkfls1',data1);
      if(this.errorToastFn(data1) && data1.data.goodsList.length>1) {
        yesterdayOrder = yesterdayOrder + data1.data.goodsList[1].dealNum
      }
      this.allDealCount = String(allDealCount)
      this.yesterdayOrder = yesterdayOrder
      this.yesterdayView = yesterdayView
    } catch (error) {
      console.log(error);
    }
  }
}
