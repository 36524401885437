
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import moment from "moment";
import { gameListHttp } from "~/api/comm";
moment.locale("zh-cn");
@Component({
  name: "hotGameList5",
  components: {
    newImg2: require("~/components/comm/newImg/index2.vue").default,
  },
})
export default class hotGameList5 extends Vue {
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State((state) => state.web.hotGamesArray) hotGamesArray!: any;
  @Prop(Number) divWidth_?: number;
  @Prop(Number) gameCount_?: number;
  public errorToastFn: any;
  public eventMsgTost: any;
  public eventNotifyTost: any;
  private queryParm: any = {}; //筛选
  private gameType: number = 0;
  private mouseIndex: number = -1;
  private gameList: any = [];
  private gameList2: any = [];
  private gameCount: number = 0;
  mounted() {
    setTimeout(async () => {
      if (this.hotGamesArray && this.hotGamesArray.length > 0) {
        this.gameList = this.hotGamesArray;
        this.gameList2 = this.hotGamesArray;
      } else {
        await this.getGameList(true);
      }
    }, 50);
    console.log(this.hotGamesArray, "pofk");
    this.getGameCount();
  }
  @Emit()
  clickGameType(v: number) {
    this.gameType = v;
    if (v == 0) {
      this.gameList = this.gameList2;
    } else {
      this.gameList = this.gameList2.filter((vv: any) => vv.gameType == v);
    }
  }
  @Emit()
  // 获取游戏列表
  async getGameList(flag: boolean = false) {
    console.log(
      this.$store.state.web.distributorsObject.distBaseConf.recGameStr,
      this.queryParm,
      "============recGameStr==========="
    );
    try {
      let { data } = await gameListHttp({ params: this.queryParm });
      if (this.errorToastFn(data)) {
        console.log(data, "获取dfkj");

        if (flag) {
          this.gameList = [];
          this.gameList2 = [];
          let hotGameArray: any = [];
          if (
            this.$store.state.web.distributorsObject.distBaseConf.recGameStr
          ) {
            hotGameArray =
              this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(
                ","
              );
            for (let i: number = 0; i < hotGameArray.length; i++) {
              let gameFlag: any = data.data.find(
                (res: any) => res.id == hotGameArray[i]
              );
              if (gameFlag) {
                this.gameList.push(gameFlag);
                this.gameList2.push(gameFlag);
              }
            }
            if (
              !this.hotGamesArray ||
              (this.hotGamesArray &&
                this.hotGamesArray.length < this.gameList.length)
            ) {
              this.SET_HOTGAMES_DATA(this.gameList);
            }
          }
        } else {
          this.gameList = data.data;
          this.gameList2 = data.data;
        }
      }
    } catch (e) {
      this.eventNotifyTost("提示", "网络超时", "warning");
      this.gameList = [];
      this.gameList2 = [];
    }
  }
  // 获取游戏列表for Count
  async getGameCount(flag: boolean = false) {
    console.log(
      this.$store.state.web.distributorsObject.distBaseConf.recGameStr,
      this.queryParm,
      "============recGameStr==========="
    );
    try {
      let { data } = await gameListHttp({ params: this.queryParm });
      if (this.errorToastFn(data)) {
        this.gameCount = data.data.length;
      }
    } catch (e) {
      this.eventNotifyTost("提示", "网络超时", "warning");
      this.gameList = [];
      this.gameList2 = [];
    }
  }
}
