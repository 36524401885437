import { restful } from '../utils/restful'

//快速金币获取跨区比例
export function queryQucikCoinHttp(par = {}) {
  var url = '/api/quickcoin/queryQucikCoin'
  return restful(par, url ,false)
}

//快速金币下单
export function placeOrderQkCoinHttp(par = {}) {
  var url = '/api/quickcoin/placeOrderQkCoin'
  return restful(par, url ,true)
}
