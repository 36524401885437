
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {gameListHttp} from "~/api/comm";
  @Component({
    name:"hotGameList",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class hotGameList extends Vue {
    @Mutation private SET_HOTGAMES_DATA!: any;
    @State(state => state.web.hotGamesArray) hotGamesArray!: any;
    @Prop(Number) divWidth_?: number //宽度
    @Prop(Number) divHeight_?: number //高度
    public errorToastFn:any
    public openGoodsList:any
    public eventNotifyTost:any
    private gameList:Array<any>=[]
    mounted() {
      setTimeout(()=>{
        this.getHotGameList()
      },50)
    }
    @Emit()
    //获取热门游戏
    async getHotGameList(){
      if (this.hotGamesArray && this.hotGamesArray.length>0) {
        this.gameList=this.hotGamesArray
      } else {
        try {
          let {data}=await gameListHttp()
          if (this.errorToastFn(data)) {
            this.gameList=[]
            let hotGameArray:any=[]
            if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
              hotGameArray=this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',')
              for (let i:number=0;i<hotGameArray.length;i++) {
                let gameFlag:any=data.data.find((res:any)=>res.id==hotGameArray[i])
                if (gameFlag) {
                  this.gameList.push(gameFlag)
                }
              }
              if (!this.hotGamesArray || (this.hotGamesArray && this.hotGamesArray.length<this.gameList.length)) {
                this.SET_HOTGAMES_DATA(this.gameList)
              }
            }
          }
        } catch (error) {
          this.eventNotifyTost('提示', '网络超时', 'warning')
          this.gameList=[]
        }
      }
    }

    @Emit()
    // 路由处理
    goUrl(item:any){
      if (item.goodsTypeStr) {
        let goodsTypeFlag=item.goodsTypeStr.split(',')
        this.openGoodsList({gameId:item.id,goodsTypeId:goodsTypeFlag[0]})
      } else {
        this.openGoodsList({gameId:item.id})
      }
    }
  }
