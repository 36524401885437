
import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
@Component({
  name:"floatMenu4",
  components: {
    newImg2: require('~/components/comm/newImg/index2.vue').default,
  }
})
export default class floatMenu4 extends Vue {
  private loading:boolean = false

  mounted() {
  }
}
