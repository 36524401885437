
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {getNewsListHttp} from "~/api/comm";
  import {distOrderCountHttp} from "~/api/order";
  import {queryVerifyCustomerHttp} from "~/api/cusSer";
  import {goodsListHttp} from "~/api/goods";
  import {latestDealRecordHttp} from "~/api/distributor";

  @Component({
    name:"substationIndex2",
    components: {
      newImg: require('~/components/comm/newImg/index.vue').default,
      logoSearch: require('~/components/comm/logoSearch/index.vue').default,
      djhHeaderNav4: require('~/components/comm/djhHeaderNav/index4.vue').default,
      djhSwiper2: require('~/components/comm/djhSwiper/index2.vue').default,
      djhSwiper3: require('~/components/comm/djhSwiper/index3.vue').default,
      footerService: require('~/components/comm/djhFooter/footerService.vue').default,
    }
  })
  export default class substationIndex1 extends Vue {
    @Mutation private SET_TOKEN_DATA!: any;
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    @Prop(Object) goodsListData_?: any
    private colorConfig : Object = { //菜单颜色
      bgColor: "#190F0D",
      hoverBgColor: "#BC0000",
      color: "#EAB700",
      hoverColor: "#EAB700"
    }
    private floatMenuOther:any = {}
    public errorToastFn:any
    public eventMsgTost:any
    public qqRegular : any // qq验证
    public eventNotifyTost:any

    private rollNewsData:any = '最近有骗子冒充代售骗钱，大家交易前一定要验证客服真假'
    private newTabIndex:any = 1 // 选择的新闻公告标签
    private newsArray:Array<any>=[] //新闻列表存放
    private newsTypeList:Array<any>=[
      {name:'公告',id:1},
      {name:'帮助中心',id:2},
      {name:'关于我们',id:3},
      {name:'首页弹屏',id:4},
      {name:'新手入门',id:5},
      {name:'客服中心',id:6},
      {name:'特色服务',id:7},
    ]
    private orderCount:any= 0 // 成交订单总数
    private checkNumber:any= null // 验证的客服QQ
    private visitCount:any= null // 昨日访问总数
    private goodsCount:any= null // 在售商品总数
    private goodsListIndex:any= 0 // 推荐商品页数
    private goodsListArrow:any= 0 // 推荐商品切换方向(0是向下，1是向上)
    private goodsListDatas:any= [] // 推荐商品列表
    private orderListData:any= [] // 每日成交

    async mounted(){
      this.goodsListDatas = this.goodsListData_.goodsList
      console.log(this.goodsListData_, '11111111111111')
      let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
      body[0].setAttribute('style', 'background-color:#F2F3F5')
      await this.getRollNews()
      await this.changeList(1)
      await this.getDealGoods()
      this.getYesterdayVisit()
      this.getSellGoodsNum()
    }

    @Emit() // 获取滚动公告
    async getRollNews(){
      try {
        let {data}=await getNewsListHttp({params:{pageSize:9,newsType:11}})
        const newData = data.data.list
        if(newData.length>0){
          this.rollNewsData = newData[0].content
          this.rollNewsData = this.rollNewsData.replace(/<[^>]*>/g, "")
        }
        console.log(this.rollNewsData, '获取滚动公告获取滚动公告')
      } catch (e) {
        console.log(e, '获取滚动公告失败！')
      }
    }

    @Emit()//改变新闻列表
    async changeList(v:number){
      this.newTabIndex=v
      try {
        let {data}=await getNewsListHttp({params:{pageSize:9,newsType:v}})
        this.newsArray = data.data.list
        console.log(this.newsArray, '改变新闻列表')
      } catch (error) {
        this.errorToastFn('系统繁忙！')
      }
    }

    @Emit() // 获取分站成交笔数
    async getDistOrderCount(){
      try {
        let {data} = await distOrderCountHttp()
        if (this.errorToastFn(data)) {
          if (data.data) {
            let flag=0
            if (this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.distBaseConf.statisMap) {
              flag=Number(flag)+Number(this.$store.state.web.distributorsObject.distBaseConf.statisMap)
            }
            this.orderCount=Number(data.data.count)+flag
          }
        }
      } catch (error) {
        this.errorToastFn('提示', '网络超时', 'warning')
      }
    }

    @Emit()//客服验证账号
    async checkSafe(){
      if(this.qqRegular.test(this.checkNumber)){
        this.checkNumber=null
        return false
      }
      try {
        const param = {queryValue:this.checkNumber,queryType:1}
        let {data}:any=await queryVerifyCustomerHttp({params:param})
        if (this.errorToastFn(data)) {
          console.log('经验证：${this.checkNumber}为真客服。',1111111111111111111111111111111111111111)
          if (data.data && !!data.data.distId) {
            this.eventMsgTost(`经验证：${this.checkNumber}为真客服。`, 'success')
          } else {
            this.eventMsgTost(`经验证：${this.checkNumber}为假客服。`)
          }
        }
      } catch (error) {
        this.errorToastFn(error,'系统繁忙中，请稍后再试！');
      }
    }

    @Emit() // 获取昨日访问数
    getYesterdayVisit(){
      let day1 = new Date();
      day1.setTime(day1.getTime()-24*60*60*1000);
      let s1 = new Date(day1.getFullYear()+"-" + (day1.getMonth()+1) + "-" + day1.getDate());
      // console.log((s1.valueOf()/10000000).toFixed(0), '获取昨日访问数')
      this.visitCount = (s1.valueOf()/1000000000).toFixed(0)+2684
    }

    @Emit() // 获取在售商品数
    async getSellGoodsNum(){
      try{
        const param = {pageSize:12, gameId:69, goodsTypeId:1}
        let {data}=await goodsListHttp({ params: param })
        if (this.errorToastFn(data)) {
          this.goodsCount = 2413+data.data.total
        }else {
          this.goodsCount = 2413
        }
      }catch (error) {
        console.log(error,'获取在售商品数失败！')
        this.goodsCount = 2413
      }
    }

    @Emit() // 切换商品列表
    changeGoodsList(num:any) {
      this.goodsListIndex = this.goodsListIndex+num
      if(this.goodsListIndex>1){
        this.goodsListIndex = 1
      }
      if(this.goodsListIndex<0){
        this.goodsListIndex = 0
      }
      this.goodsListArrow = num
    }

    @Emit() // 获取每日成交记录
    async getDealGoods(){
      try {
        let {data}=await latestDealRecordHttp({params:{pageSize:8}})
        if (this.errorToastFn(data)) {
          this.orderListData=data.data
          console.log(this.orderListData,'=======orderListData======')
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }

    @Emit()
    async getareaData(data:any){
      let res=''
      let dats = JSON.parse(data)
      if(!!data.gameName){
        res = dats.gameName
      } else {
        res = '暂无'
      }
      if(!!data.areaName){
        res = res + '-' + dats.areaName
      } else {
        res = res + '-' + '暂无'
      }
      if(!!data.serverName){
        res = res + '-' + dats.serverName
      } else {
        res = res + '-' + '暂无'
      }
      return res
    }
  }
