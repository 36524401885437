
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"headerLogoNav",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class headerLogoNav extends Vue {
    @Prop(Array) headerArray_?: any
    public getCookie:any
    private selected : number = 0 // 选中第几个
    private isShowBottom : boolean = false // 是否显示菜单栏
    private isLogined:boolean=false //是否登录
    private isShowBottomCenter:boolean=false //登录情况下是否显示注销等按钮
    private actor:any=''
    private topMenu : Array<any> = [
      {title:'网站首页', htUrl:'/', options:[]},
      {
        title:'帐号交易',
        htUrl:'/allgame?goodsTypeId=1',
        options:[
          {title:'地下城与勇士',htUrl:'/wares?gt=1&gid=1'},
          {title:'地下城与勇士',htUrl:'/wares?gt=1&gid=1'},
          {title:'地下城与勇士',htUrl:'/wares?gt=1&gid=1'},
          {title:'地下城与勇士',htUrl:'/wares?gt=1&gid=1'},
          {title:'地下城与勇士',htUrl:'/wares?gt=1&gid=1'},
        ]
      },
      {
        title:'金币交易',
        htUrl:'/',
        options:[
          {title:'地下城与勇士',htUrl:'/wares?gt=1&gid=1'},
          {title:'地下城与勇士',htUrl:'/wares?gt=1&gid=1'},
          {title:'地下城与勇士',htUrl:'/wares?gt=1&gid=1'},
        ]
      },
      {title:'游戏代练', htUrl:'#', options:[]},
      {title:'游戏陪玩',htUrl:'#',options:[]},
      {title:'包强化增幅',htUrl:'#',options:[]},
    ] // 菜单列表
    mounted(){
      this.actor=this.getCookie('actor')
      if (this.getCookie()) {
        this.isLogined=true
      }
    }
    @Emit()
    mouseLeave(){
      console.log('333333333')
      this.isShowBottom=false
      this.selected=0
    }
  }
