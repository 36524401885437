
import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
//外部点击
import '~/utils/directive'
import {dealGamefilter, gamefilterHttp, gameListHttp, goodsTypeListHttp} from "~/api/comm"
import {goodsInfoHttp} from '@/api/goods'
@Component({
  name:"logoSearch3",
  components: {
    // newImg2: require('~/components/comm/newImg/index2.vue').default,
  }
})
export default class logoSearch3 extends Vue {
  @Prop({default:'1'}) logoType?: any
  @Mutation private SET_GAMEFILTER_DATA!: any;
  @State(state => state.web.gamefilterArray) gamefilterArray!: any;
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State(state => state.web.hotGamesArray) hotGamesArray!: any;
  @Mutation private SET_GAMELIST_DATA!: any;
  @State(state => state.web.gameListArray) gameListArray!: any;
  public errorToastFn:any
  public eventMsgTost:any
  public eventNotifyTost:any
  public getGoodsListUrl:any
  public openUrl:any

  private isClickOption : boolean = false // 是否点击选项，如果点击，则不执行handleClose
  private selectGameTxt : string = '选择游戏' // 选中游戏
  private selectAreaTxt : string = '选择大区' // 选中大区
  private selectServerTxt : string = '选择服务器' // 选中服务器
  private selectGoodsTypeTxt : string = '商品类型' // 选中商品类型
  private selectGameIndex : any = -1 // 选中游戏id
  private selectGoodsTypeId : any = -1 // 选中商品类型
  private seletype : Number = 0 // 显示下拉框的位置
  private wordsIndex: string = 'hot' // 字母默认选中hot
  private words : Array<string> = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"] // 字母
  private gameList : Array<any> = [] // 游戏列表
  private allGoodsTypeList : Array<any> = [] // 所有商品类型列表
  private goodsTypeList : Array<any> = [] // 当前游戏商品类型列表
  private queryParm : any = {}
  private searchQueryParm : any = {gameId:'',goodsType:'',keyWord:''}

  private webSiteUrl:string=''

  async mounted(){
    console.log('mountedmountedmountedmountedmountedmountedmountedmountedmounted');
    
    this.webSiteUrl=window.location.origin
    setTimeout(async ()=>{
      if (this.hotGamesArray && this.hotGamesArray.length>0) {
        this.gameList=this.hotGamesArray
      } else {
        await this.getGameList(true)
      }
      console.log(this.gameList,this.hotGamesArray,this.gamefilterArray,'游戏列表');
      let gameId:any=this.$route.query.gid?this.$route.query.gid:this.$route.query.gameId
      // 请求全部商品类型列表
      const tempGoodsTypeListHttp:any = await goodsTypeListHttp()
      this.allGoodsTypeList = tempGoodsTypeListHttp.data.data.map((v:any) => {return {goodsTypeId:v.goodsTypeId,goodsTypeName:v.goodsTypeName}})
      console.log(this.allGoodsTypeList,'akljdflwefioou')

      this.$nextTick(async () => {
        if (gameId) { //列表页需要默认当前页面的游戏
          // console.log(this.$route.query.gameId,this.gameListArray,'======gameId=======她她她她她她===gameListArray=======')
          if (this.gameListArray && this.gameListArray.length>0) { //存在全部游戏缓存
            let gameFlag:any=this.gameListArray.find((v:any)=>v.id==gameId)
            if (gameFlag) { //如果在全部游戏列表找到该游戏
              this.searchQueryParm.gameId=gameFlag.id
              this.selectGameTxt=gameFlag.name
            } else { //全部游戏缓存不存在当前游戏，则拉取所有游戏
              await this.getAllGameList()
              let gameFlag2:any=this.gameListArray.find((v:any)=>v.id==gameId)
              if (gameFlag2) { //如果在全部游戏列表找到该游戏
                this.searchQueryParm.gameId=gameFlag2.id
                this.selectGameTxt=gameFlag2.name
              }
            }
          } else {
            await this.getAllGameList()
            let gameFlag3:any=this.gameListArray.find((v:any)=>v.id==gameId)
            if (gameFlag3) { //如果在全部游戏列表找到该游戏
              this.searchQueryParm.gameId=gameFlag3.id
              this.selectGameTxt=gameFlag3.name
            }
          }
        } else {
          // if (this.$store.state.web.distributorsObject.distBaseConf && this.$store.state.web.distributorsObject.distBaseConf.teGameId) {
          //   for (let k in this.gameList) {
          //     console.log(k,'=====kk======')
          //     if (this.gameList[k].id==this.$store.state.web.distributorsObject.distBaseConf.teGameId){
          //       console.log(this.gameList[k])
          //       this.selectGameIndex=k
          //       this.selectGameTxt=this.gameList[k].name
          //       this.isClickOption = true;
          //       this.searchQueryParm.gameId=this.gameList[k].id
          //       return
          //     }
          //   }
          // }
        }
      })
    },50)
  }
  destroyed() {
    console.log('组件消亡');
  }
  @Emit()
  getImgUrl(url:string){
    if (url && url.indexOf('http')>-1) {
      return url
    } else {
      return `https://oss1.hk1686.com/${url}`
    }
  }

  @Emit()
  // 获取筛选游戏列表
  async getGameList(flag:boolean=false){
    // console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr,'=======recGameStr=========')
    try {
      let {data}=await gameListHttp({params:this.queryParm})
      if (this.errorToastFn(data)) {
        if (flag) {
          this.gameList=[]
          let hotGameArray:any=[]
          if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
            hotGameArray=this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',')
            for (let i:number=0;i<hotGameArray.length;i++) {
              let gameFlag:any=data.data.find((res:any)=>res.id==hotGameArray[i])
              if (gameFlag) {
                this.gameList.push(gameFlag)
              }
            }
            if (!this.hotGamesArray || (this.hotGamesArray && this.hotGamesArray.length<this.gameList.length)) {
              this.SET_HOTGAMES_DATA(this.gameList)
            }
          }
        } else {
          this.gameList=data.data
          this.SET_GAMELIST_DATA(this.gameList)
        }
      }
    } catch (error) {
      this.eventNotifyTost('提示', '网络超时', 'warning')
      this.gameList=[]
    }
  }
  @Emit()
  //拉取所有游戏列表
  async getAllGameList(){
    try {
      let {data}=await gameListHttp()
      if (this.errorToastFn(data)) {
        this.SET_GAMELIST_DATA(data.data)
      }
    } catch (error) {
      this.eventNotifyTost('提示', '网络超时', 'warning')
    }
  }
  // 选择商品类型
  selectGoodsType(index:number,name:string,id:number) {
    this.seletype = -1
    this.selectGoodsTypeId = id
    this.selectGoodsTypeTxt = name
    this.searchQueryParm.goodsType = id
  }
  @Emit()
  // 点击label显示下拉框
  async clickLabel(v:number,type:any) {
    console.log(type,'======vvvvv======')
    this.seletype = v;
    if (v==1) {
      this.wordsIndex=type
      if (type=='hot') {
        this.gameList=this.hotGamesArray
      } else {
        this.queryParm['initial']=type
        this.getGameList(false)
      }
    }
  }
  @Emit()
  // 选择游戏
  selectGame(index:number,id:number) {
    this.isClickOption = true;
    this.seletype = 2;
    // 获取该游戏下的商品类型
    let tempGoodsTypeList = this.gameList.find((v:any) => v.id==id).goodsTypeStr
    if(tempGoodsTypeList.includes(',')) {
        console.log(this.allGoodsTypeList,'当前游戏的商品类型')
        console.log(tempGoodsTypeList.split(','),'当前游戏的商品类型')

        this.goodsTypeList = this.allGoodsTypeList.filter((v:any) => {
            return tempGoodsTypeList.split(',').includes(v.goodsTypeId+'')
        })
    } else {
        this.goodsTypeList = this.allGoodsTypeList.filter((v:any) => {
            return tempGoodsTypeList.includes(v.goodsTypeId+'')
        })
    }
    console.log(this.goodsTypeList,'当前游戏的商品类型');

    //拉取大区列表
    let gameIndex = this.gameList.findIndex(v => (v.gameId?v.gameId:v.id) == id)
    this.selectGameIndex = gameIndex;
    this.selectGameTxt = this.gameList[gameIndex].name;
    //筛选条件赋值
    this.searchQueryParm.gameId=id
    this.selectGoodsTypeId = -1
    this.selectGoodsTypeTxt = ''
    this.searchQueryParm.goodsType = ''
  }
  @Emit()
  // 搜索
  async jumpUrl(){
    let reg = /^[A-Z][0-9]*$/
      if(this.searchQueryParm.keyWord.length==19 && reg.test(this.searchQueryParm.keyWord)) {
          console.log(this.searchQueryParm.keyWord.substring(this.searchQueryParm.keyWord.length-2),1, 'qwrqqi')
        
        let tempParam = {goodsSn:this.searchQueryParm.keyWord}
        const {data} = await goodsInfoHttp({params:tempParam}) 
        console.log(data.code,data,'argh')
        if(data.code == 0 && data.data.goodsSn) {
          console.log(this.searchQueryParm.keyWord.substring(this.searchQueryParm.keyWord.length-2),this.searchQueryParm.keyWord.substr(0,1), 1,'qwrqqi')
          this.searchQueryParm.gameId = this.searchQueryParm.keyWord.substring(this.searchQueryParm.keyWord.length-2)
          if(this.searchQueryParm.gameId[0] == '0') {
            this.searchQueryParm.gameId = this.searchQueryParm.gameId[1]
          }
          if(this.searchQueryParm.keyWord.substr(0,1)=='Z') {
            this.searchQueryParm.gid = 1
          } else if(this.searchQueryParm.keyWord.substr(0,1)=='J'){
            this.searchQueryParm.gid = 2
          }
        }
      }

    console.log(this.searchQueryParm,window.location.pathname)
    if (!this.searchQueryParm.gameId) {
      this.seletype=1
      return
    }
    // this.getGoodsListUrl({gameId:this.searchQueryParm.gameId})
    let routeData:any
    if (this.searchQueryParm.goodsType && this.searchQueryParm.keyWord) {
      routeData = this.$router.resolve({
        path: "/wares/",
        query: {
          gt: this.searchQueryParm.goodsType,
          gid: this.searchQueryParm.gameId,
          keyWord: this.searchQueryParm.keyWord
        }
      })
    } else if (this.searchQueryParm.goodsType) {
      routeData = this.$router.resolve({
        path: "/wares/",
        query: {
          gt: this.searchQueryParm.goodsType,
          gid: this.searchQueryParm.gameId
        }
      })
    } else if (this.searchQueryParm.keyWord) {
      routeData = this.$router.resolve({
        path: "/wares/",
        query: {
          gt: '1',
          gid: this.searchQueryParm.gameId,
          keyWord: this.searchQueryParm.keyWord
        }
      })
    } else if (this.searchQueryParm.gameId) {
      routeData = this.$router.resolve({
        path: "/wares/",
        query: {
          gt: '1',
          gid: this.searchQueryParm.gameId
        }
      })
    }
    if (window.location.pathname != "/wares/") {
      window.open(routeData.href, "_blank");
    } else {
      window.open(routeData.href, "_self");
    }
  }
  @Emit()
  // 关闭弹框
  handleClose(el:any) {
    if (this.isClickOption) {
      this.isClickOption = false;
      return;
    }
    if (this.seletype) this.seletype = 0;
  }

}
