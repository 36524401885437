
  import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
  @Component({
    name:"djhSwiper2",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class djhSwiper extends Vue {
    @Prop(Array) swiperList_?: any
    @Prop({default:'716px'}) divHeight_?: any
    @Prop({default:false}) needPagination?: boolean // 是否需要指示器
    private swiper_index:number=0//当前轮播图（走马灯）页面编号
    private swiperOption: any = {
      pagination: {
        el: '.swiper-pagination',
        clickable:true,
        bulletClass : 'pagination',
        bulletActiveClass: 'pagination_active',
        // renderBullet:'官方文档查看这个属性'
      },
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      loop: true,
      lazy: true,
      on: {
        slideChangeTransitionEnd: () => {
          let swiper = (this.$refs.sub_swiperBox as any).swiper;
          this.swiper_index = swiper.activeIndex;
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    }
    mounted(){
    }
    @Emit()
    getSwiper(){
      if (this.swiperList_ && this.swiperList_.length>0) {
        // console.log(this.swiperList_.filter((v:any)=>v.device==0),'=======swiper=========')
        return this.swiperList_.filter((v:any)=>v.device==0)
      } else {
        return []
      }
    }
  }
