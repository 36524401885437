
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  import  moment from 'moment';
  import {latestDealRecordHttp} from "~/api/distributor";
  moment.locale("zh-cn");
  @Component({
    name:"latestDealGoods",
    components: {
      newImg: require('~/components/comm/newImg/index.vue').default,
    },
  })
  export default class latestDealGoods extends Vue {
    @Prop(Number) divWidth_?: number
    @Prop(Number) divHeight_?: any
    public openUrl:any
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    private orderListData:Object={}
    mounted(){
      this.getDealGoods()
    }
    @Emit()
    async getDealGoods(){
      try {
        let {data}=await latestDealRecordHttp({params:{pageSize:8}})
        if (this.errorToastFn(data)) {
          this.orderListData=data.data
          console.log(this.orderListData,'=======orderListData======')
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
    @Emit()
    //普通商品区服信息
    getDetails(item:any){
      let flag:any={}
      if (item && item.details) {
        flag=eval('(' + item.details +')')
        return `${flag.areaName}-${flag.serverName}`
      } else {
        return ''
      }
    }
    @Emit()
    //普通商品跨区信息
    getDetailsCross(item:any){
      let flag:any={}
      if (item && item.details) {
        flag=eval('(' + item.details +')')
        if (flag.crossName) {
          if (String(flag.crossName).indexOf('DNF')>-1) { //地下城
            return String(flag.crossName).replace('DNF','')
          } else {
            return `${flag.crossName}`
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
