
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  import  moment from 'moment';
  import {getNewsListHttp} from "~/api/comm";
  import {latestDealRecordHttp} from "~/api/distributor";
  moment.locale("zh-cn");
  @Component({
    name:"hotGameList2",
  })
  export default class hotGameList2 extends Vue {
    @Prop(Number) divWidth_?: number
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    private date:any=''
    private newDealGoodsArray:any= []
    mounted(){
      this.date=moment(new Date()).format('YYYY.MM.DD')
      this.getDealGoods()
    }
    @Emit()
    async getDealGoods(){
      try {
        let {data}=await latestDealRecordHttp({params:{pageSize:10}})
        if (this.errorToastFn(data)) {
          this.newDealGoodsArray=data.data
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
    @Emit()
    //获取游戏
    getDetails(v:any){
      let flag:any={}
      if (v) {
        flag=eval('(' + v +')')
        return `${flag.gameName}`
      } else {
        return ''
      }
    }
  }
