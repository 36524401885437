
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {publicWechatQRCodeHttp} from "~/api/operation";
  import {goodsListHttp} from "~/api/goods";
  import axios from "~/plugins/axios";
  @Component({
    name:"bestCoin",
  })
  export default class bestCoin extends Vue {
    public coinUnitType:any //金币单位
    private coinListTitle:string='' //标题（区服）
    private coinList:Array<any>=[] //金币列表

    mounted() {
      this.getCoinList()
    }
    @Emit()
    async getCoinList(){
      try {
        let game_id=1
        if (this.$store.state.web.distributorsObject.theme[0]){
          game_id=this.$store.state.web.distributorsObject.theme[0].game
        }
        let {data}=await goodsListHttp({params:{'goodsTypeId':2,'gameId':game_id,'pageSize':10}})
        this.coinList=data.results
        if (this.coinList.length>0) {
          this.coinListTitle=this.coinList[0].area_server
        }
      } catch (error) {
        this.coinList=[]
      }
    }
    @Emit()
    getAreaServer(area_server:string,cross:string){
      let index1=area_server.indexOf('-')
      let index2=area_server.lastIndexOf('-')
      if (!!cross) {
        return `${cross}${area_server.substring(index2,area_server.length)}`
      } else {
        return area_server.substring(index1+1,area_server.length)
      }
    }
  }
