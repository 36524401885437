
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"freeEvaluation",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class freeEvaluation extends Vue {
    @Prop(Number) divWidth_?: number //宽度
    private list:any={
      adverTag:[],
      htUrl:[],
      smalltitle:[],
      themeColor:[],
      title:[],
      url:[],
    }
    mounted() {
      if (this.$store.state.web.distributorsObject.freeValTitles && this.$store.state.web.distributorsObject.freeValTitles.length>0) {
        this.dealFreeEvaluation()
      }
    }
    @Emit()
    //数据处理
    dealFreeEvaluation(){
      let flag=this.$store.state.web.distributorsObject.freeValTitles[0]
      for (let i in flag) {
        if (flag[i]) {
          this.list[i]=String(flag[i]).split(',')
        }
      }
      console.log(this.list,'=========i===iii=i=i=i=i=i===========')
    }
  }
