
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import  moment from 'moment';
  import {gameListHttp} from "~/api/comm";
  moment.locale("zh-cn");
  @Component({
    name:"hotGameList2",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class hotGameList2 extends Vue {
    @Mutation private SET_HOTGAMES_DATA!: any;
    @State(state => state.web.hotGamesArray) hotGamesArray!: any;
    @Prop(Number) divWidth_?: number
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    private queryParm:any={}//筛选
    private wordsIndex:number=-1
    private gameList:any=[]
    private words:Array<any>=["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
    mounted(){
      setTimeout(async ()=>{
        if (this.hotGamesArray && this.hotGamesArray.length>0) {
          this.gameList=this.hotGamesArray
        } else {
          await this.getGameList(true)
        }
      },50)
    }
    @Emit()
    //点击字母筛选
    clickWords(item:any,index:number){
      this.wordsIndex=index
      if (index==-1) { //点击了热门
        this.gameList=this.hotGamesArray
      } else {
        this.queryParm={initial:item}
        this.getGameList(false)
      }
    }
    @Emit()
    // 获取游戏列表
    async getGameList(flag:boolean=false) {
      console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr,'============recGameStr===========')
      try {
        let {data} = await gameListHttp({params:this.queryParm})
        if (this.errorToastFn(data)){
          if (flag) {
            this.gameList=[]
            let hotGameArray:any=[]
            if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
              hotGameArray=this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',')
              for (let i:number=0;i<hotGameArray.length;i++) {
                let gameFlag:any=data.data.find((res:any)=>res.id==hotGameArray[i])
                if (gameFlag) {
                  this.gameList.push(gameFlag)
                }
              }
              if (!this.hotGamesArray || (this.hotGamesArray && this.hotGamesArray.length<this.gameList.length)) {
                this.SET_HOTGAMES_DATA(this.gameList)
              }
            }
          } else {
            this.gameList = data.data;
          }
        }
      }catch (e) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
        this.gameList = []
      }
    }
    @Emit()
    //计算商品类型
    getGoodsType(v:any){
      if (v) {
        let strToObj = JSON.parse(v)
        return strToObj
      } else {
        return []
      }
    }
  }
