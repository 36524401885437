
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { distOrderCountHttp } from "~/api/order";
import { latestDealRecordHttp } from "~/api/distributor";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { dailyOrderHttp } from "~/api/order";

@Component({
  name: "substationIndex5",
  components: {
    logoSearch: require("~/components/comm/logoSearch/index.vue").default,
    djhHeaderNav5: require("~/components/comm/djhHeaderNav/index5.vue").default,
    djhSwiper5: require("~/components/comm/djhSwiper/index5.vue").default,
    gameCheckNum: require("~/components/template/gameCheckNum.vue").default,
    newsList5: require("~/components/comm/news/newsList5.vue").default,
    homeLogin3: require("~/components/comm/homeLogin/index3.vue").default,
    djhAd14: require("~/components/comm/djhAd/index14.vue").default,
    djhAd13: require("~/components/comm/djhAd/index13.vue").default,
    newsList6: require("~/components/comm/news/newsList6.vue").default,
    djhAd15: require("~/components/comm/djhAd/index15.vue").default,
    hotGameList4: require("~/components/comm/hotGame/hotGameList4.vue").default,
    goodsList6: require("~/components/comm/goodsList/goodsList6.vue").default,
    footerService: require("~/components/comm/djhFooter/footerService.vue")
      .default,
    bestCoin: require("~/components/comm/coin/bestCoin.vue").default,
    newsList: require("~/components/comm/news/newsList.vue").default,
    safeCheck: require("~/components/comm/safeCheck/index.vue").default,
    floatMenu6: require("~/components/comm/floatMenu/index6.vue").default,
    newImg2: require("~/components/comm/newImg/index2.vue").default,
  },
})
export default class substationIndex5 extends Vue {
  @Mutation private SET_TOKEN_DATA!: any;
  @State((state) => state.web.distributorsObject) distributorsObject!: any;
  public setCookie: any;
  public openUrl: any;
  public errorToastFn: any;
  public eventNotifyTost: any;
  private orderCount: any = ""; // 成交订单总数
  private yesterdayOrderCount: number = 0; // 昨日成交订单总数

  private colorConfig: Object = {
    //菜单颜色
    bgColor: "#190F0D",
    hoverBgColor: "#BC0000",
    color: "#EAB700",
    hoverColor: "#EAB700",
  };
  private adSingleImg: any = [];
  private orderListData: any = [];
  private isFirstShowLeft: boolean = true;
  private isHkdsPlat : boolean = false

  async mounted() {
    if(window.location.host.includes('192')) {
      this.isHkdsPlat = true
    }
    setTimeout(() => {
      console.log(this.distributorsObject, "========aaaaaa啊啊啊啊啊啊啊");
      let body: any = document.querySelectorAll(
        "body"
      ) as NodeListOf<HTMLElement>;
      console.log(body, "===body===");
      body[0].setAttribute("style", "background-color:#f2f3f5");
    }, 50);

    this.getArray();
    // await this.getDistOrderCount();
    await this.getDealGoods();
    this.dailyOrderHttpFn();
    this.getStaticOrderCount();
  }

  @Emit()
  getArray() {
    if (this.distributorsObject && this.distributorsObject.adverPosMap["4"]) {
      let flag: any = this.distributorsObject.adverPosMap["4"].filter(
        (v: any) => v.device == 0
      );
      if (flag) {
        this.adSingleImg = flag;
      }
    }
  }
  @Emit() //获取当日成交笔数
  async dailyOrderHttpFn() {
    try {
      let { data } = await dailyOrderHttp();
      if (this.errorToastFn(data)) {
        if (data.data) {
          this.yesterdayOrderCount = data.data.goodsList[1].dealNum;
          this.orderCount = (this.orderCount * 1 + this.yesterdayOrderCount)
            .toString()
            .padStart(6, "0");
          this.$bus.$emit("yesterdayOrderCountEvent", this.yesterdayOrderCount);
          console.log(this.orderCount,'sadfhjksdfjklsdf');
          
        }
      }
    } catch (error) {
      this.errorToastFn("提示", "网络超时", "warning");
    }
  }
  @Emit() // 获取分站静态订单笔数
  async getStaticOrderCount() {
    if (
      this.$store.state.web.distributorsObject &&
      this.$store.state.web.distributorsObject.distBaseConf.statisMap
    ) {
      let statisMap =
        this.$store.state.web.distributorsObject.distBaseConf.statisMap;
      if (!!statisMap && statisMap.includes("{")) {
        let flagMap = JSON.parse(statisMap);
        if (!!flagMap["dealCount"]) {
          this.orderCount = flagMap["dealCount"];
        }
      }
    }
  }
  @Emit() // 获取分站成交笔数
  async getDistOrderCount() {
    try {
      let { data } = await distOrderCountHttp();
      if (this.errorToastFn(data)) {
        if (data.data) {
          console;
          let flag = "20";
          // console.log(this.$store.state.web.distributorsObject.distBaseConf.statisMap,'1111111111111111111111111111111111111111111111111')
          try {
            if (
              this.$store.state.web.distributorsObject &&
              this.$store.state.web.distributorsObject.distBaseConf.statisMap
            ) {
              //  flag=this.$store.state.web.distributorsObject.distBaseConf.statisMap.split(',')[2]
              let statisMap =
                this.$store.state.web.distributorsObject.distBaseConf.statisMap;
              if (!!statisMap && statisMap.includes("{")) {
                let flagMap = JSON.parse(statisMap);
                if (!!flagMap["dealCount"]) {
                  flag = (
                    flagMap["dealCount"] * 1 +
                    data.data.count
                  ).toString();
                }
              }
            }
          } catch (e) {}
          this.orderCount = flag;
        }
      }
    } catch (error) {
      this.errorToastFn("提示", "网络超时", "warning");
    }
  }
  @Emit() // 获取每日成交记录
  async getDealGoods() {
    try {
      let { data } = await latestDealRecordHttp({ params: { pageSize: 13 } });
      if (this.errorToastFn(data)) {
        this.orderListData = data.data;
        console.log(this.orderListData, "=======orderListData======");
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
    }
  }
  @Emit()
  closeFloatLeft() {
    this.isFirstShowLeft = false
  }
  beforeDestroy() {
    this.$bus.$off("yesterdayOrderCountEvent", 0);
  }
}
