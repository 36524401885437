

import {Component, Vue, Emit, Prop, Watch} from "vue-property-decorator";
import {State, Getter, Action, Mutation, namespace} from "vuex-class";
import {getWinningRaffleList} from "~/api/user";

@Component({
  name: "scrollAuto",

})
export default class scrollAuto extends Vue {
  public timer:any = null;
  public show:boolean = false;
  public scrollTop:number = 0 //列表滚动高度
  public speed:number = 30 //滚动的速度
  public listData:any = [] //表示有多少个列表项
  public copyHtml:any=''//复制多一份防止滚动到后面出现空白

  mounted() {
    // 如果列表数据是异步获取的，记得初始化在获取数据后再调用
    this.initScroll()
  }

   async initScroll() {
      await getWinningRaffleList().then((res:any)=>{
        if(res.data.code===0)
        {
          console.log(res,'中奖名单');
          if(res.data.data.raffleList.length!==0){
            this.show=true;
            this.listData = res.data.data.raffleList;
            this.$nextTick(() => {
              this.copyHtml = (this.$refs.scrollItemBox as any).innerHTML
              this.startScroll()
            })
          }else
          {
            this.show=false;
          }
        }
      })
    }
    // 鼠标移入停止滚动
    mEnter() {
      clearInterval(this.timer);
    }
    // 鼠标移出继续滚动
    mLeave() {
      this.startScroll()
    }
    // 开始滚动
    startScroll() {
      this.timer = setInterval(this.scroll, this.speed);
    }
    // 滚动处理方法
    scroll() {
      this.scrollTop++;
      // 获取需要滚动的盒子的高度
      let scrollItemBox:number = (this.$refs.scrollItemBox as any).offsetHeight;
      // 当判断滚动的高度大于等于盒子高度时，从头开始滚动
      if (this.scrollTop >= scrollItemBox) {
        this.scrollTop = 0
      }
    }

};
