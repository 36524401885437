
  import { Component, Vue, Emit, Prop, Watch} from '~/node_modules/vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from '~/node_modules/vuex-class'
  import  moment from '~/node_modules/moment';
  moment.locale("zh-cn");
  @Component({
    name:"djhAd18",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class djhAd18 extends Vue {
    @Mutation private SET_TOKEN_DATA!: any;
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    private erweima:any = ''
    private erweimaTitle:any = ''
    private rightThreeServe:any = []
    mounted(){
        console.log(this.distributorsObject,this.distributorsObject.adverPosMap,'tsetas')
        this.getErWeiMa()
        
    }
    @Emit()
    getErWeiMa() {
        if(this.distributorsObject && this.distributorsObject.adverPosMap && this.distributorsObject.adverPosMap[6]) {
            console.log(this.distributorsObject.adverPosMap[6],'二维码1')
            let PCtempArr = this.distributorsObject.adverPosMap[6].filter((v:any) => v.device === 0)
            console.log('jklsdf',PCtempArr);
            if(PCtempArr && PCtempArr.length > 0) {
                this.erweima = PCtempArr[0].url
                this.erweimaTitle = PCtempArr[0].title
                this.rightThreeServe = PCtempArr.filter((v:any,i:any) => {return i>0 && i<4})
            }
        } 
        console.log(this.erweima,this.rightThreeServe,'二维码')
    }
  }
