
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"footerfooterService3",
  })
  export default class footerfooterService3 extends Vue {
    @Prop({default:true}) needMarginTop?: any
    @Prop({default:false}) needBoxShadow?: any
    mounted(){
    }
  }

