
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"helpButton",
  })
  export default class helpButton extends Vue {
    // @Prop(Number) divWidth_?: number
    // @Prop(Number) divHeight_?: any
    private nowChild:number=-1 //当前鼠标悬浮按钮位置
    mounted() {

    }
  }
