
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  @Component({
    name:"substationIndex1",
    components: {
      logoSearch: require('~/components/comm/logoSearch/index.vue').default,
      djhHeaderNav2: require('~/components/comm/djhHeaderNav/index2.vue').default,
      djhSwiper2: require('~/components/comm/djhSwiper/index2.vue').default,
      hotGameList3: require('~/components/comm/hotGame/hotGameList3.vue').default,
      homeLogin: require('~/components/comm/homeLogin/index.vue').default,
      djhAd8: require('~/components/comm/djhAd/index8.vue').default,
      hotLine: require('~/components/comm/hotLine/index.vue').default,
      newsList3: require('~/components/comm/news/newsList3.vue').default,
      goodsList5: require('~/components/comm/goodsList/goodsList5.vue').default,
      latestDealGoods2: require('~/components/comm/dealGoods/latestDealGoods2.vue').default,
      djhAd9: require('~/components/comm/djhAd/index9.vue').default,
      searchQrcode: require('~/components/comm/other/searchQrcode.vue').default,
      floatMenu: require('~/components/comm/floatMenu/index.vue').default,
    }
  })
  export default class substationIndex1 extends Vue {
    @Mutation private SET_TOKEN_DATA!: any;
    @State(state => state.web.distributorsObject) distributorsObject!: any;
    @Prop(Object) goodsListData_?: any
    private colorConfig : Object = { //菜单颜色
      bgColor: "#190F0D",
      hoverBgColor: "#BC0000",
      color: "#EAB700",
      hoverColor: "#EAB700"
    }
    private floatMenuOther:any = {}
    mounted(){
      let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
      console.log(body,'===body====')
      body[0].setAttribute('style', 'background-color:#F5F6FA')
    }
  }
