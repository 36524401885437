
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"goodsList1",
    components: {
      newImg: require('~/components/comm/newImg/index.vue').default,
    }
  })
  export default class goodsList1 extends Vue {
    @Prop(Object) goodsListData_?: any
    @Prop(Number) divWidth_?: any
    @Prop(Number) itemWidth_?: any
    mounted(){

    }
  }
