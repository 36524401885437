
import {Component, Vue, Emit, Prop} from 'vue-property-decorator'
import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
import {getSeckillList, querySeckillTimeList} from "~/api/goods";
import {gameListHttp} from "~/api/comm";
import "~/plugins/mixin.js";
import buyGuarantee from "~/pages/center/buyers/buyGuarantee.vue";
import moment from "moment";

// let vm: any = null;
@Component({
  name: "auctionList",
  components: {
    newImg: require('~/components/comm/newImg/index.vue').default,
    timer: require('~/components/comm/goodsList/timer.vue').default,
  },
  asyncData() {
  }
})
export default class goodsList11 extends Vue {

  @Mutation private SET_HOTGAMES_DATA!: any;
  @State(state => state.web.hotGamesArray) hotGamesArray!: any;
  // @Prop({default:()=>[]}) hotGameList_?: any //游戏类型：1：网络游戏  2：手机游戏
  public errorToastFn: any
  public eventNotifyTost: any
  public openGoodsDetail: any
  public getCookie:any
  public min: any
  private noMore: boolean = false;
  private goodsListData: any = {
    total: 0,
    goodsList: []
  }
  private param: any = {
    pages: 1,
    pageSize: 4
  }
  @Prop(Array) swiperList_?: any
  private leftTime: number = 0;
  private sum_h: number = 0;
  private ListData: any = []
  public Timer: any =''
  public showTime: any =''
  private nowGameId: any = -1
  private defaultItem: any = {
    thumbnail: 'img/001/zypa/2021/11/12/1636685246558',
    bigTitle: '暂无商品！！！！！',
    areaName: '',
    serverName: '-',
    price: '--',
    createTime: '--'
  }
  mounted() {
    // vm = this;
    this.getGoodsList()
  }
  timeChange(time:any)
  {
    time = this.timeFormatUser(time,'YYYY-MM-DD HH:mm:ss')
    // console.log(time,'time1');
    time = time.substring(time.indexOf(' ')+1,time.length)
    time = time.substring(0,5);
    // console.log(time,'time1');
    return time
  }
  titleChange(title: string) {
    title = title.substring(title.indexOf('】') + 1, title.length)
    return title
  }

  get priceInteger() {
    return (item: any) => {
      if (String(item.Price).includes('.')) {
        return String(item.Price).split('.')[0] + '.'
      } else {
        return String(item.Price)
      }
    }
  }
  goLogin(item:any)
  {
    let userId = this.getCookie('userId')
    console.log(userId);
    if(userId)
    {
      this.goToGoodsDetail(item);
    }else
    {
      this.$router.push({ name: "login"})
    }
  }
  goLogin1()
  {
    let userId = this.getCookie('userId')
    // console.log(userId);
    if(userId)
    {
      this.$router.push({ name: "auctionPage"})
    }else
    {
      this.$router.push({ name: "login"})
    }
  }
  get priceMinority() {
    return (item: any) => {
      if (String(item.price).includes('-')) {
        return ''
      }
      if (String(item.price).includes('.')) {
        return String(item.price).split('.')[1]
      } else {
        return '.00'
      }
    }
  }

  // 切换游戏
  /*switchGame(gameId:any) {
    console.log('进来111');
    if(this.nowGameId == gameId) {
      return
    }
    this.nowGameId = gameId
    this.param = { pages: 1,pageSize:4}
    this.getGoodsList()
  }*/
  getLeft(leftTime: number, sum_h: number) {
    this.leftTime = leftTime;
    this.min = this.leftTime / 1000 / 60;
    // console.log(leftTime,this.min,'123123123');
    this.sum_h = sum_h;

  }

  async getGoodsList() {
    // console.log('进来222');
    try {
      let timeData:any = await querySeckillTimeList();
      console.log(timeData,'123456')
      let item = timeData.data.data.list.filter((item:any)=>item.Show===1);
      this.Timer = this.timeFormatUser(item[0].SeckillTime,'YYYY-MM-DD HH:mm:ss');
      this.showTime = this.timeChange(item[0].SeckillTime);
      this.param.seckillTime = this.Timer;
      const {data}:any = await getSeckillList({params: this.param})
      // console.log(data,this.Timer,'123456')
      if (data.code === 0) {
        this.goodsListData.total = data.data.Count
        this.goodsListData.goodsList = data.data.Data
      }
    } catch (error) {
      console.log(error);
      this.goodsListData = {total: 0, goodsList: []}
    }
  }
  @Emit()
  //时间格式化，type：YYYY-MM-DD HH:mm:ss
  timeFormatUser(time: string, type: string) {
    return moment(time).format(type);
  }
  getSecKillList() {
    if (this.goodsListData.goodsList && this.goodsListData.total > 0) {
      const datas = this.goodsListData.goodsList;
      const datas2 = [];
      while (datas.length > 0) {
        datas2.push(datas.splice(0, 4));
        console.log(datas2, '秒杀商品css');
      }
      return datas2
    } else {
      return []
    }
  }

  // 打开商品详情
  @Emit()
  goToGoodsDetail(item: any) {
    console.log(item.GoodsSn, '123456789')
    if (item.GoodsSn) {
      this.openGoodsDetail({goodsSn: item.GoodsSn, goodsTypeId: item.GoodsTypeId}, '_blank')
    } else {
      this.$message.warning('请看看其他商品吧！')
    }
  }
}
