
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {getUserInfoHttp} from "~/api/user";
  @Component({
    name:"homeLogin3",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class homeLogin3 extends Vue {
    @Prop(Number) divWidth_?: number
    @Prop(Number) divHeight_?: number
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    public getCookie:any
    public setCookie:any
    private isLogined : boolean = false // 是否已登录
    private actor : string = '' // 是否已登录
    private nickName : string = '' // 是否已登录

    private hover:number=0 //我要卖鼠标
    private isShowHover:boolean=false //是否显示我要卖二级菜单
    mounted(){
      if (this.getCookie('token')) {
        this.isLogined=true
        this.actor=this.getCookie('actor')
        this.nickName=this.getCookie('nickName')
      }
    }
    @Emit()
    async getUserInfo(){
      try { //登录成功后拉取用户信息
        let {data}=await getUserInfoHttp()
        if (this.errorToastFn(data)) {
          this.actor=data.data.actor
          this.nickName=data.data.nickName
          this.setCookie("nickName", data.data.nickName)
          this.setCookie("userId", data.data.userId)
          this.setCookie("actor", data.data.actor)
        }
      } catch (e) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
  }
